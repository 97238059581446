import { EdgeControllerRoutesContext } from "/apps/optimization/configuration/edge-controller/routes"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { DeleteConfirmation } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { remove } from "../api"
import { organization } from "/utils/store/organization"

export default function Delete() {
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["optimization"])
  const { edgeController } = useContext(EdgeControllerRoutesContext)

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  const removeObject = async () => {
    await remove(siteStore.id, edgeController.id, auth.userData.access_token)
      .then(() => {
        displayNotification([
          successNotification("Delete", t("The edge controller has been removed."))
        ])
        navigate(
          `/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/configuration/edge-controllers`
        )
      })
      .catch(error => {
        displayNotification([errorNotification("Error", error.message)])
      })
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  return (
    edgeController && (
      <DeleteConfirmation
        objectType={t("Edge controller")}
        objectNames={[`${t("Edge controller")} #${edgeController.id}`]}
        remove={removeObject}
        goBack={() => navigate(-1)}
      />
    )
  )
}
