import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import {
  BaseLayout,
  ButtonWithLoader,
  FormContent
} from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { add } from "../api"
import { Bacnet } from "../models/bacnet"
import { Carel } from "../models/carel"
import { ModbusBit, ModbusRegister, ModbusRegisterBitExtract } from "../models/modbus"
import { OPCUA } from "../models/opcua"
import { SMController, SMIO, SMMeter } from "../models/sm"
import { ExternalAPI } from "../models/externalapi"

const SelectType = ({ data, setData }) => {
  const { t } = useTranslation(["assets"])

  const [type, setType] = useState(null)
  const types = [
    { value: "modbus", label: "Modbus" },
    { value: "bacnet", label: "Bacnet" },
    { value: "sm", label: "AK-SM" },
    { value: "opcua", label: "OPCUA" },
    { value: "carel", label: "Carel" },
    { value: "externalapi", label: "External API" }
  ]
  const [subTypeList, setSubTypeList] = useState([])
  const [subType, setSubType] = useState(null)

  const smSubTypes = [
    { value: "smio", label: "AK-SM IO" },
    { value: "smmeter", label: "AK-SM Meter" },
    { value: "smcontroller", label: "AK-SM Controller" }
  ]

  const modbusSubTypes = [
    { value: "modbusbit", label: "Modbus bit" },
    { value: "modbusregister", label: "Modbus register" },
    { value: "modbusregisterbitextract", label: "Modbus register bit extract" }
  ]

  const updateType = value => {
    setType(value)
    setSubType(null)

    if (value === "sm") {
      setSubTypeList(smSubTypes)
    } else if (value === "modbus") {
      setSubTypeList(modbusSubTypes)
    } else {
      setSubTypeList([])
    }
  }

  const updateSubType = value => {
    setSubType(value)
  }

  useEffect(() => {
    if (type === "sm" && subType) {
      setData({ ...data, ...{ type: subType } })
    } else if (type === "modbus" && subType) {
      setData({ ...data, ...{ type: subType } })
    } else {
      setData({ ...data, ...{ type: type } })
    }
  }, [type, subType])

  return (
    <>
      <div className="field-container">
        <div className="field dropdown2">
          <label className="label label-required">{t("Type")}</label>
          <div className="field-input">
            <Dropdown
              options={types}
              value={type}
              onChange={e => updateType(e.value)}
              placeholder={t("Choose a type")}
            />
          </div>
        </div>
      </div>

      {subTypeList.length > 0 && (
        <div className="field-container">
          <div className="field dropdown2">
            <label className="label label-required">{t("Subtype")}</label>
            <div className="field-input">
              <Dropdown
                options={subTypeList}
                value={subType}
                onChange={e => updateSubType(e.value)}
                placeholder={t("Choose a sub type")}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [nodes, setNodes] = useState([])
  const [processing, setProcessing] = useState(false)

  const goBack = e => {
    e && e.preventDefault()
    navigate(-1)
  }

  const mappingNodeTypes = {
    modbusbit: "modbusnode",
    modbusregister: "modbusnode",
    modbusregisterbitextract: "modbusnode",
    bacnet: "bacnetnode",
    opcua: "opcuanode",
    carel: "carelnode",
    smio: "smionode",
    smmeter: "smmeternode",
    smcontroller: "smcontrollernode",
    externalapi: "externalapinode"
  }

  const create = async function (e) {
    e.preventDefault()
    setProcessing(true)

    const pointData = { ...data }
    if (pointData.type === "smcontroller") {
      pointData.rw = pointData.writable ? "W" : "R"
    }

    await add(siteStore.id, pointData, auth.userData.access_token)
      .then(response => {
        dispatch(
          addNotifications([
            successNotification("Create", t("The automation point has been created."))
          ])
        )
        navigate(
          `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/automation-points/${response.data.id}`
        )
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        error.response && error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setProcessing(false))
  }

  const getModel = pointType => {
    switch (pointType) {
      case "modbusbit":
        return ModbusBit(nodes)
      case "modbusregister":
        return ModbusRegister(nodes)
      case "modbusregisterbitextract":
        return ModbusRegisterBitExtract(nodes)
      case "bacnet":
        return Bacnet(nodes)
      case "smio":
        return SMIO(nodes)
      case "smmeter":
        return SMMeter(nodes)
      case "smcontroller":
        return SMController(nodes)
      case "opcua":
        return OPCUA(nodes)
      case "carel":
        return Carel(nodes)
      case "externalapi":
        return ExternalAPI(nodes)
      default:
        return {}
    }
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (siteStore.id && organizationStore.id) {
      setData({ ...data, site: siteStore.id })
    }
  }, [siteStore.id && organizationStore.id])

  useEffect(() => {
    const retrieveNodes = async () => {
      await paginatedRequest(
        `asset/sites/${siteStore.id}/nodes/?type=${mappingNodeTypes[data.type]}`,
        auth.userData.access_token
      )()
        .then(results => {
          setNodes(results)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })
    }
    if (data && data.type) retrieveNodes()
    if (data && data.type && data.type === "bacnet") setData({ ...data, bacnet_priority: 9 })
  }, [data.type])

  return (
    data && (
      <BaseLayout title={t("Create automation point")}>
        <form
          className="form-view"
          onSubmit={create}
        >
          <SelectType
            data={data}
            setData={setData}
          />
          {data.type && (
            <FormContent
              model={getModel(data.type)}
              data={data}
              setData={setData}
              errors={errors}
            />
          )}
          <div className="action-buttons-wrapper">
            <Button
              rounded
              outlined
              severity="secondary"
              label={t("Cancel")}
              icon="fa-solid fa-xmark"
              disabled={processing}
              onClick={goBack}
            />
            <ButtonWithLoader
              rounded
              severity="success"
              label={t("Save")}
              type="submit"
              icon="fa-solid fa-floppy-disk"
              disabled={processing}
            />
          </div>
        </form>
      </BaseLayout>
    )
  )
}
