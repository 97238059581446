import { ContractRoutesContext } from "/apps/energy/contract/routes"
import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { BaseLayout, OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { update } from "../api"
import { Contract, ContractOverview } from "../models"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingDeliveryPoints, setLoadingDeliveryPoints] = useState(true)
  const [loadingCompanies, setLoadingCompanies] = useState(true)
  const [loadingGateways, setLoadingGateways] = useState(true)
  const [energyDeliveryPoints, setEnergyDeliveryPoints] = useState([])
  const [energyCompanies, setEnergyCompanies] = useState([])
  const [gateways, setGateways] = useState([])
  const { contract, retrieveDetail } = useContext(ContractRoutesContext)

  const getEDP = async () => {
    setLoadingDeliveryPoints(true)
    await paginatedRequest(
      `energy/sites/${siteStore.id}/energy_delivery_points/`,
      auth.userData.access_token
    )()
      .then(results => {
        setEnergyDeliveryPoints(results)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification("Energy delivery points", message)]))
      })
      .finally(() => setLoadingDeliveryPoints(false))
  }

  const getEC = async () => {
    setLoadingCompanies(true)
    await paginatedRequest("energy/energy_companies/", auth.userData.access_token)()
      .then(results => {
        setEnergyCompanies(results)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification("Energy companies", message)]))
      })
      .finally(() => setLoadingCompanies(false))
  }

  const getGW = async () => {
    setLoadingGateways(true)
    await paginatedRequest(
      `connectivity/sites/${siteStore.id}/gateways/`,
      auth.userData.access_token
    )()
      .then(results => {
        setGateways(results)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification("Gateways", message)]))
      })
      .finally(() => setLoadingGateways(false))
  }

  const updateObject = async function (e) {
    e.preventDefault()
    setProcessing(true)
    await update(siteStore.id, contract.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(
          addNotifications([successNotification("Update", t("The contract has been updated."))])
        )
        retrieveDetail()
        setLoading(true)
        setEdit(false)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        error.response && error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (contract) {
      setLoading(false)
      setData({ ...contract, optimization_gateways: contract.optimization_gateways_id })
    }
  }, [contract])

  useEffect(() => {
    if (edit) {
      getEDP()
      getEC()
      getGW()
    }
  }, [edit])

  return (
    contract && (
      <BaseLayout title={contract.name}>
        <OverView
          object={contract}
          model={Contract(
            energyDeliveryPoints,
            energyCompanies,
            gateways,
            loadingDeliveryPoints,
            loadingCompanies,
            loadingGateways
          )}
          overviewModel={ContractOverview()}
          updateObject={updateObject}
          data={data}
          setData={setData}
          errors={errors}
          edit={edit}
          processing={processing || loadingDeliveryPoints || loadingCompanies || loadingGateways}
          loading={loading}
          setEdit={setEdit}
          deleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/energy/contracts/${contract.id}/delete/`}
        />
      </BaseLayout>
    )
  )
}
