import { config } from "/utils/api"
import axios from "axios"

export async function retrieveList(siteId, token, queryParams) {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/automation_points/`,
    config({ token, queryParams })
  )
}

export async function retrieve(siteId, pointId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/automation_points/${pointId}/`,
    config({ token })
  )
}

export async function add(siteId, data, token) {
  return await axios.post(
    `${window.BACKEND_URL}/asset/sites/${siteId}/automation_points/`,
    data,
    config({ token })
  )
}

export async function update(siteId, pointId, data, token) {
  return await axios.put(
    `${window.BACKEND_URL}/asset/sites/${siteId}/automation_points/${pointId}/`,
    data,
    config({ token })
  )
}

export async function remove(siteId, pointId, token) {
  return await axios.delete(
    `${window.BACKEND_URL}/asset/sites/${siteId}/automation_points/${pointId}/`,
    config({ token })
  )
}

export async function removePoints(siteId, ids, token) {
  return await axios.post(
    `${window.BACKEND_URL}/asset/sites/${siteId}/automation_points/deletion/`,
    ids,
    config({ token })
  )
}

export async function getCount(pointId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/asset/automation_points/${pointId}/count`,
    config({ token })
  )
}

export async function getSpaces(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/spaces/`, config({ token }))
}

export async function getSMPoints(siteId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/automation_points/`,
    config({ token, queryParams: { type: "sm" } })
  )
}

export async function getCarelPoints(siteId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/automation_points/`,
    config({ token, queryParams: { type: "carel" } })
  )
}

export async function getPointsCount(ids, token) {
  return await axios.post(
    `${window.BACKEND_URL}/asset/automation_points/count/`,
    ids,
    config({ token })
  )
}

export async function getAllValues(siteId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/automation_points/allvalues`,
    config({ token })
  )
}

export async function exportPoints(siteId, pointType, token) {
  return await axios({
    method: "get",
    url: `${window.BACKEND_URL}/asset/sites/${siteId}/automation-points/${pointType}node/bulk/export`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export async function exportTemplate(pointType, token) {
  return await axios({
    method: "get",
    url: `${window.BACKEND_URL}/asset/sites/0/automation-points/${pointType}node/bulk/export`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export async function importPoints(siteId, pointType, file, token) {
  let formData = new FormData()
  formData.append("file", file)
  return await axios.post(
    `${window.BACKEND_URL}/asset/sites/${siteId}/automation-points/${pointType}node/bulk/import`,
    formData,
    config({ token, file: true }),
    { signal: new AbortController() }
  )
}
