import { GatewayRoutesContext } from "/apps/connectivity/gateway/routes"
import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { DeleteConfirmation, Loader } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { removeStores } from "../api"

const DeleteBulk = () => {
  const auth = useAuth()
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const { t } = useTranslation(["assets"])
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [loadingRelatedObject, setLoadingRelatedObject] = useState(false)
  const [objectNames, setObjectNames] = useState([])
  const [processing, setProcessing] = useState(false)
  const { gateway } = useContext(GatewayRoutesContext)
  const ids = searchParams.getAll("id")

  const getStores = async () => {
    setLoadingRelatedObject(true)
    await paginatedRequest(
      `connectivity/sites/${siteStore.id}/stores/?gateway=${gateway.id}&${searchParams.toString()}`,
      auth.userData.access_token
    )()
      .then(results => {
        setObjectNames(results.map(store => store.name))
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        displayNotification([errorNotification("Stores", message)])
      })
      .finally(() => setLoadingRelatedObject(false))
  }

  const bulkDelete = async () => {
    setProcessing(true)
    await removeStores(siteStore.id, ids, auth.userData.access_token)
      .then(({ status, statusText }) => {
        if (status === 200) {
          dispatch(addNotifications([successNotification("Stores", t("Stores has been removed."))]))
          navigate(
            `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/stores/`
          )
        } else {
          throw new Error(statusText)
        }
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification("Stores", message)]))
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    getStores()
  }, [])

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  return loadingRelatedObject ? (
    <Loader />
  ) : (
    <DeleteConfirmation
      objectType={t("Stores")}
      objectNames={objectNames}
      remove={bulkDelete}
      processing={processing}
      goBack={() => navigate(-1)}
    />
  )
}

export default DeleteBulk
