import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "/utils/constants/api"
import { datetimeStr } from "/utils/datetime"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ListActions, ListLayout } from "@software-engineering/hivolution-frontend-utils"
import fileDownload from "js-file-download"
import { useAuth } from "oidc-react"
import { SortOrder } from "primereact/api"
import { Button } from "primereact/button"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { exportPoints, exportTemplate, getAllValues, importPoints, retrieveList } from "../api"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingFilters, setLoadingFilters] = useState(true)
  const [filterValues, setFilterValues] = useState(null)
  const [filters, setFilters] = useState({})
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset, setOffset] = useState(REQUEST_DEFAULT_OFFSET)
  const [count, setCount] = useState(0)
  const [processingImport, setProcessingImport] = useState(false)
  const [processingExport, setProcessingExport] = useState(false)
  const [processingTemplate, setProcessingTemplate] = useState(false)
  const hiddenFileInputModbus = useRef(null)
  const hiddenFileInputBacnet = useRef(null)
  const hiddenFileInputSM = useRef(null)
  const hiddenFileInputOPCUA = useRef(null)
  const hiddenFileInputCarel = useRef(null)
  const hiddenFileInputExternal = useRef(null)
  const [sortOrder, setSortOrder] = useState(SortOrder.ASC)
  const [sortField, setSortField] = useState("name")
  const [selected, setSelected] = useState([])

  const retrieve = async (queryParams = { limit, offset }) => {
    setLoading(true)
    await retrieveList(siteStore.id, auth.userData.access_token, queryParams)
      .then(({ data }) => {
        setCount(data.count)
        data.results &&
          setItems(
            data.results.map(point => {
              return {
                ...point,
                equipment_name: point.equipment,
                building_point_name: point.point_name
              }
            })
          )
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setLoading(false))
  }

  const getFilterValues = async () => {
    setLoadingFilters(true)
    await getAllValues(siteStore.id, auth.userData.access_token)
      .then(response => {
        setFilterValues(response.data)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification("Automation points", message)]))
      })
      .finally(() => setLoadingFilters(false))
  }

  const onSort = sortEvent => {
    setSortOrder(sortEvent.sortOrder)
    setSortField(sortEvent.sortField)
  }

  const columns = [
    { field: "name", header: t("Name"), sortable: true },
    {
      field: "node_name",
      header: t("Node"),
      sortable: true,
      link: point =>
        point.node
          ? `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/automation-nodes/${point.node}`
          : null
    },
    { field: "read_write", header: t("Read / Write") },
    { field: "type", header: t("Type"), sortable: true },
    { field: "equipment_name", header: t("Equipment"), sortable: true },
    { field: "building_point_name", header: t("Point"), sortable: true }
  ]

  const columnsFilter = [
    { field: "name", header: t("Name") },
    { field: "type", header: t("Type") },
    { field: "node_name", header: t("Node") },
    { field: "equipment_name", header: t("Equipment") },
    { field: "writable", header: t("Writable") }
  ]

  const getDetailUrl = objectId =>
    `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/automation-points/${objectId}/`

  const getDeleteUrl = objectId =>
    `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/automation-points/${objectId}/delete/`

  const onRowClick = objectId => {
    navigate(getDetailUrl(objectId))
  }

  const getExport = async pointType => {
    setProcessingExport(true)
    await exportPoints(siteStore.id, pointType, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `${pointType}_${datetimeStr()}.csv`)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification("Automation points", message)]))
      })
      .finally(() => setProcessingExport(false))
  }

  const getTemplate = async pointType => {
    setProcessingTemplate(true)
    await exportTemplate(pointType, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `${pointType}_${datetimeStr()}.csv`)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification("Automation points", message)]))
      })
      .finally(() => setProcessingTemplate(false))
  }

  const postImport = async function (target, pointType) {
    const file = target.files[0]
    if (file) {
      setProcessingImport(true)
      await importPoints(siteStore.id, pointType, file, auth.userData.access_token)
        .then(response => {
          dispatch(addNotifications([successNotification("Import", response.data)]))
          retrieve()
        })
        .catch(error => {
          if (
            error.response &&
            error.response.headers &&
            error.response.headers["content-type"] === "text/csv"
          ) {
            fileDownload(error.response.data, `ERROR_${pointType}_${datetimeStr()}.csv`)
            dispatch(
              addNotifications([
                errorNotification(
                  "Automation points",
                  t(
                    "An error occured during the import, see more details in the error report file."
                  )
                )
              ])
            )
          } else {
            const message =
              error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : error.message
            dispatch(addNotifications([errorNotification("Automation points", message)]))
          }
        })
        .finally(() => {
          setProcessingImport(false)
          target.value = null
        })
    }
  }

  const menuEntries = [
    {
      label: t("Import"),
      icon: processingImport ? "fa-solid fa-spinner fa-spin" : "fa-solid fa-file-import",
      disabled: processingImport,
      items: [
        {
          label: "Modbus",
          command: () => hiddenFileInputModbus && hiddenFileInputModbus.current.click()
        },
        {
          label: "Bacnet",
          command: () => hiddenFileInputBacnet && hiddenFileInputBacnet.current.click()
        },
        { label: "SM", command: () => hiddenFileInputSM && hiddenFileInputSM.current.click() },
        {
          label: "Carel",
          command: () => hiddenFileInputCarel && hiddenFileInputCarel.current.click()
        },
        {
          label: "OPC UA",
          command: () => hiddenFileInputOPCUA && hiddenFileInputOPCUA.current.click()
        },
        {
          label: "External API",
          command: () => hiddenFileInputExternal && hiddenFileInputExternal.current.click()
        }
      ]
    },
    {
      label: t("Export"),
      icon: processingExport ? "fa-solid fa-spinner fa-spin" : "fa-solid fa-file-export",
      disabled: processingExport,
      items: [
        { label: "Modbus", command: () => getExport("modbus") },
        { label: "Bacnet", command: () => getExport("bacnet") },
        { label: "SM", command: () => getExport("sm") },
        { label: "Carel", command: () => getExport("carel") },
        { label: "OPC UA", command: () => getExport("opcua") },
        { label: "External API", command: () => getExport("externalapi") }
      ]
    },
    {
      label: t("Template"),
      icon: processingTemplate ? "fa-solid fa-spinner fa-spin" : "fa-solid fa-file",
      disabled: processingTemplate,
      items: [
        { label: "Modbus", command: () => getTemplate("modbus") },
        { label: "Bacnet", command: () => getTemplate("bacnet") },
        { label: "SM", command: () => getTemplate("sm") },
        { label: "Carel", command: () => getTemplate("carel") },
        { label: "OPC UA", command: () => getTemplate("opcua") },
        { label: "External API", command: () => getTemplate("externalapi") }
      ]
    }
  ]

  const menuFilters = { columnsFilter, filters, setFilters, filterValues }

  const queryParams = () => {
    return {
      ...filters,
      limit,
      offset,
      ordering: sortField && `${sortOrder > 0 ? "" : "-"}${sortField}`
    }
  }

  useEffect(() => {
    siteStore.id && (offset ? setOffset(REQUEST_DEFAULT_OFFSET) : retrieve(queryParams()))
  }, [filters, sortField, sortOrder])

  useEffect(() => {
    siteStore.id && retrieve(queryParams())
  }, [limit, offset])

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id && organizationStore.id && getFilterValues()
  }, [siteStore.id, organizationStore.id])

  return (
    <>
      <ListActions
        entries={menuEntries}
        filterBackend={true}
        filters={menuFilters}
        loading={loadingFilters}
      >
        <input
          hidden
          type="file"
          ref={hiddenFileInputModbus}
          onChange={e => postImport(e.target, "modbus")}
        />
        <input
          hidden
          type="file"
          ref={hiddenFileInputBacnet}
          onChange={e => postImport(e.target, "bacnet")}
        />
        <input
          hidden
          type="file"
          ref={hiddenFileInputSM}
          onChange={e => postImport(e.target, "sm")}
        />
        <input
          hidden
          type="file"
          ref={hiddenFileInputCarel}
          onChange={e => postImport(e.target, "carel")}
        />
        <input
          hidden
          type="file"
          ref={hiddenFileInputOPCUA}
          onChange={e => postImport(e.target, "opcua")}
        />
        <input
          hidden
          type="file"
          ref={hiddenFileInputExternal}
          onChange={e => postImport(e.target, "externalapi")}
        />
        <Link
          to={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/automation-points/create/`}
        >
          <Button
            rounded
            className="button"
            severity="info"
            icon="fa-regular fa-plus"
            label={t("Create")}
          />
        </Link>
      </ListActions>

      <ListLayout
        columns={columns}
        value={items}
        objectName={t("Automation point")}
        loading={loading}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
        offset={offset}
        limit={limit}
        setOffset={setOffset}
        setLimit={setLimit}
        count={count}
        bulkDeleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/automation-points/multi-delete/?${selected.map(item => `id=${item.id}`).join("&")}`}
        handleSelection={setSelected}
        onSort={onSort}
        sortOrder={sortOrder}
        sortField={sortField}
      />
    </>
  )
}
