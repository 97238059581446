import { paginatedRequest } from "/utils/api"
import axios from "axios"
import { config } from "/utils/api"

export const retrieveAllSpaces = async (siteId, token) =>
  await paginatedRequest(`asset/sites/${siteId}/spaces/`, token)()

export async function retrieveList(siteId, token, queryParams) {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/spaces/`,
    config({ token, queryParams })
  )
}

export async function retrieve(siteId, id, token) {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/spaces/${id}/`,
    config({ token })
  )
}

export async function add(siteId, data, token) {
  return await axios.post(
    `${window.BACKEND_URL}/asset/sites/${siteId}/spaces/`,
    data,
    config({ token })
  )
}

export async function update(siteId, id, data, token) {
  return await axios.put(
    `${window.BACKEND_URL}/asset/sites/${siteId}/spaces/${id}/`,
    data,
    config({ token })
  )
}

export async function remove(siteId, id, token) {
  return await axios.delete(
    `${window.BACKEND_URL}/asset/sites/${siteId}/spaces/${id}/`,
    config({ token })
  )
}

export async function getCount(id, token) {
  return await axios.get(`${window.BACKEND_URL}/DETAIL_URL/${id}/count`, config({ token }))
}

export async function getAllValues(siteId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/spaces/allvalues`,
    config({ token })
  )
}

export async function removeSpaces(siteId, ids, token) {
  return await axios.post(
    `${window.BACKEND_URL}/asset/sites/${siteId}/spaces/bulk-delete/`,
    ids,
    config({ token })
  )
}

export async function exportSpaces(siteId, token) {
  return await axios({
    method: "get",
    url: `${window.BACKEND_URL}/asset/sites/${siteId}/spaces/bulk/`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export async function importSpaces(siteId, file, token) {
  let formData = new FormData()
  formData.append("file", file)
  return await axios.post(
    `${window.BACKEND_URL}/asset/sites/${siteId}/spaces/bulk/`,
    formData,
    config({ token, file: true }),
    { signal: new AbortController() }
  )
}
