import { errorNotification, successNotification } from "/utils/notification"

import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"

import { FormContent } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { useDispatch } from "react-redux"

import { useNavigate, useParams } from "react-router-dom"

import { add } from "../api"
import { FlexOpti } from "../models"

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const { siteId, flexId } = useParams()

  const [data, setData] = useState({
    contract_id: flexId,
    site_id: siteId
  })
  const [errors, setErrors] = useState({})

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "site", siteId: siteId }))
  }, [])

  const create = async function (e) {
    e.preventDefault()

    await add(data, auth.userData.access_token)
      .then(() => {
        dispatch(
          addNotifications([successNotification("Create", t("The flex opti has been created."))])
        )
        navigate(`/sites/${siteId}/optimization/flexibility/${flexId}/flex-opti/`)
      })
      .catch(error => {
        if (error.response.data) {
          setErrors(error.response.data)
        }
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  return (
    data && (
      <>
        <div className="base-content-title">
          <h2>{t("Create flex opti")}</h2>
        </div>
        <form
          className="form-view"
          onSubmit={create}
        >
          <FormContent
            model={FlexOpti()}
            data={data}
            setData={setData}
            errors={errors}
          />
          <div className="action-buttons-wrapper">
            <Button
              rounded
              severity="success"
              label={t("Save")}
              type="submit"
              icon="fa-solid fa-floppy-disk"
            />
          </div>
        </form>
      </>
    )
  )
}
