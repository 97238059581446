import AssetRoutes from "/apps/assets/routes"
import { retrieve } from "/apps/assets/site/api"
import Create from "/apps/assets/site/components/Create"
import Delete from "/apps/assets/site/components/Delete"
import Detail from "/apps/assets/site/components/Detail"
import List from "/apps/assets/site/components/List"
import ConnectivityRoutes from "/apps/connectivity/routes"
import EnergyRoutes from "/apps/energy/routes"
import EventRoutes from "/apps/event/routes"
import OperationRoutes from "/apps/operation/routes"
import OptimizationRoutes from "/apps/optimization/routes"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { site, updateSite } from "/utils/store/site"
import { Loader } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import * as React from "react"
import { createContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, Route, Routes, useParams } from "react-router-dom"

const SiteContentContext = createContext()

const SiteContent = () => {
  const siteStore = useSelector(site)
  const auth = useAuth()
  const { siteId } = useParams()
  const [data, setData] = useState()
  const dispatch = useDispatch()

  const retrieveDetail = async () => {
    await retrieve(siteId, auth.userData.access_token)
      .then(({ data }) => {
        setData(data)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification("Site", message)]))
      })
  }

  useEffect(() => {
    siteId && retrieveDetail()
  }, [siteId])

  useEffect(() => {
    data && dispatch(updateSite(data))
  }, [data])

  return (
    <SiteContentContext.Provider value={{ retrieveDetail }}>
      {siteStore.id ? <Outlet /> : <Loader />}
    </SiteContentContext.Provider>
  )
}

export { SiteContentContext }
export default function SiteRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="create"
        element={<Create />}
      />
      <Route
        path=":siteId"
        element={<SiteContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
        <Route
          path="assets/*"
          element={<AssetRoutes />}
        />
        <Route
          path="energy/*"
          element={<EnergyRoutes />}
        />
        <Route
          path="connectivity/*"
          element={<ConnectivityRoutes />}
        />
        <Route
          path="optimization/*"
          element={<OptimizationRoutes />}
        />
        <Route
          path="operations/*"
          element={<OperationRoutes />}
        />
        <Route
          path="events/*"
          element={<EventRoutes />}
        />
      </Route>
    </Routes>
  )
}
