import { notifications } from "/utils/store/notification"

import { Toast } from "primereact/toast"
import React, { useEffect, useRef } from "react"

import { useSelector } from "react-redux"

export default function Notifications() {
  const items = useSelector(notifications)
  const toast = useRef(null)

  useEffect(() => {
    toast.current.show(items)
  }, [items])

  return (
    <Toast
      ref={toast}
      position="bottom-center"
    />
  )
}
