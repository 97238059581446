import { retrieveAll } from "/apps/organization/api"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import {
  BaseLayout,
  ButtonWithLoader,
  FormContent
} from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { add, addContract, getPriceZones, getWeatherZones } from "../api"
import { SiteContractForm, SiteForm } from "../models"

const Create = () => {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [priceZones, setPriceZones] = useState([])
  const [weatherZones, setWeatherZones] = useState([])
  const [organizations, setOrganizations] = useState([])
  const [processing, setProcessing] = useState(false)
  const [data, setData] = useState({
    organization_id: organizationStore.id,
    active: false,
    synchronise_legacy: true
  })
  const [contractData, setContractData] = useState({})
  const [errors, setErrors] = useState({})
  const [loadingPriceZone, setLoadingPriceZone] = useState(false)
  const [loadingWeatherZone, setLoadingWeatherZone] = useState(false)
  const [loadingOrganizations, setLoadingOrganizations] = useState(false)

  const goBack = e => {
    e && e.preventDefault()
    navigate(-1)
  }

  const processCreation = async () => {
    const postData = {
      ...data,
      latitude: `${data.latitude}`,
      longitude: `${data.longitude}`,
      altitude: `${data.altitude}`
    }

    const siteResponse = await add(postData, auth.userData.access_token)
    const contractResponse = await addContract(
      { site_id: siteResponse.data.id, ...contractData },
      auth.userData.access_token
    )

    return [siteResponse, contractResponse]
  }

  const create = async e => {
    e.preventDefault()
    setProcessing(true)

    processCreation()
      .then(([siteData]) => {
        dispatch(addNotifications([successNotification("Site", t("The site has been created."))]))
        navigate(`/organizations/${organizationStore.id}/sites/${siteData.data.id}`)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification("Site", message)]))
        error.response && error.response.data && setErrors(error.response.data)
      })
      .finally(() => setProcessing(false))
  }

  const getPZ = async () => {
    setLoadingPriceZone(true)
    await getPriceZones(auth.userData.access_token)
      .then(({ data }) => {
        data && setPriceZones(data)
      })
      .catch(({ message }) => {
        dispatch(addNotifications([errorNotification("Site", t("Error loading price zones"))]))
        console.error(message)
      })
      .finally(() => setLoadingPriceZone(false))
  }

  const getWZ = async countryCode => {
    setLoadingWeatherZone(true)
    await getWeatherZones(countryCode, auth.userData.access_token)
      .then(({ data }) => {
        data.results && setWeatherZones(Array.isArray(data.results) ? data.results : [])
      })
      .catch(({ message }) => {
        dispatch(
          addNotifications([errorNotification("Weather zone", "Error loading weather zone")])
        )
        console.error(message)
      })
      .finally(() => setLoadingWeatherZone(false))
  }

  const getOrganizations = async () => {
    setLoadingOrganizations(true)
    await retrieveAll(auth.userData.access_token)
      .then(results => {
        results && setOrganizations(results)
      })
      .catch(({ message }) => {
        dispatch(addNotifications([errorNotification("Site", t("Error loading organizations"))]))
        console.error(message)
      })
      .finally(() => setLoadingOrganizations(false))
  }

  useEffect(() => {
    getPZ()
    getOrganizations()
  }, [organizationStore.id])

  useEffect(() => {
    data.country && getWZ(data.country)
  }, [data.country])

  useEffect(() => {
    if (data.weather_zone_id) {
      const wz = weatherZones.find(item => item.id === data.weather_zone_id)
      wz && setData({ ...data, weather_zone_timezone: wz.timezone })
    }
  }, [data.weather_zone_id])

  return (
    data && (
      <BaseLayout title={t("Create site")}>
        <form
          className="form-view"
          onSubmit={create}
        >
          <FormContent
            model={SiteForm(
              priceZones,
              weatherZones,
              organizations,
              loadingPriceZone,
              loadingWeatherZone,
              loadingOrganizations
            )}
            initial={data}
            data={data}
            setData={setData}
            errors={errors}
          />

          <div className="base-separator">
            <h2>{t("Contract")}</h2>
          </div>

          <FormContent
            model={SiteContractForm()}
            initial={contractData}
            data={contractData}
            setData={setContractData}
            errors={errors}
          />

          <div className="action-buttons-wrapper">
            <Button
              rounded
              outlined
              severity="secondary"
              label={t("Cancel")}
              icon="fa-solid fa-xmark"
              onClick={goBack}
              disabled={
                processing || loadingPriceZone || loadingWeatherZone || loadingOrganizations
              }
            />
            <ButtonWithLoader
              rounded
              severity="success"
              label={t("Save")}
              type="submit"
              icon="fa-solid fa-floppy-disk"
              disabled={
                processing || loadingPriceZone || loadingWeatherZone || loadingOrganizations
              }
            />
          </div>
        </form>
      </BaseLayout>
    )
  )
}

export default Create
