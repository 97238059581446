import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputText } from "primereact/inputtext"

import { commonsFields, commonsOverview } from "./commons"

export const Bacnet = (nodes, loadingNodes) => {
  return {
    ...commonsFields(nodes, loadingNodes),
    ...{
      object_instance: {
        label: i18n.t("Object instance", { ns: "assets" }),
        field: InputNumber,
        options: { required: true }
      },
      object_name: {
        label: i18n.t("Object name", { ns: "assets" }),
        field: InputText,
        options: { maxLength: 255 }
      },
      object_type: {
        label: i18n.t("Object type", { ns: "assets" }),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "ANALOG_INPUT", label: i18n.t("Analogue Input (0)", { ns: "assets" }) },
            { value: "ANALOG_OUTPUT", label: i18n.t("Analogue Output (1)", { ns: "assets" }) },
            { value: "ANALOG_VALUE", label: i18n.t("Analogue Value (2)", { ns: "assets" }) },
            { value: "BINARY_INPUT", label: i18n.t("Binary Input (3)", { ns: "assets" }) },
            { value: "BINARY_OUTPUT", label: i18n.t("Binary Output (4)", { ns: "assets" }) },
            { value: "BINARY_VALUE", label: i18n.t("Binary Value (5)", { ns: "assets" }) },
            {
              value: "MULTI_STATE_INPUT",
              label: i18n.t("Multi State Input (13)", { ns: "assets" })
            },
            {
              value: "MULTI_STATE_OUTPUT",
              label: i18n.t("Multi State Output (14)", { ns: "assets" })
            }
          ]
        }
      },
      bacnet_property: {
        label: i18n.t("Bacnet property", { ns: "assets" }),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "PRESENT_VALUE", label: i18n.t("Present value", { ns: "assets" }) },
            { value: "STATUS_FLAGS", label: i18n.t("Status flags", { ns: "assets" }) },
            { value: "PRIORITY_ARRAY", label: i18n.t("Priority array", { ns: "assets" }) },
            { value: "RELINQUISH_DEFAULT", label: i18n.t("Relinquish default", { ns: "assets" }) },
            { value: "UNINHIBIT_PRIORITY", label: i18n.t("Uninhibit priority", { ns: "assets" }) },
            {
              value: "PRIORITY_1",
              label: i18n.t("Priority {{priority}}", { ns: "assets", priority: 1 })
            },
            {
              value: "PRIORITY_2",
              label: i18n.t("Priority {{priority}}", { ns: "assets", priority: 2 })
            },
            {
              value: "PRIORITY_3",
              label: i18n.t("Priority {{priority}}", { ns: "assets", priority: 3 })
            },
            {
              value: "PRIORITY_4",
              label: i18n.t("Priority {{priority}}", { ns: "assets", priority: 4 })
            },
            {
              value: "PRIORITY_5",
              label: i18n.t("Priority {{priority}}", { ns: "assets", priority: 5 })
            },
            {
              value: "PRIORITY_6",
              label: i18n.t("Priority {{priority}}", { ns: "assets", priority: 6 })
            },
            {
              value: "PRIORITY_7",
              label: i18n.t("Priority {{priority}}", { ns: "assets", priority: 7 })
            },
            {
              value: "PRIORITY_8",
              label: i18n.t("Priority {{priority}}", { ns: "assets", priority: 8 })
            },
            {
              value: "PRIORITY_9",
              label: i18n.t("Priority {{priority}}", { ns: "assets", priority: 9 })
            },
            {
              value: "PRIORITY_10",
              label: i18n.t("Priority {{priority}}", { ns: "assets", priority: 10 })
            },
            {
              value: "PRIORITY_11",
              label: i18n.t("Priority {{priority}}", { ns: "assets", priority: 11 })
            },
            {
              value: "PRIORITY_12",
              label: i18n.t("Priority {{priority}}", { ns: "assets", priority: 12 })
            },
            {
              value: "PRIORITY_13",
              label: i18n.t("Priority {{priority}}", { ns: "assets", priority: 13 })
            },
            {
              value: "PRIORITY_14",
              label: i18n.t("Priority {{priority}}", { ns: "assets", priority: 14 })
            },
            {
              value: "PRIORITY_15",
              label: i18n.t("Priority {{priority}}", { ns: "assets", priority: 15 })
            },
            {
              value: "PRIORITY_16",
              label: i18n.t("Priority {{priority}}", { ns: "assets", priority: 16 })
            }
          ]
        }
      },
      bacnet_priority: {
        label: i18n.t("Bacnet priority", { ns: "assets" }),
        field: InputNumber,
        options: {}
      },
      bacnet_priority_uninhibition: {
        label: i18n.t("Bacnet priority uninhibition", { ns: "assets" }),
        field: InputNumber,
        options: {}
      }
    }
  }
}

export const BacnetOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      object_instance: { label: i18n.t("Object instance", { ns: "assets" }) },
      object_name: { label: i18n.t("Object name", { ns: "assets" }) },
      object_type: { label: i18n.t("Object type", { ns: "assets" }) },
      bacnet_priority: { label: i18n.t("Bacnet priority", { ns: "assets" }) },
      bacnet_priority_uninhibition: {
        label: i18n.t("Bacnet priority uninhibition", { ns: "assets" })
      }
    }
  }
}
