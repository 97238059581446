import React, { useState, useEffect } from "react"
import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import { useTranslation } from "react-i18next"
import BillFixPriceFields from "./BillFixPriceFields"
import BillSchedulePriceFields from "./BillSchedulePriceFields"

/* import PriceFixBillForm from "./PriceFixBillForm"
import PriceSchBillForm from "./PriceSchBillForm" */

export default function BillPriceField(props) {
  const [currentPrice, setCurrentPrice] = useState(props.price)
  const { t } = useTranslation(["energy"])

  const priceTypes = [
    { value: "FIX", label: "Fix" },
    { value: "SCH", label: "Schedule" }
  ]

  useEffect(() => {
    const prices = [...props.prices]
    prices[props.index] = currentPrice
    props.setData(oldData => ({
      ...oldData,
      prices: prices
    }))
  }, [currentPrice])

  const update = (value, key) => {
    setCurrentPrice({ ...currentPrice, ...{ [key]: value } })
  }

  const removePrice = () => {
    const prices = [...props.prices]
    prices.splice(props.index, 1)
    props.setData(oldData => ({
      ...oldData,
      prices
    }))
  }

  return (
    <div className="price-fields flex flex-column">
      <i
        className="fa-solid fa-trash ml-auto cursor-pointer"
        onClick={removePrice}
      ></i>
      <div className="field dropdown">
        <label className="label label-required">{t("Price type")}</label>
        <div className="field-input w-full">
          <Dropdown
            options={priceTypes}
            value={currentPrice.price_type}
            onChange={e => update(e.value, "price_type")}
            placeholder={t("Choose price type")}
          />
        </div>
      </div>
      {currentPrice.price_type === "FIX" && (
        <BillFixPriceFields
          price={currentPrice}
          setPrice={setCurrentPrice}
        />
      )}

      {currentPrice.price_type === "SCH" && (
        <BillSchedulePriceFields
          price={currentPrice}
          setPrice={setCurrentPrice}
        />
      )}
    </div>
  )
}
