import { DateInput, Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import { MultiSelect } from "primereact/multiselect"

export const Contract = (
  energyDeliveryPoints,
  energyCompanies,
  gateways,
  loadingDeliveryPoints = false,
  loadingCompanies = false,
  loadingGateways = false
) => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: {
      label: i18n.t("Name", { ns: "energy" }),
      field: InputText,
      options: {
        required: true,
        maxLength: 255
      }
    },
    contract_type: {
      label: i18n.t("Contract type", { ns: "energy" }),
      field: Dropdown,
      options: {
        options: [
          { value: i18n.t("Energy", { ns: "energy" }), label: "Energy" },
          { value: i18n.t("Peak demand", { ns: "energy" }), label: "Peak" },
          { label: i18n.t("Flexibility", { ns: "energy" }), value: "Flexibility" }
        ],
        placeholder: "Select contract type",
        required: true
      }
    },
    energy_delivery_point: {
      label: i18n.t("Energy delivery point", { ns: "energy" }),
      field: Dropdown,
      options: {
        required: true,
        options: energyDeliveryPoints,
        optionLabel: "meter_id",
        optionValue: "id",
        placeholder: loadingDeliveryPoints ? "Loading..." : "Select delivery point",
        loading: loadingDeliveryPoints
      }
    },
    description: {
      label: i18n.t("Description", { ns: "energy" }),
      field: InputTextarea,
      options: {}
    },
    contract_step: {
      label: i18n.t("Contract step", { ns: "energy" }),
      field: InputNumber,
      options: {
        showButtons: true
      }
    },
    start_validity: {
      label: i18n.t("Start validity", { ns: "energy" }),
      field: DateInput,
      options: {}
    },
    end_validity: {
      label: i18n.t("End validity", { ns: "energy" }),
      field: DateInput,
      options: {}
    },
    energy_company: {
      label: i18n.t("Market player", { ns: "energy" }),
      field: Dropdown,
      options: {
        required: true,
        options: energyCompanies,
        optionValue: "id",
        optionLabel: "name",
        placeholder: loadingCompanies ? "Loading..." : "Select company",
        loading: loadingCompanies
      }
    },
    optimization_gateways: {
      label: i18n.t("Optimization gateways", { ns: "energy" }),
      field: MultiSelect,
      options: {
        options: gateways,
        optionValue: "id",
        optionLabel: "name",
        filter: gateways && gateways.length > 20,
        display: "chip",
        placeholder: loadingGateways ? "Loading..." : "Select gateway",
        loading: loadingGateways
      }
    }
  }
}

export const ContractOverview = () => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: { label: i18n.t("Name", { ns: "energy" }) },
    contract_type: { label: i18n.t("Contract type", { ns: "energy" }) },
    energy_delivery_point_meter_id: { label: i18n.t("Energy delivery point", { ns: "energy" }) },
    description: { label: i18n.t("Description", { ns: "energy" }) },
    contract_step: { label: i18n.t("Contract step", { ns: "energy" }) },
    start_validity: { label: i18n.t("Start validity", { ns: "energy" }) },
    end_validity: { label: i18n.t("End validity", { ns: "energy" }) },
    energy_company_name: { label: i18n.t("Market player", { ns: "energy" }) },
    optimization_gateways_names: { label: i18n.t("Optimization gateways", { ns: "energy" }) }
  }
}
