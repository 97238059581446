import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import {
  ListActions,
  ListLayout,
  BaseLayout
} from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { retrieveList } from "../api"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const dispatch = useDispatch()
  const auth = useAuth()
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})
  const { t } = useTranslation(["configuration"])

  const columns = [
    { field: "equipment_type", header: "Name", sortable: true },
    { field: "name", header: "Subtype", sortable: true }
  ]

  const retrieve = async () => {
    setLoading(true)

    await retrieveList(auth.userData.access_token)
      .then(response => {
        response.data && response.data.results && setItems(response.data.results)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    retrieve()
  }, [])

  const menuFilters = { columns, items, filters, setFilters }

  return (
    <BaseLayout title={t("Equipment type")}>
      <section className="list-section">
        <ListActions filters={menuFilters}></ListActions>

        <ListLayout
          columns={columns}
          value={items}
          objectName="Equipment type"
          loading={loading}
          filters={filters}
        />
      </section>
    </BaseLayout>
  )
}
