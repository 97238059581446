import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputSwitch } from "primereact/inputswitch"
import { commonsFields, commonsOverview } from "./commons"

export const ModbusBit = (nodes, loadingNodes) => {
  return {
    ...commonsFields(nodes, loadingNodes),
    ...{
      address: {
        label: i18n.t("Address", { ns: "assets" }),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          max: 999,
          showButtons: true
        }
      },
      writable: {
        label: i18n.t("Writable", { ns: "assets" }),
        field: InputSwitch,
        options: {}
      },
      bit_type: {
        label: i18n.t("Type of bit", { ns: "assets" }),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "COIL", label: i18n.t("Coil", { ns: "assets" }) },
            { value: "DISCRETE_INPUT", label: i18n.t("Discrete input", { ns: "assets" }) }
          ]
        }
      }
    }
  }
}

export const ModbusBitOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      address: { label: i18n.t("Address", { ns: "assets" }) },
      read_write: { label: i18n.t("Read / Write", { ns: "assets" }) },
      bit_type: { label: i18n.t("Type of bit", { ns: "assets" }) }
    }
  }
}

export const ModbusRegister = (nodes, loadingNodes) => {
  return {
    ...commonsFields(nodes, loadingNodes),
    ...{
      address: {
        label: i18n.t("Address", { ns: "assets" }),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          max: 999,
          showButtons: true
        }
      },
      number_of_words: {
        label: i18n.t("Number of words", { ns: "assets" }),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          max: 999,
          showButtons: true
        }
      },
      writable: {
        label: i18n.t("Writable", { ns: "assets" }),
        field: InputSwitch,
        options: {}
      },
      register_type: {
        label: i18n.t("Type of register", { ns: "assets" }),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "INPUT_REGISTER", label: i18n.t("Input register", { ns: "assets" }) },
            { value: "HOLDING_REGISTER", label: i18n.t("Holding register", { ns: "assets" }) }
          ]
        }
      },
      register_format: {
        label: i18n.t("Format", { ns: "assets" }),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "INT", label: i18n.t("Int", { ns: "assets" }) },
            { value: "UINT", label: i18n.t("UInt", { ns: "assets" }) },
            { value: "FLOAT", label: i18n.t("Float", { ns: "assets" }) }
          ]
        }
      },
      swap_words: {
        label: i18n.t("Swap words", { ns: "assets" }),
        field: InputSwitch,
        options: {}
      },
      swap_bytes: {
        label: i18n.t("Swap bytes", { ns: "assets" }),
        field: InputSwitch,
        options: {}
      }
    }
  }
}

export const ModbusRegisterOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      address: { label: i18n.t("Address", { ns: "assets" }) },
      number_of_words: { label: i18n.t("Number of words", { ns: "assets" }) },
      read_write: { label: i18n.t("Read / Write", { ns: "assets" }) },
      register_type: { label: i18n.t("Type of register", { ns: "assets" }) },
      register_format: { label: i18n.t("Format", { ns: "assets" }) },
      swap_words: { label: i18n.t("Swap words", { ns: "assets" }) },
      swap_bytes: { label: i18n.t("Swap bytes", { ns: "assets" }) }
    }
  }
}

export const ModbusRegisterBitExtract = (nodes, loadingNodes) => {
  return {
    ...commonsFields(nodes, loadingNodes),
    ...{
      address: {
        label: i18n.t("Address", { ns: "assets" }),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          max: 999,
          showButtons: true
        }
      },
      number_of_words: {
        label: i18n.t("Number of words", { ns: "assets" }),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          max: 999,
          showButtons: true
        }
      },
      register_type: {
        label: i18n.t("Type of register", { ns: "assets" }),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "INPUT_REGISTER", label: i18n.t("Input register", { ns: "assets" }) },
            { value: "HOLDING_REGISTER", label: i18n.t("Holding register", { ns: "assets" }) }
          ]
        }
      },
      register_format: {
        label: i18n.t("Format", { ns: "assets" }),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "INT", label: i18n.t("Int", { ns: "assets" }) },
            { value: "UINT", label: i18n.t("UInt", { ns: "assets" }) },
            { value: "FLOAT", label: i18n.t("Float", { ns: "assets" }) }
          ]
        }
      },
      swap_words: {
        label: i18n.t("Swap words", { ns: "assets" }),
        field: InputSwitch,
        options: {}
      },
      swap_bytes: {
        label: i18n.t("Swap bytes", { ns: "assets" }),
        field: InputSwitch,
        options: {}
      },
      start_bit: {
        label: i18n.t("Bit start", { ns: "assets" }),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          max: 999,
          showButtons: true
        }
      },
      number_of_bits: {
        label: i18n.t("Number of Bits", { ns: "assets" }),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          max: 999,
          showButtons: true
        }
      },
      writable: {
        label: i18n.t("Writable", { ns: "assets" }),
        field: InputSwitch,
        options: {}
      }
    }
  }
}

export const ModbusRegisterBitExtractOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      address: { label: i18n.t("Address", { ns: "assets" }) },
      number_of_words: { label: i18n.t("Number of words", { ns: "assets" }) },
      register_type: { label: i18n.t("Type of register", { ns: "assets" }) },
      register_format: { label: i18n.t("Format", { ns: "assets" }) },
      swap_words: { label: i18n.t("Swap words", { ns: "assets" }) },
      swap_bytes: { label: i18n.t("Swap bytes", { ns: "assets" }) },
      start_bit: { label: i18n.t("Bit start", { ns: "assets" }) },
      number_of_bits: { label: i18n.t("Number of Bits", { ns: "assets" }) },
      read_write: { label: i18n.t("Read / Write", { ns: "assets" }) }
    }
  }
}
