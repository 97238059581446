{
  "0: digital input": "0 : entrée numérique",
  "1: digital output": "1 : sortie numérique",
  "2: analog input": "2 : entrée analogique",
  "3: analog output": "3 : sortie analogique",
  "Acceptable values": "Valeurs acceptables",
  "Access level": "Access level",
  "Action": "Action",
  "Active": "Actif",
  "Add": "Ajouter",
  "Add values": "Ajouter des valeurs",
  "Address": "Adresse",
  "Aggregator Response": "Réponse de l'agrégateur",
  "Altitude": "Altitude",
  "An error occured during the import, see more details in the error report file": "Une erreur s'est produite lors de l'importation, voir plus de détails dans le fichier de rapport d'erreurs.",
  "Analogue Input (0)": "Analogue Input (0)",
  "Analogue Output (1)": "Analogue Output (1)",
  "Analogue Value (2)": "Analogue Value (2)",
  "Are you sure you want to delete this interface?": "Êtes-vous sûr de vouloir supprimer cette interface ?",
  "Automation node": "Nœud d'automatisation",
  "Automation nodes": "Nœuds d'automatisation",
  "Automation point": "Point d'automatisation",
  "Automation point type": "Type de point d'automatisation",
  "Automation points": "Points d'automatisation",
  "Back to state list": "Retour à la liste des états",
  "Bacnet": "Bacnet",
  "Bacnet network": "Réseau Bacnet",
  "Bacnet number": "Numéro Bacnet",
  "Bacnet priority": "Bacnet priority",
  "Bacnet priority uninhibition": "Bacnet priority uninhibition",
  "Bacnet property": "Bacnet property",
  "Base informations": "Informations de base",
  "Batch size": "Taille du lot",
  "Batch size read": "Lecture de la taille du lot",
  "Batch size write": "Écriture de la taille du lot",
  "Bills": "Facturations",
  "Binary Input (3)": "Binary Input (3)",
  "Binary Output (4)": "Binary Output (4)",
  "Binary Value (5)": "Binary Value (5)",
  "Bit start": "Bit start",
  "Boolean": "Boolean",
  "Building": "Bâtiment",
  "Cabinet": "Armoire",
  "Cabinet name": "Nom du cabinet",
  "Cancel": "Annuler",
  "Carel node": "Nœud Carel",
  "Choose a node type": "Choisissez un type de nœud",
  "Choose a sub type": "Choisissez un sous-type",
  "Choose a type": "Choisissez un type",
  "Cid": "Cid",
  "CIDR format: {{format}}": "CIDR format: {{format}}",
  "Circuit breaker": "Disjoncteur",
  "Circuit breaker relation": "Relation avec le disjoncteur",
  "Circuit breakers": "Disjoncteurs",
  "Circuit breakers relations": "Relations avec le disjoncteur",
  "City": "Ville",
  "Coef": "Coef",
  "Coil": "Coil",
  "Comment": "Commentaire",
  "Communicable": "Communicable",
  "Communication": "Communication",
  "Condition": "Condition",
  "Configuration": "Configuration",
  "Confirm password": "Confirmer le mot de passe",
  "Confirmed curtail schedule": "Calendrier de réduction confirmé",
  "Confirmed curtail schedules": "Calendriers de réduction confirmés",
  "Connect": "Connecter",
  "Connect a network": "Connecter un réseau",
  "Connectivity view": "Vue de la connectivité",
  "Connector instance": "Instance de connecteur",
  "Connector instances": "Instances de connecteurs",
  "Connector instances has been removed": "Les instances de connecteurs ont été supprimées.",
  "Constraint states": "États de contrainte",
  "Contract": "Contrat",
  "Contract type": "Type de contrat",
  "Contracts": "Contrats",
  "Cooled": "Refroidi",
  "Country": "Pays",
  "Create": "Créer",
  "Create automation node": "Créer un nœud d'automatisation",
  "Create automation point": "Créer un point d'automatisation",
  "Create circuit breaker relation": "Create circuit breaker relation",
  "Create connector instance": "Créer une instance de connecteur",
  "Create contract": "Créer un contrat",
  "Create curcuit breaker": "Créer un disjoncteur",
  "Create curtail parameter": "Créer un paramètre de réduction",
  "Create energy delivery point": "Créer un point de livraison d'énergie",
  "Create equipment": "Créer un équipement",
  "Create flex opti": "Créer une optimisation flexible",
  "Create gateway": "Créer une passerelle",
  "Create load curtail schedule": "Créer un calendrier de réduction de charge",
  "Create network": "Créer un réseau",
  "Create point": "Créer un point",
  "Create site": "Créer un site",
  "Create space": "Créer un espace",
  "Create state": "Créer un état",
  "Create store": "Créer un magasin",
  "Created at": "Créé à",
  "Curtail capacity": "Réduire la capacité",
  "Curtail end": "Fin de la réduction",
  "Curtail parameters": "Paramètres de réduction",
  "Curtail parameters have been created": "Les paramètres de réduction ont été créés.",
  "Curtail parameters have been removed": "Les paramètres de réduction ont été supprimés.",
  "Curtail parameters have been updated": "Les paramètres de réduction ont été mis à jour.",
  "Curtail start": "Début de la réduction",
  "Data type": "Data type",
  "Delete": "Supprimer",
  "Delivery points": "Points de livraison",
  "Deploy": "Déployer",
  "Deployed": "Déployé",
  "Description": "Description",
  "Device ID": "ID de l'appareil",
  "Device instance": "Instance de l'appareil",
  "Device type": "Type d'appareil",
  "Disable storage": "Désactiver le stockage",
  "Discrete input": "Discrete input",
  "Distributor": "Distributeur",
  "Double": "Double",
  "Download": "Télécharger",
  "Edge blocks": "Blocs Edge",
  "Edge controllers": "Contrôleurs Edge",
  "Electrical": "Électrique",
  "End validity": "Fin de validité",
  "Energy": "Énergie",
  "Energy delivery point": "Point de livraison d'énergie",
  "Energy delivery points": "Points de livraison d'énergie",
  "Energy type": "Type d'énergie",
  "Equipment": "Équipement",
  "Equipment sub type": "Sous-type d'équipement",
  "Equipment type": "Type d'équipement",
  "Equipments": "Équipements",
  "Equipments has been removed": "Les équipements ont été supprimés.",
  "Equipments powered": "Équipements alimentés",
  "Equivalent metering": "Mesurage équivalent",
  "Equivalent meterings": "Mesurages équivalents",
  "Error loading organizations": "Error loading organizations",
  "Error loading price zones": "Erreur de chargement des zones de prix",
  "Export": "Exporter",
  "External ID": "ID externe",
  "External point type": "Type de point externe",
  "Flex contract": "Contrat flexible",
  "Flex opti": "Optimisation flexible",
  "Flexibility": "Flexibilité",
  "Float": "Float",
  "Forced stop hours": "Heures d'arrêt forcé",
  "Forced stop reset": "Réinitialisation de l'arrêt forcé",
  "Format": "Format",
  "Gateway": "Passerelle",
  "Gateway interface": "Interface de passerelle",
  "Gateway interfaces": "Interfaces de passerelle",
  "Gateway point": "Point de passerelle",
  "Gateway point text": "Texte du point de passerelle",
  "Gateway point value": "Valeur du point de passerelle",
  "Gauge": "Jauge",
  "GRDF": "GRDF",
  "Hardware": "Matériel",
  "Heated": "Chauffé",
  "Height (m)": "Hauteur (m)",
  "Historical load curtailment for": "Réduction de charge historique pour",
  "History": "Historique",
  "Holding register": "Holding register",
  "HTTP": "HTTP",
  "HTTPS": "HTTPS",
  "ID Var": "ID Var",
  "Import": "Importer",
  "Infos": "Infos",
  "Inhibit states": "Inhiber les états",
  "Input register": "Input register",
  "Int": "Int",
  "Int{{value}}": "Int{{value}}",
  "IO node type": "Type de nœud E/S",
  "IP": "IP",
  "IP addres range": "Plage d'adresses IP",
  "IP address": "Adresse IP",
  "IP Address": "Adresse IP",
  "IP addresses": "Adresses IP",
  "IP Range": "Plage IP",
  "Is Logic": "Is Logic",
  "Is measured by": "Est mesuré par",
  "Koovea": "Koovea",
  "Latitude": "Latitude",
  "Line ID": "ID de ligne",
  "Linear constant": "Constante linéaire",
  "Linear multiplier": "Multiplicateur linéaire",
  "Linked equipments": "Équipements liés",
  "List": "Liste",
  "Load curtail parameters": "Paramètres de réduction de charge",
  "Load curtail schedule": "Calendrier de réduction de charge",
  "Load curtailment for": "Réduction de charge pour",
  "Longitude": "Longitude",
  "Low cipher": "Chiffre faible",
  "Manage modbus response timeouts": "Gérer les délais d'attente de réponse Modbus",
  "Manage timeout": "Gérer le délai d'attente",
  "Manufacturer": "Fabricant",
  "Map": "Map",
  "Market player": "Acteur du marché",
  "Market player interaction": "Interaction avec l'acteur du marché",
  "Market schedule group proposal history": "Historique des propositions de groupe de calendrier de marché",
  "Measured by": "Mesuré par",
  "Meter ID": "ID de compteur",
  "MobileAlerts": "MobileAlerts",
  "Mod": "Mod",
  "Model": "Modèle",
  "MSTP address": "Adresse MSTP",
  "Multi State Input (13)": "Multi State Input (13)",
  "Multi State Output (14)": "Multi State Output (14)",
  "Multi-case name": "Nom multi-cas",
  "MySirius": "MySirius",
  "Name": "Nom",
  "Namespace index": "Index de l'espace de nom",
  "Network": "Réseau",
  "Network interface": "Interface réseau",
  "Network interfaces": "Interfaces réseau",
  "Network interfaces has been removed": "Les interfaces réseaux ont été supprimées.",
  "Network IP Range": "Plage IP du réseau",
  "Networks": "Réseaux",
  "Networks has been removed": "Les réseaux ont été supprimés.",
  "No": "Non",
  "Node": "Nœud",
  "Node address": "Adresse du nœud",
  "Node ID": "Node ID",
  "Node interfaces": "Interfaces de nœud",
  "Nodes has been removed": "Les nœuds ont été supprimés.",
  "Nominal Equipments powered": "Équipements nominaux alimentés",
  "Nominal intensity": "Intensité nominale",
  "Num suction": "Nombre d'aspirations",
  "Number of Bits": "Number of Bits",
  "Number of points": "Nombre de points",
  "Number of stores": "Nombre de magasins",
  "Number of words": "Number of words",
  "Object instance": "Instance de l'objet",
  "Object name": "Nom d'objet",
  "Object type": "Type d'objet",
  "Optimization gateways": "Passerelles d'optimisation",
  "Optimized": "Optimisé",
  "Organization": "Organisation",
  "Original point value": "Valeur du point d'origine",
  "Overview": "Vue d'ensemble",
  "Parent circuit breaker": "Disjoncteur parent",
  "Parent space": "Espace parent",
  "Password": "Mot de passe",
  "Passwords must be the same": "Les mots de passe doivent être identiques",
  "Path": "Chemin",
  "Percent of share economics for BeeBryte": "Pourcentage de partage économique pour BeeBryte",
  "Percentage of share economics for BeeBryte": "Pourcentage de partage économique pour BeeBryte",
  "Permeable": "Perméable",
  "Point": "Point",
  "Points": "Points",
  "Points has been removed": "Les points ont été supprimés.",
  "Points writable": "Points modifiables",
  "Port": "Port",
  "Powers": "Alimente",
  "Present value": "Valeur présente",
  "Pretty name": "Nom joli",
  "Price zone": "Zone de prix",
  "Priority": "Priorité",
  "Priority {{priority}}": "Priority {{priority}}",
  "Priority array": "Priority array",
  "Proposal for aggregator": "Proposition pour l'agrégateur",
  "Proposal sent": "Proposition envoyée",
  "Proposed by": "Proposé par",
  "Proposed curtail schedule": "Calendrier de réduction proposé",
  "Proposed curtail schedules": "Calendriers de réduction proposés",
  "Proposed time": "Temps proposé",
  "Protocol": "Protocole",
  "Protocol instance": "Instance de protocole",
  "Protocol instances": "Instances de protocole",
  "Protocol instances has been removed": "Les instances de protocole ont été supprimées.",
  "Protocol type": "Type de protocole",
  "Protocols": "Protocoles",
  "Pulling interval": "Intervalle de tirage",
  "Rack ID": "ID de rack",
  "Read": "Lire",
  "Read / Write": "Lire / Écrire",
  "Relation between": "Relation entre {{circuitBreakerRelation.circuit_breaker_name}} et {{circuitBreakerRelation.equipment_name}}",
  "Relation type": "Type de relation",
  "Relinquish default": "Renoncer au défaut",
  "Request time": "Temps de demande",
  "Requested by": "Demandé par",
  "Requested?": "Demandé ?",
  "Response timeout": "Délai de réponse",
  "Response timeout (seconds)": "Délai de réponse (secondes)",
  "Response timeouts has been updated": "Les délais de réponse ont été mis à jour",
  "Router": "Routeur",
  "Safe states": "États sécurisés",
  "Save": "Enregistrer",
  "Schedule date": "Date de planification",
  "Scheme": "Schéma",
  "Security mode": "Mode de sécurité",
  "Security policy": "Politique de sécurité",
  "Select a price zone": "Sélectionnez une zone de prix",
  "Select a weather zone": "Sélectionnez une zone météorologique",
  "Select an organizations": "Sélectionnez des organisations",
  "Send to aggregator": "Envoyer à l'agrégateur",
  "Sensinov": "Sensinov",
  "Sent by": "Envoyé par",
  "Serial": "Série",
  "Serial ID": "ID de série",
  "Server certificate path": "Chemin du certificat du serveur",
  "Sigfox": "Sigfox",
  "Site": "Site",
  "Sites": "Sites",
  "Slave ID": "Slave ID",
  "Sleep time": "Temps de sommeil",
  "SM Nodes": "Nœuds SM",
  "SM Type": "Type SM",
  "Software": "Logiciel",
  "Space": "Espace",
  "Spaces": "Espaces",
  "Spaces has been removed": "Les espaces ont été supprimés.",
  "Start validity": "Début de validité",
  "State": "État",
  "State history": "Historique de l'état",
  "State name": "Nom de l'état",
  "State values": "Valeurs d'état",
  "States": "États",
  "Status": "Statut",
  "Status flags": "Indicateurs d'état",
  "Store": "Stockage",
  "Store type": "Type de stockage",
  "Stores": "Stockages",
  "Stores has been removed": "Les stockages ont été supprimés.",
  "String": "String",
  "Subtype": "Sous-type",
  "Suction ID": "ID d'aspiration",
  "Summary for": "Résumé pour",
  "Surface area (m²)": "Surface (m²)",
  "Swap bytes": "Octets d'échange",
  "Swap words": "Mots d'échanges",
  "Synchronize legacy": "Synchroniser l'héritage",
  "System manager": "Gestionnaire de système",
  "Tag": "Tag",
  "Target": "Cible",
  "Template": "Modèle",
  "The automation node has been created": "Le nœud d'automatisation a été créé.",
  "The automation node has been removed": "Le nœud d'automatisation a été supprimé.",
  "The automation node has been updated": "Le nœud d'automatisation a été mis à jour.",
  "The automation point has been created": "Le point d'automatisation a été créé.",
  "The automation point has been removed": "Le point d'automatisation a été supprimé.",
  "The automation point has been updated": "Le point d'automatisation a été mis à jour.",
  "The circuit breaker has been created": "Le disjoncteur a été créé.",
  "The circuit breaker has been removed": "Le disjoncteur a été supprimé.",
  "The circuit breaker has been updated": "Le disjoncteur a été mis à jour.",
  "The circuit breaker relation has been created": "La relation de disjoncteur a été créée.",
  "The circuit breaker relation has been removed": "La relation de disjoncteur a été supprimée.",
  "The circuit breaker relation has been updated": "La relation de disjoncteur a été mise à jour.",
  "The connector instance has been created": "L'instance de connecteur a été créée.",
  "The connector instance has been removed": "L'instance de connecteur a été supprimée.",
  "The connector instance has been updated": "L'instance de connecteur a été mise à jour.",
  "The contract has been created": "Le contrat a été créé.",
  "The contract has been removed": "Le contrat a été supprimé.",
  "The contract has been updated": "Le contrat a été mis à jour.",
  "The curtail schedule has been removed": "Le calendrier de réduction a été supprimé.",
  "The curtail schedule has been updated": "Le calendrier de réduction a été mis à jour.",
  "The energy delivery point has been created": "Le point de livraison d'énergie a été créé.",
  "The energy delivery point has been removed": "Le point de livraison d'énergie a été supprimé.",
  "The energy delivery point has been updated": "Le point de livraison d'énergie a été mis à jour.",
  "The equipment has been created": "L'équipement a été créé.",
  "The equipment has been removed": "L'équipement a été supprimé.",
  "The equipment has been updated": "L'équipement a été mis à jour.",
  "The flex contract has been created": "Le contrat flexible a été créé.",
  "The flex opti has been created": "L'optimisation flexible a été créée.",
  "The flex opti has been removed": "L'optimisation flexible a été supprimée.",
  "The flex opti has been updated": "L'optimisation flexible a été mise à jour.",
  "The gateway has been created": "La passerelle a été créée.",
  "The gateway has been removed": "La passerelle a été supprimée.",
  "The gateway has been updated": "La passerelle a été mise à jour.",
  "The gateway interface has been created": "L'interface de passerelle a été créée.",
  "The gateway interface has been removed": "L'interface de passerelle a été supprimée.",
  "The interface has been created": "L'interface a été créée.",
  "The interface has been removed": "L'interface a été supprimée.",
  "The interface has been updated": "L'interface a été mise à jour.",
  "The network has been created": "Le réseau a été créé.",
  "The network has been removed": "Le réseau a été supprimé.",
  "The network has been updated": "Le réseau a été mis à jour.",
  "The network interface has been updated": "L'interface réseau a été mise à jour.",
  "The point has been created": "Le point a été créé.",
  "The point has been removed": "Le point a été supprimé.",
  "The point has been updated": "Le point a été mis à jour.",
  "The proposal for aggregator has been created": "La proposition pour l'agrégateur a été créée.",
  "The proposal for aggregator has been removed": "La proposition pour l'agrégateur a été supprimée.",
  "The proposal has been sent to the aggregator": "La proposition a été envoyée à l'agrégateur.",
  "The proposed curtail schedule has been removed": "Le calendrier de réduction proposé a été supprimé.",
  "The proposed curtail schedule has been updated": "Le calendrier de réduction proposé a été mis à jour.",
  "The proposed curtail schedules has been created": "Les calendriers de réduction proposés ont été créés.",
  "The protocol instance has been created": "L'instance de protocole a été créée.",
  "The protocol instance has been removed": "L'instance de protocole a été supprimée.",
  "The protocol instance has been updated": "L'instance de protocole a été mise à jour.",
  "The site has been created": "Le site a été créé.",
  "The site has been removed": "Le site a été supprimé.",
  "The site has been updated": "Le site a été mis à jour.",
  "The space has been created": "L'espace a été créé.",
  "The space has been removed": "L'espace a été supprimé.",
  "The space has been updated": "L'espace a été mis à jour.",
  "The state has been created": "L'état a été créé.",
  "The state has been removed": "L'état a été supprimé.",
  "The state has been updated": "L'état a été mis à jour.",
  "The store has been created": "Le magasin a été créé.",
  "The store has been removed": "Le magasin a été supprimé.",
  "The store has been updated": "Le magasin a été mis à jour.",
  "Timeouts": "Délais d'attente",
  "Timezone": "Timezone",
  "TLS version": "Version TLS",
  "Transport type": "Type de transport",
  "Type": "Type",
  "Type of bit": "Type of bit",
  "Type of register": "Type of register",
  "UInt": "UInt",
  "UInt{{value}}": "UInt{{value}}",
  "Uninhibit priority": "Priorité désinhibition",
  "Unit": "Unité",
  "Unspecified loads": "Charges non spécifiées",
  "Upcoming load curtailment for": "Réduction de charge à venir pour",
  "Update response timeouts": "Mettre à jour les délais d'attente de réponse",
  "Updated at": "Mis à jour à",
  "Updated by": "Mis à jour par",
  "Updated time": "Heure de mise à jour",
  "User": "Utilisateur",
  "Value": "Valeur",
  "Vid": "Vid",
  "Weather zone": "Zone météorologique",
  "Writable": "Modifiable",
  "Write": "Écrire",
  "Yes": "Oui",
  "Zip code": "Code postal",
  "Zone name": "Nom de la zone"
}
