import { Dropdown, SelectArray } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const Equipment = (
  retrieveNodes,
  getNodeValues,
  node_label,
  gateways,
  spaces,
  equipmentTypes,
  equipmentSubTypes,
  loadingGateways,
  loadingSpaces,
  loadingEquipments,
  loadingEquipmentSubTypes
) => {
  return {
    /*
     * List of fields for the model, like this
     */
    ...{
      name: {
        label: i18n.t("Name", { ns: "assets" }),
        field: InputText,
        options: {
          required: true,
          keyfilter: /[\w\d\-_]/,
          maxLength: 255
        }
      },
      description: {
        label: i18n.t("Description", { ns: "assets" }),
        field: InputTextarea,
        options: {}
      },
      equip_comm_info: {
        node: {
          label: i18n.t("Node", { ns: "assets" }),
          field: SelectArray,
          options: {
            getItems: retrieveNodes,
            getAllValues: getNodeValues,
            columns: [
              { field: "name", header: i18n.t("Name", { ns: "assets" }), sortable: true },
              { field: "model", header: i18n.t("Model", { ns: "assets" }), sortable: true },
              {
                field: "manufacturer",
                header: i18n.t("Manufacturer", { ns: "assets" }),
                sortable: true
              },
              { field: "type", header: i18n.t("Protocol", { ns: "assets" }), sortable: true },
              {
                field: "ip_addresses",
                header: i18n.t("IP addresses", { ns: "assets" }),
                sortable: true
              }
            ],
            columnsFilter: [
              { field: "name", header: i18n.t("Name", { ns: "assets" }), sortable: true },
              { field: "model", header: i18n.t("Model", { ns: "assets" }), sortable: true },
              {
                field: "manufacturer",
                header: i18n.t("Manufacturer", { ns: "assets" }),
                sortable: true
              },
              { field: "protocol", header: i18n.t("Protocol", { ns: "assets" }), sortable: true },
              {
                field: "ip_addresses",
                header: i18n.t("IP addresses", { ns: "assets" }),
                sortable: true
              }
            ],
            searchLabel: (id, items) => {
              let find = items.find(node => node.id === id)
              return find ? find.name : ""
            },
            LABEL_VALUE: node_label
          }
        },
        gateway: {
          label: i18n.t("Gateway", { ns: "assets" }),
          field: Dropdown,
          options: {
            required: true,
            options: gateways,
            optionLabel: "name",
            optionValue: "id",
            filter: true,
            loading: loadingGateways,
            placeholder: loadingGateways ? "Loading..." : "Select a gateway"
          }
        }
      },
      space: {
        label: i18n.t("Space", { ns: "assets" }),
        field: Dropdown,
        options: {
          options: spaces,
          optionLabel: "name",
          optionValue: "id",
          filter: true,
          loading: loadingSpaces,
          placeholder: loadingSpaces ? "Loading..." : "Select a space"
        }
      },
      equipment_type: {
        label: i18n.t("Equipment type", { ns: "assets" }),
        field: Dropdown,
        options: {
          options: equipmentTypes,
          optionLabel: "name",
          optionValue: "id",
          loading: loadingEquipments,
          placeholder: loadingEquipments ? "Loading..." : "Select an equipment",
          filter: true
        }
      }
    },
    ...(equipmentSubTypes.length > 0
      ? {
          equipment_subtype: {
            label: i18n.t("Equipment sub type", { ns: "assets" }),
            field: Dropdown,
            options: {
              options: equipmentSubTypes,
              optionLabel: "name",
              optionValue: "id",
              loading: loadingEquipmentSubTypes,
              placeholder: loadingEquipmentSubTypes ? "Loading..." : "Select an equipment subtype",
              filter: true
            }
          }
        }
      : {})
  }
}

export const EquipmentOverview = () => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: { label: i18n.t("Name", { ns: "assets" }) },
    description: { label: i18n.t("Description", { ns: "assets" }) },
    node_name: { label: i18n.t("Node", { ns: "assets" }) },
    gateway_name: { label: i18n.t("Gateway", { ns: "assets" }) },
    equipment_type_name: { label: i18n.t("Type", { ns: "assets" }) },
    equipment_subtype_name: { label: i18n.t("Subtype", { ns: "assets" }) }
  }
}
