import axios from "axios"
import { config } from "/utils/api"

export async function retrieveList(siteId, token, queryParams) {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/networks/`,
    config({ token, queryParams })
  )
}

export async function retrieve(siteId, networkId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/networks/${networkId}/`,
    config({ token })
  )
}

export async function add(siteId, data, token) {
  return await axios.post(
    `${window.BACKEND_URL}/asset/sites/${siteId}/networks/`,
    data,
    config({ token })
  )
}

export async function update(siteId, networkId, data, token) {
  return await axios.put(
    `${window.BACKEND_URL}/asset/sites/${siteId}/networks/${networkId}/`,
    data,
    config({ token })
  )
}

export async function remove(siteId, networkId, token) {
  return await axios.delete(
    `${window.BACKEND_URL}/asset/sites/${siteId}/networks/${networkId}/`,
    config({ token })
  )
}

export async function getCount(networkId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/asset/networks/${networkId}/count`,
    config({ token })
  )
}

export async function getNetworksCount(ids, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/networks/count/`, ids, config({ token }))
}

export async function removeNetworks(siteId, ids, token) {
  return await axios.post(
    `${window.BACKEND_URL}/asset/sites/${siteId}/networks/deletion/`,
    ids,
    config({ token })
  )
}

export async function getSpaces(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/spaces/`, config({ token }))
}

export async function getGatewayInterfaces(siteId, networkId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/connectivity/sites/${siteId}/gateway_ip_interfaces/`,
    config({ token, queryParams: { network: networkId } })
  )
}

export async function getNodeInterfaces(siteId, networkId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/node_interfaces/`,
    config({ token, queryParams: { network: networkId } })
  )
}

export async function getAllValues(siteId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/networks/allvalues`,
    config({ token })
  )
}

export async function exportNetworks(siteId, token) {
  return await axios({
    method: "get",
    url: `${window.BACKEND_URL}/asset/sites/${siteId}/networks/bulk/export`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export async function exportTemplate(siteId, token) {
  return await axios({
    method: "get",
    url: `${window.BACKEND_URL}/asset/sites/${siteId}/networks/bulk/export`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export async function importNetwork(siteId, file, token) {
  let formData = new FormData()
  formData.append("file", file)
  return await axios.post(
    `${window.BACKEND_URL}/asset/sites/${siteId}/networks/bulk/import`,
    formData,
    config({ token, file: true }),
    { signal: new AbortController() }
  )
}
