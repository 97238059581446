import React from "react"
import { useTranslation } from "react-i18next"
import { DatetimeInput } from "@software-engineering/hivolution-frontend-utils"
import BillPriceField from "./BillPriceField"
import { Button } from "primereact/button"

export default function BillForm({ data, setData, submit, cancelForm }) {
  const { t } = useTranslation(["energy"])

  const updateData = (key, value) => {
    setData({
      ...data,
      [key]: value
    })
  }

  const addPrice = () => {
    setData(oldData => ({
      ...oldData,
      prices: [...oldData.prices, { name: "", price_type: "", value: null }]
    }))
  }

  return (
    <form
      className="form-view my-5"
      onSubmit={submit}
    >
      <div className="field-container">
        <div className="field datetimeinput">
          <label className="label label-required">{t("Start validity")}</label>
          <div className="field-input">
            <DatetimeInput
              required={true}
              value={data.start_validity}
              onChange={e => updateData("start_validity", e.value)}
            />
          </div>
        </div>
      </div>

      <div className="field-container">
        <div className="field datetimeinput">
          <label className="label label-required">{t("End validity")}</label>
          <div className="field-input">
            <DatetimeInput
              required={true}
              value={data.end_validity}
              min={data.start_validity}
              onChange={e => updateData("end_validity", e.value)}
            />
          </div>
        </div>
      </div>
      <div className="prices-fields">
        <Button
          rounded
          className=""
          type="button"
          onClick={addPrice}
        >
          Add price
        </Button>
        {data.prices.map((price, index) => (
          <BillPriceField
            key={index}
            index={index}
            price={price}
            prices={data.prices}
            setData={setData}
            /* removePrice={(index) => removePrice(index)} */
          />
        ))}
      </div>
      <div className="flex w-full">
        <div className="ml-auto">
          <Button
            rounded
            type="submit"
          >
            {t("Save")}
          </Button>
          <Button
            rounded
            severity="danger"
            onClick={cancelForm}
            className="ml-3"
            type="button"
          >
            {t("Cancel")}
          </Button>
        </div>
      </div>
    </form>
  )
}
