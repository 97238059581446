import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const commonsFields = (nodes, loadingNodes) => {
  return {
    name: {
      label: i18n.t("Name", { ns: "assets" }),
      field: InputText,
      options: {
        required: true,
        maxLength: 255
      }
    },
    node: {
      label: i18n.t("Node", { ns: "assets" }),
      field: Dropdown,
      options: {
        required: true,
        options: nodes,
        optionLabel: "name",
        optionValue: "id",
        loading: loadingNodes,
        placeholder: loadingNodes ? "Loading..." : "Select node",
        filter: true
      }
    },
    description: {
      label: i18n.t("Description", { ns: "assets" }),
      field: InputTextarea,
      options: {}
    }
  }
}

export const commonsOverview = () => {
  return {
    name: { label: i18n.t("Name", { ns: "assets" }) },
    node_name: { label: i18n.t("Node", { ns: "assets" }) },
    description: { label: i18n.t("Description", { ns: "assets" }) }
  }
}
