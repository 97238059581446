import { EdgeControllerRoutesContext } from "/apps/optimization/configuration/edge-controller/routes"
import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { Loader, OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getEdgeControllerTypes, update } from "../api"
import { EdgeController, EdgeControllerOverview } from "../models"
import { organization } from "/utils/store/organization"
import ConfigErrors from "./ConfigError"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["optimization"])
  const [object, setObject] = useState({})
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [gateways, setGateways] = useState([])
  const [edgeControllerTypes, setEdgeControllerTypes] = useState(["Optimization"])
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const { edgeController } = useContext(EdgeControllerRoutesContext)

  const [configErrors, setConfigErrors] = useState(null)

  const updateObject = async function (e) {
    e.preventDefault()
    setConfigErrors(null)
    await update(siteStore.id, object.id, data, auth.userData.access_token)
      .then(response => {
        dispatch(
          addNotifications([
            successNotification("Update", t("The edge controller has been updated."))
          ])
        )
        setLoading(true)
        setEdit(false)

        navigate(
          `/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/configuration/edge-controllers/${response.data.id}/`
        )
      })
      .catch(error => {
        if (error.response.data) {
          try {
            const errorConfig = JSON.parse(error.response.data.message)
            setConfigErrors(errorConfig)
          } catch (e) {
            console.log(e)
            setErrors(error.response.data)
          }
        }
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (edgeController) {
      setObject(edgeController)
      setData(edgeController)
      setLoading(false)
    }
  }, [edgeController])

  useEffect(() => {
    // Get gateways
    const getGW = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequest(
        `connectivity/sites/${siteStore.id}/gateways/`,
        auth.userData.access_token
      )()
        .then(results => {
          setGateways(results)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })
    }

    const getECT = async () => {
      await getEdgeControllerTypes(auth.userData.access_token)
        .then(({ data }) => {
          setEdgeControllerTypes(data)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })
    }

    // Only on edit
    if (siteStore.id && edit) {
      getGW()
      getECT()
    }
  }, [siteStore.id, edit])

  const customError = () => {
    return configErrors && <ConfigErrors configErrors={configErrors} />
  }

  return object && !loading ? (
    <>
      <div className="base-content-title">
        <h2>
          {t("Edge controller")} #{object.id}
        </h2>
      </div>

      <OverView
        object={object}
        model={EdgeController(gateways, edgeControllerTypes)}
        overviewModel={EdgeControllerOverview()}
        updateObject={updateObject}
        data={data}
        setData={setData}
        errors={errors}
        edit={edit}
        setEdit={setEdit}
        customErrorComponent={customError}
        loading={loading}
        deleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/configuration/edge-controllers/${object.id}/delete/`}
      />
    </>
  ) : (
    <Loader />
  )
}
