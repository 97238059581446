import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

export default function BillResume({ bill }) {
  const { t } = useTranslation(["energy"])

  const [fixPrices, setFixPrices] = useState([])
  const [schPrices, setSchPrices] = useState([])

  useEffect(() => {
    if (bill) {
      const fix = bill.prices.filter(price => price.price_type === "FIX")
      const sch = bill.prices.filter(price => price.price_type === "SCH")
      setFixPrices(fix)
      setSchPrices(sch)
    }
  }, [bill])

  return (
    bill && (
      <div className="bill-resume">
        <div className="bill-resume-dates">
          <h3>{t("Dates")}</h3>
          <dl>
            <div className="bill-resume-item">
              <dt>{t("Start validity")}</dt>
              <dd>{bill.start_validity}</dd>
            </div>
            <div className="bill-resume-item">
              <dt>{t("End validity")}</dt>
              <dd>{bill.end_validity}</dd>
            </div>
          </dl>
        </div>

        <div className="bill-resume-fix-prices">
          <h3>{t("Fix prices")}</h3>

          {fixPrices.length > 0 ? (
            <dl>
              {fixPrices.map((fixPrice, index) => {
                return (
                  <div
                    key={index}
                    className="bill-resume-item"
                  >
                    <dt>{fixPrice.name}</dt>
                    <dd>{fixPrice.value}</dd>
                  </div>
                )
              })}
            </dl>
          ) : (
            <span>{t("There is no fix prices for this bill configuration")}</span>
          )}
        </div>
        <div className="bill-resume-schedule-prices">
          <h3>{t("Schedule prices")}</h3>

          {schPrices.length > 0 ? (
            <div className="schedule-prices">
              {schPrices.map((schPrice, index) => {
                return (
                  <div
                    key={index}
                    className="schedule-price"
                  >
                    <span>{schPrice.name}</span>
                    <dl>
                      {Object.entries(schPrice.value).map(([key, value], index) => {
                        return (
                          <div
                            className="bill-resume-item"
                            key={index}
                          >
                            <dt>{key}</dt>
                            <dd>{value}</dd>
                          </div>
                        )
                      })}
                    </dl>
                  </div>
                )
              })}
            </div>
          ) : (
            <span>{t("There is no schedule prices for this bill configuration")}</span>
          )}
        </div>
      </div>
    )
  )
}
