{
  "Alarm (4)": "Alarme (4)",
  "Analogue (2)": "Analogique (2)",
  "Data type": "Type de données",
  "Digital (1)": "Numérique (1)",
  "Distributor": "Distributeur",
  "Gateway": "Passerelle",
  "Height": "Hauteur",
  "Integer (3)": "Entier (3)",
  "No weather zone found": "Aucune zone météorologique trouvée.",
  "Sign out": "Se déconnecter",
  "Surface area (m²)": "Surface (m²)"
}
