import axios from "axios"
import { config } from "/utils/api"

const BASE_URL = siteId => `${window.BACKEND_URL}/operation/sites/${siteId}/constraints/`

export async function retrieveList(siteId, token, queryParams) {
  return await axios.get(BASE_URL(siteId), config({ token, queryParams }))
}

export async function retrieve(siteId, id, token) {
  return await axios.get(`${BASE_URL(siteId)}${id}/`, config({ token }))
}

export async function add(siteId, data, token) {
  return await axios.post(BASE_URL(siteId), data, config({ token }))
}

export async function update(siteId, id, data, token) {
  return await axios.put(`${BASE_URL(siteId)}${id}/`, data, config({ token }))
}

export async function remove(siteId, id, token) {
  return await axios.delete(`${BASE_URL(siteId)}${id}/`, config({ token }))
}

export async function removeSpaceConstraints(siteId, ids, token) {
  return await axios.post(`${BASE_URL(siteId)}bulk-delete/`, ids, config({ token }))
}

export async function retrieveSpaces(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/spaces/`, config({ token }))
}

export async function getAllValues(siteId, token) {
  return await axios.get(`${BASE_URL(siteId)}allvalues`, config({ token }))
}
