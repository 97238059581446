import { retrieve } from "/apps/connectivity/connector-instance/api"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { site } from "/utils/store/site"
import { InnerTabsLayout, Loader } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import * as React from "react"
import { createContext, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Route, Routes, useParams } from "react-router-dom"
import { GatewayRoutesContext } from "../gateway/routes"
import Create from "./components/Create"
import Delete from "./components/Delete"
import DeleteBulk from "./components/DeleteBulk"
import Detail from "./components/Detail"
import List from "./components/List"
import ProtocolInstanceRoutes from "./protocol-instance/routes"

const ConnectorInstanceRoutesContext = createContext(null)

const ConnectorInstanceContent = () => {
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const { t } = useTranslation(["assets"])
  const auth = useAuth()
  const { connectorInstanceId } = useParams()
  const [connector, setConnector] = useState()
  const [tabs, setTabs] = useState()
  const { gateway } = useContext(GatewayRoutesContext)

  const retrieveDetail = async () => {
    await retrieve(siteStore.id, connectorInstanceId, auth.userData.access_token)
      .then(response => {
        setConnector(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    siteStore.id && retrieveDetail()
  }, [siteStore.id])

  useEffect(() => {
    if (siteStore.id && gateway && gateway.id && connectorInstanceId) {
      setTabs([
        {
          name: t("Base informations"),
          active:
            location.pathname.endsWith(`/connector-instances/${connectorInstanceId}/`) ||
            location.pathname.endsWith(`/connector-instances/${connectorInstanceId}/delete/`),
          url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connectorInstanceId}/`
        },
        {
          name: t("Protocol instances"),
          active: location.pathname.includes("/protocol-instances/"),
          url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connectorInstanceId}/protocol-instances/`
        }
      ])

      retrieveDetail()
    }
  }, [siteStore.id, gateway, connectorInstanceId])

  return (
    <ConnectorInstanceRoutesContext.Provider value={{ connector, retrieveDetail }}>
      {connector ? (
        <InnerTabsLayout
          title={`${t("Connector instance")} ${connector.name}`}
          tabs={tabs}
          setTabs={setTabs}
        />
      ) : (
        <Loader />
      )}
    </ConnectorInstanceRoutesContext.Provider>
  )
}

export { ConnectorInstanceRoutesContext }
export default function ConnectorInstanceRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="create"
        element={<Create />}
      />
      <Route
        path="multi-delete"
        element={<DeleteBulk />}
      />
      <Route
        path=":connectorInstanceId"
        element={<ConnectorInstanceContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
        <Route
          path="protocol-instances/*"
          element={<ProtocolInstanceRoutes />}
        />
      </Route>
    </Routes>
  )
}
