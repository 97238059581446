import { paginatedRequestFlex } from "/utils/api"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { ListActions, ListLayout } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link, useNavigate, useParams } from "react-router-dom"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const { siteId, flexId } = useParams()

  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "site", siteId: siteId }))

    const retrieve = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequestFlex(
        `flex/${flexId}/market_schedule_group/`,
        auth.userData.access_token
      )()
        .then(results => {
          setItems(results)
          setLoading(false)
        })
        .catch(error => {
          setLoading(false)
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* await retrieveList(flexId, auth.userData.access_token).then(response => {
        if (response.data) {
          setItems(response.data.results)
        }
        setLoading(false)
      }).catch(error => {
        setLoading(false)
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      }) */
    }
    retrieve()
  }, [])

  const columns = [
    {
      field: "schedule_date",
      header: t("Schedule date"),
      processValue: value => {
        return new Date(value).toLocaleString()
      }
    },
    { field: "aggregator_response", header: t("Aggregator Response") },
    { field: "proposal_sent", header: t("Proposal sent") },
    { field: "sent_by", header: t("Sent by") }
  ]

  const getDetailUrl = marketId => {
    return `/sites/${siteId}/optimization/flexibility/${flexId}/market-player-interaction/${marketId}/`
  }

  const getDeleteUrl = marketId => {
    return `/sites/${siteId}/optimization/flexibility/${flexId}/market-player-interaction/${marketId}/delete/`
  }

  const onRowClick = marketId => {
    navigate(getDetailUrl(marketId))
  }

  const menuFilters = { items, columns, filters, setFilters }

  return (
    <section className="list-section">
      <div className="list-section-head">
        <h3>{t("Market schedule group proposal history")}</h3>
      </div>

      <ListActions filters={menuFilters}>
        <Link
          to={`/sites/${siteId}/optimization/flexibility/${flexId}/market-player-interaction/create/`}
        >
          <Button
            rounded
            severity="info"
            icon="fa-regular fa-plus"
            label={t("Create")}
          />
        </Link>
      </ListActions>

      <ListLayout
        columns={columns}
        value={items}
        loading={loading}
        filters={filters}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
      />
    </section>
  )
}
