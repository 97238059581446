import { StateRoutesContext } from "/apps/optimization/configuration/state/routes"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { DeleteConfirmation } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { remove } from "../api"

export default function Delete() {
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["assets"])
  const { state } = useContext(StateRoutesContext)

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  const removeObject = async () => {
    await remove(siteStore.id, state.id, auth.userData.access_token)
      .then(() => {
        displayNotification([successNotification("Delete", t("The state has been removed"))])
        navigate(-2)
      })
      .catch(error => {
        displayNotification([errorNotification("Error", error.message)])
      })
  }

  return (
    state && (
      <DeleteConfirmation
        objectType={t("State")}
        objectNames={[state.name]}
        remove={removeObject}
        goBack={() => navigate(-1)}
      />
    )
  )
}
