import axios from "axios"

function header(token) {
  return {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

export async function retrieveList(siteId, connectorInstanceId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/connectivity/sites/${siteId}/protocol_instances?connector_instance=${connectorInstanceId}`,
    header(token)
  )
}

export async function retrieve(siteId, id, token) {
  return await axios.get(
    `${window.BACKEND_URL}/connectivity/sites/${siteId}/protocol_instances/${id}`,
    header(token)
  )
}

export async function add(siteId, data, token) {
  return await axios.post(
    `${window.BACKEND_URL}/connectivity/sites/${siteId}/protocol_instances/`,
    data,
    header(token)
  )
}

export async function update(siteId, id, data, token) {
  return await axios.put(
    `${window.BACKEND_URL}/connectivity/sites/${siteId}/protocol_instances/${id}/`,
    data,
    header(token)
  )
}

export async function remove(siteId, id, token) {
  return await axios.delete(
    `${window.BACKEND_URL}/connectivity/sites/${siteId}/protocol_instances/${id}/`,
    header(token)
  )
}

export async function getCount(id, token) {
  return await axios.get(`${window.BACKEND_URL}/DETAIL_URL/${id}/count`, header(token))
}

export async function retrieveResponseTimeouts(siteId, protocolInstanceId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/connectivity/sites/${siteId}/protocol_instances/${protocolInstanceId}/response_timeouts/`,
    header(token)
  )
}

export async function updateResponseTimeouts(siteId, protocolInstanceId, data, token) {
  return await axios.post(
    `${window.BACKEND_URL}/connectivity/sites/${siteId}/protocol_instances/${protocolInstanceId}/response_timeouts/`,
    data,
    header(token)
  )
}

export async function removeProtocolInstances(siteId, ids, token) {
  return await axios.post(
    `${window.BACKEND_URL}/connectivity/sites/${siteId}/protocol_instances/deletion/`,
    ids,
    header(token)
  )
}
