import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"

export const ProtocolInstanceOverview = () => ({
  label: { label: i18n.t("Type", { ns: "connectivity" }) },
  gateway_ip_interface_label: { label: i18n.t("Gateway interface", { ns: "connectivity" }) }
})

export const ProtocolInstance = (gatewayInterfaces, loadingGateway, editMode = false) => {
  return {
    type: {
      label: i18n.t("Type", { ns: "connectivity" }),
      field: Dropdown,
      options: {
        required: true,
        options: [
          { value: "bacnetip", label: i18n.t("Bacnet IP", { ns: "connectivity" }) },
          { value: "modbusip", label: i18n.t("Modbus IP", { ns: "connectivity" }) },
          { value: "smxmlapi", label: i18n.t("SM XML API", { ns: "connectivity" }) },
          { value: "opcua", label: i18n.t("OPSCUA", { ns: "connectivity" }) },
          { value: "carelxmlapi", label: i18n.t("Carel", { ns: "connectivity" }) }
        ],
        placeholder: "Select type",
        disabled: editMode
      }
    },
    gateway_ip_interface: {
      label: i18n.t("Gateway interface", { ns: "connectivity" }),
      field: Dropdown,
      dependsOn: "type",
      options: {
        required: true,
        optionLabel: "label",
        optionValue: "id",
        options: gatewayInterfaces,
        loading: loadingGateway,
        placeholder: loadingGateway ? "Loading..." : "Select gateway interface"
      }
    },
    gateway_udp_port: {
      label: i18n.t("Gateway UDP Port", { ns: "connectivity" }),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "bacnetip",
      options: {
        required: true
      }
    },
    batch_size: {
      label: i18n.t("Batch size", { ns: "connectivity" }),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "smxmlapi",
      options: {
        required: true,
        showButtons: true
      }
    },
    batch_size_write: {
      label: i18n.t("Batch size writing", { ns: "connectivity" }),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "smxmlapi",
      options: {
        required: true,
        showButtons: true
      }
    },
    sleep_time: {
      label: i18n.t("Sleep time", { ns: "connectivity" }),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "smxmlapi",
      options: {
        required: true,
        showButtons: true
      }
    },
    keepalive_timer: {
      label: i18n.t("Keep alive timer", { ns: "connectivity" }),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "opcua",
      options: {
        required: true,
        showButtons: true
      }
    },
    connect_timeout: {
      label: i18n.t("Connect timeout", { ns: "connectivity" }),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "opcua",
      options: {
        required: true,
        showButtons: true
      }
    },
    read_timeout: {
      label: i18n.t("Read timeout", { ns: "connectivity" }),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "opcua",
      options: {
        required: true,
        showButtons: true
      }
    },
    write_timeout: {
      label: i18n.t("Write timeout", { ns: "connectivity" }),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "opcua",
      options: {
        required: true,
        showButtons: true
      }
    },
    backoff_max_wait: {
      label: i18n.t("Backoff max wait", { ns: "connectivity" }),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "opcua",
      options: {
        required: true,
        showButtons: true
      }
    },
    set_wait_time: {
      label: i18n.t("Set wait time", { ns: "connectivity" }),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "carelxmlapi",
      options: {
        required: true,
        showButtons: true
      }
    }
  }
}
