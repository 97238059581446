import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "/utils/constants/api"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import {
  BaseLayout,
  ListActions,
  ListLayout
} from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { getAllValues, retrieveList } from "../api"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const [parameters, setParameters] = useState([])
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["configuration"])
  const [filters, setFilters] = useState({})
  const [filterValues, setFilterValues] = useState(null)
  const [loadingFilters, setLoadingFilters] = useState(false)
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset, setOffset] = useState(REQUEST_DEFAULT_OFFSET)
  const [count, setCount] = useState(0)
  const [sortOrder, setSortOrder] = useState(null)
  const [sortField, setSortField] = useState(null)

  const retrieve = async (queryParams = { limit, offset }) => {
    setLoading(true)
    await retrieveList(auth.userData.access_token, queryParams)
      .then(({ data }) => {
        data.results &&
          setParameters(
            data.results.map(item => {
              return {
                ...item,
                unit_name: item.unit,
                quantity_name: item.quantity
              }
            })
          )
        setCount(data.count)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getFilterValues = async () => {
    setLoadingFilters(true)
    await getAllValues(auth.userData.access_token)
      .then(response => {
        setFilterValues(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setLoadingFilters(false))
  }

  const onSort = sortEvent => {
    setSortOrder(sortEvent.sortOrder)
    setSortField(sortEvent.sortField)
  }

  useEffect(() => {
    getFilterValues()
  }, [])

  const columns = [
    { field: "name", header: t("Name"), sortable: true },
    { field: "unit_name", header: t("Unit"), sortable: true },
    { field: "quantity_name", header: t("Quantity"), sortable: true }
  ]

  const columnsFilter = [
    { field: "name", header: t("Name") },
    { field: "unit_name", header: t("Unit") },
    { field: "quantity_name", header: t("Quantity") }
  ]

  const menuFilters = { columnsFilter, filters, setFilters, filterValues }

  const queryParams = () => {
    return {
      ...filters,
      limit,
      offset,
      ordering: sortField && `${sortOrder > 0 ? "" : "-"}${sortField}`
    }
  }

  useEffect(() => {
    offset ? setOffset(REQUEST_DEFAULT_OFFSET) : retrieve(queryParams())
  }, [filters, sortField, sortOrder])

  useEffect(() => {
    retrieve(queryParams())
  }, [limit, offset])

  return (
    <BaseLayout title={t("Units & quantities")}>
      <ListActions
        filterBackend={true}
        filters={menuFilters}
        loading={loadingFilters}
      />
      <ListLayout
        objectName={t("Units & quantities")}
        columns={columns}
        value={parameters}
        loading={loading}
        filters={filters}
        limit={limit}
        offset={offset}
        setLimit={setLimit}
        setOffset={setOffset}
        count={count}
        onSort={onSort}
        sortOrder={sortOrder}
        sortField={sortField}
      />
    </BaseLayout>
  )
}
