import axios from "axios"
import { config } from "/utils/api"

export async function retrieve(siteId, interfaceId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/connectivity/sites/${siteId}/gateway_ip_interfaces/${interfaceId}/`,
    config({ token })
  )
}

export async function retrieveList(siteId, token, queryParams) {
  return await axios.get(
    `${window.BACKEND_URL}/connectivity/sites/${siteId}/gateway_ip_interfaces/`,
    config({ token, queryParams })
  )
}

export async function addInterface(siteId, data, token) {
  return await axios.post(
    `${window.BACKEND_URL}/connectivity/sites/${siteId}/gateway_ip_interfaces/`,
    data,
    config({ token })
  )
}

export async function updateInterface(siteId, interfaceId, data, token) {
  return await axios.put(
    `${window.BACKEND_URL}/connectivity/sites/${siteId}/gateway_ip_interfaces/${interfaceId}/`,
    data,
    config({ token })
  )
}

export async function removeInterface(siteId, interfaceId, token) {
  return await axios.delete(
    `${window.BACKEND_URL}/connectivity/sites/${siteId}/gateway_ip_interfaces/${interfaceId}/`,
    config({ token })
  )
}

export async function getAllValues(gatewayId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/connectivity/gateways/${gatewayId}/gateway_ip_interfaces/allvalues`,
    config({ token })
  )
}

export async function removeInterfaces(gatewayId, ids, token) {
  return await axios.post(
    `${window.BACKEND_URL}/connectivity/gateways/${gatewayId}/gateway_ip_interfaces/bulk-delete/`,
    ids,
    config({ token })
  )
}
