import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { commonsFields, commonsOverview } from "./commons"

export const ModbusNode = spaces => {
  return {
    ...commonsFields(spaces),
    ...{
      slave_id: {
        label: i18n.t("Slave ID", { ns: "assets" }),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          max: 247,
          showButtons: true
        }
      },
      response_timeout: {
        label: i18n.t("Response timeout (seconds)", { ns: "assets" }),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true,
          min: 0
        }
      }
    }
  }
}

export const ModbusOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      slave_id: { label: i18n.t("Slave ID", { ns: "assets" }) },
      response_timeout: { label: i18n.t("Response timeout (seconds)", { ns: "assets" }) }
    }
  }
}
