import axios from "axios"
import { config } from "/utils/api"

export async function retrieveList(siteId, token, queryParams) {
  return await axios.get(
    `${window.BACKEND_URL}/events/sites/${siteId}/events/`,
    config({ token, queryParams })
  )
}

export async function getAllValues(siteId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/events/sites/${siteId}/events/allvalues`,
    config({ token })
  )
}
