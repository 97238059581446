import axios from "axios"
import { config } from "/utils/api"

export async function retrieveList(siteId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/operation/apiarist_bill_list/${siteId}`,
    config({ token })
  )
}

export async function create(siteId, data, token) {
  return axios.post(
    `${window.BACKEND_URL}/operation/apiarist_bill_list/${siteId}`,
    data,
    config({ token })
  )
}

export async function update(siteId, data, token) {
  return axios.put(
    `${window.BACKEND_URL}/operation/apiarist_bill_detail/${siteId}`,
    data,
    config({ token })
  )
}
