import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "/utils/constants/api"
import { datetimeStr } from "/utils/datetime"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ListActions, ListLayout } from "@software-engineering/hivolution-frontend-utils"
import fileDownload from "js-file-download"
import { useAuth } from "oidc-react"
import { SortOrder } from "primereact/api"
import { Button } from "primereact/button"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { exportNodes, exportTemplate, getAllValues, importNodes, retrieveList } from "../api"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingFilters, setLoadingFilters] = useState(true)
  const [filterValues, setFilterValues] = useState(null)
  const [filters, setFilters] = useState({})
  const [selected, setSelected] = useState([])
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset, setOffset] = useState(REQUEST_DEFAULT_OFFSET)
  const [count, setCount] = useState(0)
  const [processingImport, setProcessingImport] = useState(false)
  const [processingExport, setProcessingExport] = useState(false)
  const [processingTemplate, setProcessingTemplate] = useState(false)
  const hiddenFileInputModbus = useRef(null)
  const hiddenFileInputBacnet = useRef(null)
  const hiddenFileInputSM = useRef(null)
  const hiddenFileInputCarel = useRef(null)
  const hiddenFileInputExternal = useRef(null)
  const [sortOrder, setSortOrder] = useState(SortOrder.ASC)
  const [sortField, setSortField] = useState("name")

  const retrieve = async (queryParams = { limit, offset }) => {
    setLoading(true)
    await retrieveList(siteStore.id, auth.userData.access_token, queryParams)
      .then(({ data }) => {
        setCount(data.count)
        data.results && setItems(data.results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setLoading(false))
  }

  const getFilterValues = async () => {
    setLoadingFilters(true)
    await getAllValues(siteStore.id, auth.userData.access_token)
      .then(response => {
        setFilterValues(response.data)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification("Automation nodes", message)]))
      })
      .then(() => setLoadingFilters(false))
  }

  const onSort = sortEvent => {
    setSortOrder(sortEvent.sortOrder)
    setSortField(sortEvent.sortField)
  }

  const columns = [
    { field: "name", header: t("Name"), sortable: true },
    { field: "model", header: t("Model"), sortable: true },
    { field: "manufacturer", header: t("Manufacturer"), sortable: true },
    // { field: "description", header: t("Description") },
    { field: "type", header: t("Protocol"), sortable: true },
    { field: "slave_id", header: t("Slave ID"), sortable: true },
    { field: "ip_addresses", header: t("IP addresses"), sortable: true }
  ]

  const columnsFilter = [
    { field: "name", header: t("Name") },
    { field: "model", header: t("Model") },
    { field: "manufacturer", header: t("Manufacturer") },
    { field: "type", header: t("Protocol") },
    { field: "slave_id", header: t("Slave ID") },
    { field: "space_name", header: t("Space") },
    { field: "ip_addresses", header: t("IP address") }
  ]

  const getDetailUrl = objectId =>
    `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/automation-nodes/${objectId}/`

  const getDeleteUrl = objectId =>
    `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/automation-nodes/${objectId}/delete/`

  const onRowClick = objectId => {
    navigate(getDetailUrl(objectId))
  }

  const getExport = async nodeType => {
    setProcessingExport(true)
    await exportNodes(siteStore.id, nodeType, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `${nodeType}_${datetimeStr()}.csv`)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification("Automation nodes", message)]))
      })
      .then(() => setProcessingExport(false))
  }

  const getTemplate = async nodeType => {
    setProcessingTemplate(true)
    await exportTemplate(nodeType, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `${nodeType}_${datetimeStr()}.csv`)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification("Automation nodes", message)]))
      })
      .finally(() => setProcessingTemplate(false))
  }

  const postImport = async function (target, nodeType) {
    const file = target.files[0]
    if (file) {
      setProcessingImport(true)
      await importNodes(siteStore.id, nodeType, file, auth.userData.access_token)
        .then(response => {
          dispatch(addNotifications([successNotification("Import", response.data)]))
          retrieve()
        })
        .catch(error => {
          if (
            error.response &&
            error.response.headers &&
            error.response.headers["content-type"] === "text/csv"
          ) {
            fileDownload(error.response.data, `ERROR_${nodeType}_${datetimeStr()}.csv`)
            dispatch(
              addNotifications([
                errorNotification(
                  "Automation nodes",
                  t(
                    "An error occured during the import, see more details in the error report file."
                  )
                )
              ])
            )
          } else {
            const message =
              error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : error.message
            dispatch(addNotifications([errorNotification("Automation nodes", message)]))
          }
        })
        .then(() => {
          setProcessingImport(false)
          target.value = null
        })
    }
  }

  const menuEntries = [
    {
      label: t("Import"),
      icon: processingImport ? "fa-solid fa-spinner fa-spin" : "fa-solid fa-file-import",
      disabled: processingImport,
      items: [
        {
          label: "Modbus",
          command: () => hiddenFileInputModbus && hiddenFileInputModbus.current.click()
        },
        {
          label: "Bacnet",
          command: () => hiddenFileInputBacnet && hiddenFileInputBacnet.current.click()
        },
        { label: "SM", command: () => hiddenFileInputSM && hiddenFileInputSM.current.click() },
        {
          label: "Carel",
          command: () => hiddenFileInputCarel && hiddenFileInputCarel.current.click()
        },
        {
          label: "External API",
          command: () => hiddenFileInputExternal && hiddenFileInputExternal.current.click()
        }
      ]
    },
    {
      label: t("Export"),
      icon: processingExport ? "fa-solid fa-spinner fa-spin" : "fa-solid fa-file-export",
      disabled: processingExport,
      items: [
        { label: "Modbus", command: () => getExport("modbusnode") },
        { label: "Bacnet", command: () => getExport("bacnetnode") },
        { label: "SM", command: () => getExport("smnode") },
        { label: "Carel", command: () => getExport("carelnode") },
        { label: "External API", command: () => getExport("externalapinode") }
      ]
    },
    {
      label: t("Template"),
      icon: processingTemplate ? "fa-solid fa-spinner fa-spin" : "fa-solid fa-file",
      disabled: processingTemplate,
      items: [
        { label: "Modbus", command: () => getTemplate("modbusnode") },
        { label: "Bacnet", command: () => getTemplate("bacnetnode") },
        { label: "SM", command: () => getTemplate("smnode") },
        { label: "Carel", command: () => getTemplate("carelnode") },
        { label: "External API", command: () => getTemplate("externalapinode") }
      ]
    }
  ]

  const menuFilters = { columnsFilter, filters, setFilters, filterValues }

  const queryParams = () => {
    return {
      ...filters,
      limit,
      offset,
      ordering: sortField && `${sortOrder > 0 ? "" : "-"}${sortField}`
    }
  }

  useEffect(() => {
    siteStore.id && (offset ? setOffset(REQUEST_DEFAULT_OFFSET) : retrieve(queryParams()))
  }, [filters, sortField, sortOrder])

  useEffect(() => {
    siteStore.id && retrieve(queryParams())
  }, [limit, offset])

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id && organizationStore.id && getFilterValues()
  }, [siteStore.id, organizationStore.id])

  return (
    <>
      <ListActions
        entries={menuEntries}
        filterBackend={true}
        filters={menuFilters}
        loading={loadingFilters}
      >
        <input
          hidden
          type="file"
          ref={hiddenFileInputModbus}
          onChange={e => postImport(e.target, "modbusnode")}
        />
        <input
          hidden
          type="file"
          ref={hiddenFileInputBacnet}
          onChange={e => postImport(e.target, "bacnetnode")}
        />
        <input
          hidden
          type="file"
          ref={hiddenFileInputSM}
          onChange={e => postImport(e.target, "smnode")}
        />
        <input
          hidden
          type="file"
          ref={hiddenFileInputCarel}
          onChange={e => postImport(e.target, "carelnode")}
        />
        <input
          hidden
          type="file"
          ref={hiddenFileInputExternal}
          onChange={e => postImport(e.target, "externalapinode")}
        />
        <Link
          to={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/automation-nodes/create/`}
        >
          <Button
            rounded
            className="button"
            severity="info"
            icon="fa-regular fa-plus"
            label={t("Create")}
          />
        </Link>
      </ListActions>

      <ListLayout
        columns={columns}
        value={items}
        objectName={t("Automation node")}
        loading={loading}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
        bulkDeleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/automation-nodes/multi-delete/?${selected.map(item => `id=${item.id}`).join("&")}`}
        handleSelection={setSelected}
        offset={offset}
        limit={limit}
        setOffset={setOffset}
        setLimit={setLimit}
        count={count}
        onSort={onSort}
        sortOrder={sortOrder}
        sortField={sortField}
      />
    </>
  )
}
