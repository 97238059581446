import axios from "axios"

function header(token) {
  return {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

export async function retrieveList(siteId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/energy/sites/${siteId}/contracts?contract_type=Flexibility`,
    header(token)
  )
}

export async function add(siteId, data, token) {
  return await axios.post(
    `${window.BACKEND_URL}/energy/sites/${siteId}/contracts/`,
    data,
    header(token)
  )
}

export async function getGateways(siteId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/connectivity/sites/${siteId}/gateways/`,
    header(token)
  )
}

export async function getEnergyCompanies(token) {
  return await axios.get(`${window.BACKEND_URL}/energy/energy_companies/`, header(token))
}

export async function getEnergyDeliveryPoints(siteId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/energy/sites/${siteId}/energy_delivery_points/`,
    header(token)
  )
}
