import axios from "axios"
import { config } from "/utils/api"

export async function retrieveList(siteId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/connectivity/sites/${siteId}/gateways/`,
    config({ token })
  )
}

export async function retrieve(siteId, gatewayId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/connectivity/sites/${siteId}/gateways/${gatewayId}/`,
    config({ token })
  )
}

export async function add(siteId, data, token) {
  return await axios.post(
    `${window.BACKEND_URL}/connectivity/sites/${siteId}/gateways/`,
    data,
    config({ token })
  )
}

export async function update(siteId, gatewayId, data, token) {
  return await axios.put(
    `${window.BACKEND_URL}/connectivity/sites/${siteId}/gateways/${gatewayId}/`,
    data,
    config({ token })
  )
}

export async function remove(siteId, gatewayId, token) {
  return await axios.delete(
    `${window.BACKEND_URL}/connectivity/sites/${siteId}/gateways/${gatewayId}/`,
    config({ token })
  )
}

export async function getCount(gatewayId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/connectivity/gateways/${gatewayId}/count`,
    config({ token })
  )
}

export async function getHardwares(token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/hardwares/`, config({ token }))
}

export async function getSpaces(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/spaces/`, config({ token }))
}

export async function getIpNetworks(siteId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/networks/`,
    config({ token, queryParams: { type: "ipnetwork" } })
  )
}

export async function getDeployHistory(gatewayName, token) {
  return await axios.get(
    `${window.BACKEND_URL}/connectivity/deploy/history`,
    config({ token, queryParams: { device_id: gatewayName } })
  )
}
