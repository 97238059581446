import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { update } from "../api"
import { Bacnet, BacnetOverview } from "../models/bacnet"
import { Carel, CarelOverview } from "../models/carel"
import {
  ModbusBit,
  ModbusBitOverview,
  ModbusRegister,
  ModbusRegisterBitExtract,
  ModbusRegisterBitExtractOverview,
  ModbusRegisterOverview
} from "../models/modbus"
import { OPCUA, OPCUAOverview } from "../models/opcua"
import {
  SMController,
  SMControllerOverview,
  SMIO,
  SMIOOverview,
  SMMeter,
  SMMeterOverview
} from "../models/sm"
import { PointRoutesContext } from "../routes"
import { ExternalAPI, ExternalAPIOverview } from "../models/externalapi"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [object, setObject] = useState(null)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingNodes, setLoadingNodes] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [nodes, setNodes] = useState([])
  const { point, retrieveDetail } = useContext(PointRoutesContext)

  const mappingNodeTypes = {
    modbusbit: "modbusnode",
    modbusregister: "modbusnode",
    modbusregisterbitextract: "modbusnode",
    bacnet: "bacnetnode",
    opcua: "opcuanode",
    carel: "carelnode",
    smio: "smionode",
    smmeter: "smmeternode",
    smcontroller: "smcontrollernode",
    externalapi: "externalapinode"
  }

  const updateObject = async e => {
    e.preventDefault()
    setProcessing(true)

    const pointData = { ...data }
    if (pointData.type === "smcontroller") {
      pointData.rw = pointData.writable ? "W" : "R"
    }

    await update(siteStore.id, point.id, pointData, auth.userData.access_token)
      .then(() => {
        dispatch(
          addNotifications([
            successNotification("Automation point", t("The automation point has been updated."))
          ])
        )
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        error.response && error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Automation point", message)]))
      })
      .finally(() => setProcessing(false))
  }

  const retrieveNodes = async () => {
    setLoadingNodes(true)
    await paginatedRequest(
      `asset/sites/${siteStore.id}/nodes/?type=${mappingNodeTypes[object.type]}`,
      auth.userData.access_token
    )()
      .then(results => {
        setNodes(results)
      })
      .catch(error => {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setLoadingNodes(false))
  }

  const getModel = pointType => {
    switch (pointType) {
      case "modbusbit":
        return ModbusBit(nodes, loadingNodes)
      case "modbusregister":
        return ModbusRegister(nodes, loadingNodes)
      case "modbusregisterbitextract":
        return ModbusRegisterBitExtract(nodes, loadingNodes)
      case "bacnet":
        return Bacnet(nodes, loadingNodes)
      case "smio":
        return SMIO(nodes, loadingNodes)
      case "smcontroller":
        return SMController(nodes, loadingNodes)
      case "smmeter":
        return SMMeter(nodes, loadingNodes)
      case "opcua":
        return OPCUA(nodes, loadingNodes)
      case "carel":
        return Carel(nodes, loadingNodes)
      case "externalapi":
        return ExternalAPI(nodes, loadingNodes)
      default:
        return {}
    }
  }

  const getOverviewModel = pointType => {
    switch (pointType) {
      case "modbusbit":
        return ModbusBitOverview()
      case "modbusregister":
        return ModbusRegisterOverview()
      case "modbusregisterbitextract":
        return ModbusRegisterBitExtractOverview()
      case "bacnet":
        return BacnetOverview()
      case "smio":
        return SMIOOverview()
      case "smcontroller":
        return SMControllerOverview()
      case "smmeter":
        return SMMeterOverview()
      case "opcua":
        return OPCUAOverview()
      case "carel":
        return CarelOverview()
      case "externalapi":
        return ExternalAPIOverview()
      default:
        return {}
    }
  }

  useEffect(() => {
    if (point) {
      setLoading(false)
      setObject(point)
    }
  }, [point])

  useEffect(() => {
    edit && object && object.type && retrieveNodes()
  }, [siteStore.id, object, edit])

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  return (
    object &&
    siteStore.id && (
      <>
        <div className="base-content-title">
          <h2>
            {object.type} – {object.name}
          </h2>
        </div>
        <OverView
          object={object}
          model={data && getModel(data.type)}
          overviewModel={object && getOverviewModel(object.type)}
          updateObject={updateObject}
          data={data}
          setData={setData}
          errors={errors}
          edit={edit}
          loading={loading}
          processing={processing || loadingNodes}
          setEdit={setEdit}
          deleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/automation-points/${point.id}/delete/`}
        />
      </>
    )
  )
}
