import axios from "axios"
import { config } from "/utils/api"

export async function retrieveList(token, queryParams) {
  return await axios.get(
    `${window.BACKEND_URL}/asset/parameters/`,
    config({ token, queryParams, file: true })
  )
}

export async function getAllValues(token) {
  return await axios.get(`${window.BACKEND_URL}/asset/parameters/allvalues`, config({ token }))
}
