{
  "{{count}} sites_count_one": "{{count}} site",
  "{{count}} sites_count_other": "{{count}} sites",
  "Cancel": "Cancel",
  "Cards": "Cards",
  "Create": "Create",
  "Create organization": "Create organization",
  "List": "List",
  "Logo (SVG)": "Logo (SVG)",
  "Name": "Name",
  "Number of sites": "Number of sites",
  "Open": "Open",
  "Organization": "Organization",
  "Organizations": "Organizations",
  "Remove": "Remove",
  "Save": "Save",
  "Site": "Site",
  "The organization has been created": "The organization has been created.",
  "The organization has been removed": "The organization has been removed.",
  "The organization has been updated": "The organization has been updated.",
  "You can't delete this organization, there is sites linked to it": "You can't delete this organization, there is sites linked to it"
}
