import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { site } from "/utils/store/site"
import { Loader } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import * as React from "react"
import { createContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, Route, Routes, useParams } from "react-router-dom"
import { retrieve } from "./api"
import List from "./components/List"
import Create from "./components/Create"
import Detail from "./components/Detail"
import Delete from "./components/Delete"

const CircuitBreakerRelationRouteContext = createContext(null)

const CircuitBreakerRelationContent = () => {
  const siteStore = useSelector(site)
  const auth = useAuth()
  const [circuitBreakerRelation, setCircuitBreakerRelation] = useState()
  const { circuitBreakerRelationId } = useParams()
  const dispatch = useDispatch()

  const retrieveDetail = async () => {
    await retrieve(siteStore.id, circuitBreakerRelationId, auth.userData.access_token)
      .then(response => {
        setCircuitBreakerRelation(response.data)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
  }

  useEffect(() => {
    if (siteStore.id && circuitBreakerRelationId) {
      retrieveDetail()
    }
  }, [siteStore.id, circuitBreakerRelationId])
  return (
    <CircuitBreakerRelationRouteContext.Provider value={{ circuitBreakerRelation, retrieveDetail }}>
      {circuitBreakerRelation ? <Outlet /> : <Loader />}
    </CircuitBreakerRelationRouteContext.Provider>
  )
}

export { CircuitBreakerRelationRouteContext }
export default function CircuitBreakerRelationRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="create"
        element={<Create />}
      />

      <Route
        path=":circuitBreakerRelationId"
        element={<CircuitBreakerRelationContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
      </Route>
    </Routes>
  )
}
