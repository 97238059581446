import { RetailerRoutesContext } from "/apps/configuration/energy-company/retailer/routes"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { DeleteConfirmation } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { remove } from "../api"

export default function Delete() {
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["configuration"])
  const [processing, setProcessing] = useState(false)
  const { retailer } = useContext(RetailerRoutesContext)

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  const removeObject = async () => {
    setProcessing(true)
    await remove(retailer.id, auth.userData.access_token)
      .then(() => {
        displayNotification([successNotification("Delete", t("The retailer has been removed."))])
        navigate("/configuration/energy-company/retailers/")
      })
      .catch(error => {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        displayNotification([errorNotification("Error", message)])
      })
      .finally(() => setProcessing(false))
  }

  return (
    retailer && (
      <DeleteConfirmation
        objectType={t("Retailer")}
        objectNames={[retailer.name]}
        remove={removeObject}
        goBack={() => navigate(-1)}
        processing={processing}
      />
    )
  )
}
