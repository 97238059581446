import { retrieve } from "/apps/optimization/configuration/edge-controller/api"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { site } from "/utils/store/site"
import { useAuth } from "oidc-react"
import * as React from "react"
import { createContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, Route, Routes, useParams } from "react-router-dom"
import Create from "./components/Create"
import Delete from "./components/Delete"
import Detail from "./components/Detail"

import List from "./components/List"

const EdgeControllerRoutesContext = createContext(null)

const EdgeControllerContent = () => {
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const { edgeControllerId } = useParams()
  const auth = useAuth()
  const [edgeController, setEdgeController] = useState()

  const retrieveDetail = async () => {
    await retrieve(siteStore.id, edgeControllerId, auth.userData.access_token)
      .then(response => {
        setEdgeController(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    siteStore.id && edgeControllerId && retrieveDetail()
  }, [siteStore.id, edgeControllerId])

  return (
    <EdgeControllerRoutesContext.Provider value={{ edgeController, retrieveDetail }}>
      <Outlet />
    </EdgeControllerRoutesContext.Provider>
  )
}

export { EdgeControllerRoutesContext }
export default function EdgeControllerRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="create"
        element={<Create />}
      />
      <Route
        path=":edgeControllerId"
        element={<EdgeControllerContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
      </Route>
    </Routes>
  )
}
