import React from "react"
import { InputText } from "primereact/inputtext"
import { InputNumber } from "primereact/inputnumber"
import { useTranslation } from "react-i18next"

export default function BillFixPriceFields(props) {
  const { t } = useTranslation(["energy"])

  const update = (value, key) => {
    props.setPrice({ ...props.price, [key]: value })
  }

  return (
    <>
      <div className="field-container mt-3">
        <div className="field inputtext">
          <label className="label label-required">{t("Name")}</label>
          <div className="field-input">
            <InputText
              required
              value={props.price.name}
              onChange={e => update(e.target.value, "name")}
            />
          </div>
        </div>
      </div>

      <div className="field-container mt-3">
        <div className="field inputnumber">
          <label className="label label-required">{t("Value")}</label>
          <div className="field-input">
            <InputNumber
              required
              showButtons
              min={0}
              step={0.01}
              value={props.price.value}
              onChange={e => update(e.value, "value")}
            />
          </div>
        </div>
      </div>
    </>
  )
}
