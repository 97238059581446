import { config } from "/utils/api"
import axios from "axios"

export const retrieveAll = async (siteId, token) => {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/circuit_breakers/`,
    config({ token })
  )
}

export const retrieveList = async (siteId, token, queryParams) => {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/circuit_breakers/`,
    config({ token, queryParams })
  )
}

export const retrieve = async (siteId, id, token) => {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/circuit_breakers/${id}/`,
    config({ token })
  )
}

export const add = async (siteId, data, token) => {
  return await axios.post(
    `${window.BACKEND_URL}/asset/sites/${siteId}/circuit_breakers/`,
    data,
    config({ token })
  )
}

export const update = async (siteId, id, data, token) => {
  return await axios.put(
    `${window.BACKEND_URL}/asset/sites/${siteId}/circuit_breakers/${id}/`,
    data,
    config({ token })
  )
}

export const remove = async (siteId, id, token) => {
  return await axios.delete(
    `${window.BACKEND_URL}/asset/sites/${siteId}/circuit_breakers/${id}/`,
    config({ token })
  )
}

export const getCount = async (siteId, id, token) => {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/circuit_breakers/${id}/count`,
    config({ token })
  )
}

export const getAllValues = async (siteId, token) => {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/circuit_breakers/allvalues`,
    config({ token })
  )
}

export const exportCircuitBreakers = async (siteId, token) => {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/circuit_breakers/bulk`,
    config({ token })
  )
}

export const exportCircuitBreakersRelations = async (siteId, token) => {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/circuit_breaker_relations/bulk`,
    config({ token })
  )
}

export const importCircuitBreakers = async (siteId, file, token) => {
  const formData = new FormData()
  formData.append("file", file)
  return await axios.post(
    `${window.BACKEND_URL}/asset/sites/${siteId}/circuit_breakers/bulk/`,
    formData,
    config({ token, file: true }),
    { signal: new AbortController() }
  )
}

export const importCircuitBreakersRelations = async (siteId, file, token) => {
  const formData = new FormData()
  formData.append("file", file)
  return await axios.post(
    `${window.BACKEND_URL}/asset/sites/${siteId}/circuit_breaker_relations/bulk/`,
    formData,
    config({ token, file: true }),
    { signal: new AbortController() }
  )
}
