import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { FormContent } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { add, getEdgeControllerTypes, importConfigurationEdgeController } from "../api"
import { EdgeController } from "../models"
import { organization } from "/utils/store/organization"
import ConfigErrors from "./ConfigError"

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const hiddenFileInput = useRef(null)
  const { t } = useTranslation(["optimization"])

  const [data, setData] = useState({
    type: "Optimization"
  })
  const [errors, setErrors] = useState({})
  const [gateways, setGateways] = useState([])
  const [edgeControllerTypes, setEdgeControllerTypes] = useState(["Optimization"])

  const [configErrors, setConfigErrors] = useState(null)

  const getGW = async () => {
    // TODO: remove when the api will no longer paginate
    await paginatedRequest(
      `connectivity/sites/${siteStore.id}/gateways/`,
      auth.userData.access_token
    )()
      .then(results => {
        if ((results.length = 1)) {
          setData({
            ...data,
            gateway_id: results[0].id
          })
        }
        setGateways(results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })

    // TODO: uncomment when the API will no longer paginate
    /* await getGateways(siteId, auth.userData.access_token).then(response => {
      setGateways(response.data.results)
    }).catch(error => {
      dispatch(addNotifications([errorNotification("Error", error.message)]))
    }) */
  }

  const getECT = async () => {
    await getEdgeControllerTypes(auth.userData.access_token)
      .then(({ data }) => {
        setEdgeControllerTypes(data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const create = async function (e) {
    e.preventDefault()
    setConfigErrors(null)

    await add(siteStore.id, data, auth.userData.access_token)
      .then(response => {
        dispatch(
          addNotifications([
            successNotification("Create", t("The edge controller has been created."))
          ])
        )
        navigate(
          `/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/configuration/edge-controllers/${response.data.id}/`
        )
      })
      .catch(error => {
        if (error.response.data) {
          try {
            const errorConfig = JSON.parse(error.response.data.message)
            setConfigErrors(errorConfig)
          } catch (e) {
            console.log(e)
            setErrors(error.response.data)
          }
        }
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    if (siteStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
      getGW()
      getECT()
    }
  }, [siteStore.id])

  const postImport = async function (target) {
    const file = target.files[0]

    if (file) {
      await importConfigurationEdgeController(file, auth.userData.access_token)
        .then(response => {
          setData({
            ...data,
            config: response.data
          })
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })
        .finally(() => {
          target.value = null
        })
    }
  }

  return (
    data && (
      <>
        <div className="base-content-title">
          <h2>{t("Create edge controller")}</h2>

          <input
            className="hidden"
            ref={hiddenFileInput}
            type="file"
            onChange={e => postImport(e.target)}
          />
          <Button
            rounded
            label={t("Import")}
            type="button"
            icon="fa-solid fa-download"
            onClick={() => hiddenFileInput && hiddenFileInput.current.click()}
          />
        </div>
        <form
          className="form-view"
          onSubmit={create}
        >
          <FormContent
            model={EdgeController(gateways, edgeControllerTypes)}
            data={data}
            setData={setData}
            errors={errors}
          />
          {configErrors && <ConfigErrors configErrors={configErrors} />}
          <div className="action-buttons-wrapper">
            <Button
              rounded
              severity="success"
              label={t("Save")}
              type="submit"
              icon="fa-solid fa-floppy-disk"
            />
          </div>
        </form>
      </>
    )
  )
}
