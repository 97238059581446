import { CircuitBreakerRouteContext } from "/apps/assets/electrical/circuit-breaker/routes"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { retrieveEquipments, update } from "../api"
import { CircuitBreakerRelation, CircuitBreakerRelationOverview } from "../models"
import { CircuitBreakerRelationRouteContext } from "../routes"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [equipments, setEquipments] = useState([])
  const [loading, setLoading] = useState(true)
  const { circuitBreaker } = useContext(CircuitBreakerRouteContext)
  const { circuitBreakerRelation, retrieveDetail } = useContext(CircuitBreakerRelationRouteContext)
  const [data, setData] = useState({
    relationship_type: circuitBreakerRelation.relationship_type
  })

  const updateObject = async function (e) {
    e.preventDefault()
    setProcessing(true)
    await update(siteStore.id, circuitBreakerRelation.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(
          addNotifications([
            successNotification(
              "Circuit breaker relation",
              t("The circuit breaker relation has been updated.")
            )
          ])
        )
        setLoading(true)
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Circuit breaker relation", message)]))
      })
      .finally(() => setProcessing(false))
  }

  const getEquipments = async (queryParams = {}) => {
    await retrieveEquipments(siteStore.id, auth.userData.access_token, queryParams)
      .then(({ data }) => {
        setEquipments(data.results)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification("Circuit breaker relation", message)]))
      })
  }

  const deleteUrl = `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/electrical/circuit-breakers/${circuitBreaker.id}/circuit-breaker-relations/${circuitBreakerRelation.id}/delete/`

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    circuitBreakerRelation && setLoading(false)
  }, [circuitBreakerRelation])

  useEffect(() => {
    if (data.relationship_type) {
      data.relationship_type === "IS_MEASURED_BY"
        ? getEquipments({ equipment_type_name: ["Meter"] })
        : getEquipments()
      setData({ ...data, equipment_id: null })
    }
  }, [data.relationship_type])

  return (
    circuitBreakerRelation && (
      <OverView
        object={circuitBreakerRelation}
        model={CircuitBreakerRelation(equipments)}
        overviewModel={CircuitBreakerRelationOverview()}
        updateObject={updateObject}
        data={data}
        setData={setData}
        errors={errors}
        edit={edit}
        loading={loading}
        processing={processing}
        setEdit={setEdit}
        deleteUrl={deleteUrl}
      />
    )
  )
}
