import { SITE_FEATURES } from "/utils/constants/features"
import {
  CountryDropdown,
  DateInput,
  Dropdown
} from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"

const SiteBaseForm = (priceZones, organizations, loadingPriceZone, loadingOrganizations) => ({
  name: {
    label: i18n.t("Name", { ns: "assets" }),
    field: InputText,
    options: {
      required: true,
      maxLength: 255
    }
  },
  pretty_name: {
    label: i18n.t("Pretty name", { ns: "assets" }),
    field: InputText,
    options: {
      required: true,
      maxLength: 255
    }
  },
  organization_id: {
    label: i18n.t("Organization", { ns: "assets" }),
    field: Dropdown,
    options: {
      required: true,
      options: organizations,
      filter: true,
      optionLabel: "name",
      optionValue: "id",
      emptyMessage: "No organization found.",
      loading: loadingOrganizations,
      placeholder: loadingOrganizations
        ? "Loading..."
        : i18n.t("Select an organizations", { ns: "assets" })
    }
  },
  price_zone_id: {
    label: i18n.t("Price zone", { ns: "assets" }),
    field: Dropdown,
    options: {
      required: true,
      options: priceZones,
      filter: true,
      optionLabel: "name",
      optionValue: "id",
      loading: loadingPriceZone,
      placeholder: loadingPriceZone ? "Loading..." : i18n.t("Select a price zone", { ns: "assets" })
    }
  }
})

const SiteAddressForm = (weatherZones, loadingWeatherZone) => ({
  address: {
    label: i18n.t("Address", { ns: "assets" }),
    field: InputText,
    options: {
      required: true,
      maxLength: 255
    }
  },
  zip_code: {
    label: i18n.t("Zip code", { ns: "assets" }),
    field: InputText,
    options: {
      required: true,
      keyfilter: "pint",
      maxLength: 255
    }
  },
  city: {
    label: i18n.t("City", { ns: "assets" }),
    field: InputText,
    options: {
      required: true,
      maxLength: 255
    }
  },
  country: {
    label: i18n.t("Country", { ns: "assets" }),
    field: CountryDropdown,
    options: {
      required: true,
      filter: true
    }
  },
  latitude: {
    label: i18n.t("Latitude", { ns: "assets" }),
    field: InputNumber,
    options: {
      required: true,
      min: -90,
      max: 90,
      step: 0.0001,
      maxFractionDigits: 4
    }
  },
  longitude: {
    label: i18n.t("Longitude", { ns: "assets" }),
    field: InputNumber,
    options: {
      required: true,
      min: -180,
      max: 180,
      step: 0.0001,
      maxFractionDigits: 4
    }
  },
  altitude: {
    label: i18n.t("Altitude", { ns: "assets" }),
    field: InputNumber,
    options: {
      step: 1,
      maxFractionDigits: 4
    }
  },
  weather_zone_id: {
    label: i18n.t("Weather zone", { ns: "assets" }),
    field: Dropdown,
    options: {
      required: true,
      options: weatherZones,
      optionLabel: "city_name",
      optionValue: "id",
      filter: true,
      loading: loadingWeatherZone,
      emptyMessage: i18n.t("No weather zone found."),
      placeholder: loadingWeatherZone
        ? "Loading..."
        : i18n.t("Select a weather zone", { ns: "assets" })
    }
  },
  weather_zone_timezone: {
    label: i18n.t("Timezone", { ns: "assets" }),
    field: InputText,
    options: { disabled: true }
  }
})

const SiteStateForm = () => {
  let model = {
    active: {
      label: i18n.t("Active", { ns: "assets" }),
      field: InputSwitch
    }
  }

  if (SITE_FEATURES.SYNCHRONIZE_LEGACY) {
    model = {
      synchronise_legacy: {
        label: i18n.t("Synchronize legacy", { ns: "assets" }),
        field: InputSwitch
      },
      ...model
    }
  }

  return model
}

const SiteOverviewForm = (priceZones, organizations, loadingPriceZone, loadingOrganizations) => ({
  ...SiteBaseForm(priceZones, organizations, loadingPriceZone, loadingOrganizations),
  ...SiteStateForm()
})

const SiteForm = (
  priceZones,
  weatherZones,
  organizations,
  loadingPriceZone,
  loadingWeatherZone,
  loadingOrganizations
) => ({
  ...SiteBaseForm(priceZones, organizations, loadingPriceZone, loadingOrganizations),
  ...SiteAddressForm(weatherZones, loadingWeatherZone),
  ...SiteStateForm()
})

const SiteContractForm = () => ({
  name: {
    label: i18n.t("Name", { ns: "assets" }),
    field: InputText,
    options: {
      required: true,
      maxLength: 255,
      keyfilter: /[\w\d-.]/
    }
  },
  forced_stop_hours: {
    label: i18n.t("Forced stop hours", { ns: "assets" }),
    field: InputNumber,
    options: {
      required: true,
      showButtons: true,
      min: 1
    }
  },
  forced_stop_reset: {
    label: i18n.t("Forced stop reset", { ns: "assets" }),
    field: DateInput,
    options: {
      required: true
    }
  },
  bb_share: {
    label: i18n.t("Percent of share economics for BeeBryte", { ns: "assets" }),
    field: InputNumber,
    options: {
      required: true,
      showButtons: true,
      min: 1,
      max: 100
    }
  },
  start_validity: {
    label: i18n.t("Start validity", { ns: "assets" }),
    field: DateInput,
    options: {
      required: true
    }
  },
  end_validity: {
    label: i18n.t("End validity", { ns: "assets" }),
    field: DateInput,
    options: {
      required: true
    }
  }
})

const SiteOverview = () => ({
  pretty_name: { label: i18n.t("Pretty name", { ns: "assets" }) },
  name: { label: i18n.t("Name", { ns: "assets" }) },
  price_zone_name: { label: i18n.t("Price zone", { ns: "assets" }) }, // @todo: replace by price zone name
  synchronise_legacy: { label: i18n.t("Synchronize legacy", { ns: "assets" }) },
  active: { label: i18n.t("Active", { ns: "assets" }) }
})

const SiteAddress = () => ({
  address: { label: i18n.t("Address", { ns: "assets" }) },
  zip_code: { label: i18n.t("Zip code", { ns: "assets" }) },
  city: { label: i18n.t("City", { ns: "assets" }) },
  country: { label: i18n.t("Country", { ns: "assets" }) },
  latitude: { label: i18n.t("Latitude", { ns: "assets" }) },
  longitude: { label: i18n.t("Longitude", { ns: "assets" }) },
  altitude: { label: i18n.t("Altitude", { ns: "assets" }) },
  weather_zone_name: { label: i18n.t("Weather zone", { ns: "assets" }) },
  weather_zone_timezone: { label: i18n.t("Timezone", { ns: "assets" }) }
})

const SiteContract = () => ({
  name: { label: i18n.t("Name", { ns: "assets" }) },
  forced_stop_hours: { label: i18n.t("Forced stop hours", { ns: "assets" }) },
  forced_stop_reset: { label: i18n.t("Forced stop reset", { ns: "assets" }) },
  bb_share: { label: i18n.t("Percentage of share economics for BeeBryte", { ns: "assets" }) },
  start_validity: { label: i18n.t("Start validity", { ns: "assets" }) },
  end_validity: { label: i18n.t("End validity", { ns: "assets" }) }
})

export {
  SiteForm,
  SiteAddressForm,
  SiteOverviewForm,
  SiteContractForm,
  SiteOverview,
  SiteAddress,
  SiteContract
}
