import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import i18n from "i18next"

export const ExternalAPINode = spaces => {
  return {
    name: {
      label: i18n.t("Name", { ns: "assets" }),
      field: InputText,
      options: { required: true }
    },
    external_id: {
      label: i18n.t("External ID", { ns: "assets" }),
      field: InputText,
      options: { required: true }
    },
    space: {
      label: i18n.t("Space", { ns: "assets" }),
      field: Dropdown,
      options: {
        options: spaces,
        optionLabel: "name",
        optionValue: "id",
        filter: true
      }
    },
    description: {
      label: i18n.t("Description", { ns: "assets" }),
      field: InputTextarea,
      options: {}
    }
  }
}

export const ExternalAPIOverview = () => {
  return {
    name: { label: i18n.t("Name", { ns: "assets" }) },
    external_id: { label: i18n.t("External ID", { ns: "assets" }) },
    space_label: { label: i18n.t("Space", { ns: "assets" }) },
    description: { label: i18n.t("Description", { ns: "assets" }) }
  }
}
