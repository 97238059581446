import { config } from "/utils/api"
import { REQUEST_DEFAULT_LIMIT } from "/utils/constants/api"
import axios from "axios"

export async function retrieveList(siteId, token, queryParams) {
  return await axios.get(
    `${window.BACKEND_URL}/energy/sites/${siteId}/energy_delivery_points/`,
    config({ token, queryParams })
  )
}

export async function retrieve(siteId, id, token) {
  return await axios.get(
    `${window.BACKEND_URL}/energy/sites/${siteId}/energy_delivery_points/${id}/`,
    config({ token })
  )
}

export async function add(siteId, data, token) {
  return await axios.post(
    `${window.BACKEND_URL}/energy/sites/${siteId}/energy_delivery_points/`,
    data,
    config({ token })
  )
}

export async function update(siteId, id, data, token) {
  return await axios.put(
    `${window.BACKEND_URL}/energy/sites/${siteId}/energy_delivery_points/${id}/`,
    data,
    config({ token })
  )
}

export async function remove(siteId, id, token) {
  return await axios.delete(
    `${window.BACKEND_URL}/energy/sites/${siteId}/energy_delivery_points/${id}/`,
    config({ token })
  )
}

export async function getDistributors(token) {
  return await axios.get(
    `${window.BACKEND_URL}/energy/energy_companies/?company_type=Distributor`,
    config({ token, queryParams: { company_type: "Distributor" } })
  )
}

export async function getTransmissionCompanies(token) {
  return await axios.get(
    `${window.BACKEND_URL}/energy/energy_companies/`,
    config({ token, queryParams: { company_type: "Transmission" } })
  )
}

export async function getPoints(siteId, token, offset) {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/points/`,
    config({ token, queryParams: { limit: REQUEST_DEFAULT_LIMIT, offset: offset } })
  )
}

export async function getAllValues(siteId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/energy/sites/${siteId}/energy_delivery_points/allvalues`,
    config({ token })
  )
}
