import axios from "axios"

function header(token) {
  return {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

export async function getCurtailParameters(flexId, token) {
  return await axios.get(
    `${window.BACKEND_FLEX_URL}/flex/${flexId}/constant_load_curtail_parameters/`,
    header(token)
  )
}

export async function createCurtailParameters(flexId, data, token) {
  return await axios.post(
    `${window.BACKEND_FLEX_URL}/flex/${flexId}/constant_load_curtail_parameters/`,
    data,
    header(token)
  )
}

export async function updateCurtailParameters(flexId, curtailParametersId, data, token) {
  return await axios.patch(
    `${window.BACKEND_FLEX_URL}/flex/${flexId}/constant_load_curtail_parameters/${curtailParametersId}/`,
    data,
    header(token)
  )
}

export async function removeCurtailParameters(flexId, curtailParametersId, token) {
  return await axios.delete(
    `${window.BACKEND_FLEX_URL}/flex/${flexId}/constant_load_curtail_parameters/${curtailParametersId}/`,
    header(token)
  )
}

export async function getHistory(flexId, curtailParametersId, token) {
  return await axios.get(
    `${window.BACKEND_FLEX_URL}/flex/${flexId}/constant_load_curtail_parameters_history/${curtailParametersId}/`,
    header(token)
  )
}
