import { retrieve } from "/apps/energy/contract/api"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { site } from "/utils/store/site"
import { Loader } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import * as React from "react"
import { createContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, Route, Routes, useParams } from "react-router-dom"
import Create from "./components/Create"
import Delete from "./components/Delete"
import Detail from "./components/Detail"
import List from "./components/List"

const ContractRoutesContext = createContext(null)

const ContractContent = () => {
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const { contractId } = useParams()
  const auth = useAuth()
  const [contract, setContract] = useState()

  const retrieveDetail = async () => {
    await retrieve(siteStore.id, contractId, auth.userData.access_token)
      .then(response => {
        setContract({
          ...response.data,
          optimization_gateways: response.data.optimization_gateways_id,
          optimization_gateways_names: response.data.optimization_gateways_name
        })
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    siteStore.id && retrieveDetail()
  }, [siteStore.id])

  return (
    <ContractRoutesContext.Provider value={{ contract, retrieveDetail }}>
      {contract ? <Outlet /> : <Loader />}
    </ContractRoutesContext.Provider>
  )
}

export { ContractRoutesContext }
export default function ContractRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="create"
        element={<Create />}
      />
      <Route
        path=":contractId"
        element={<ContractContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
      </Route>
    </Routes>
  )
}
