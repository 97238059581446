import React, { useState, useEffect } from "react"

import { InputText } from "primereact/inputtext"
import { InputNumber } from "primereact/inputnumber"
import { useTranslation } from "react-i18next"
import { Button } from "primereact/button"

const SchedulePriceFields = props => {
  const { t } = useTranslation(["energy"])

  const update = (value, key, index) => {
    const values = [...props.values]
    values[index] = {
      ...values[index],
      [key]: value
    }
    props.setValues(values)
  }

  const remove = index => {
    const values = [...props.values]
    values.splice(index, 1)
    props.setValues(values)
  }

  const add = () => {
    const values = [...props.values]
    values.push({ name: "", value: 0 })
    props.setValues(values)
  }

  return (
    <>
      <Button
        className="mt-3"
        rounded
        type="button"
        onClick={add}
      >
        {t("Add value")}
      </Button>
      {props.values.map((value, index) => {
        return (
          <div
            key={index}
            className="flex w-full gap-4 align-items-center mt-3"
          >
            <div className="field-container flex-grow-1">
              <div className="field inputtext">
                <label className="label label-required">{t("Name")}</label>
                <div className="field-input">
                  <InputText
                    required
                    value={value.name}
                    onChange={e => update(e.value, "name", index)}
                  />
                </div>
              </div>
            </div>
            <div className="field-container flex-grow-1">
              <div className="field inputtext">
                <label className="label label-required">{t("Value")}</label>
                <div className="field-input">
                  <InputNumber
                    required
                    value={value.value}
                    onChange={e => update(e.value, "value", index)}
                    min={0}
                    showButtons
                    step={0.01}
                  />
                </div>
              </div>
            </div>
            <i
              className="fa-solid fa-trash cursor-pointer"
              onClick={() => remove(index)}
            />
          </div>
        )
      })}
    </>
  )
}

export default function BillSchedulePriceFields(props) {
  const { t } = useTranslation(["energy"])
  const [values, setValues] = useState([
    { name: "HCE", value: 0 },
    { name: "HCH", value: 0 },
    { name: "HPE", value: 0 },
    { name: "HPH", value: 0 },
    { name: "Pointe", value: 0 }
  ])

  useEffect(() => {
    if (props.price.value) {
      setValues(
        Object.entries(props.price.value).map(([key, value]) => {
          return {
            name: key,
            value: value
          }
        })
      )
    }
  }, [])

  useEffect(() => {
    updateMultiValue(values)
  }, [values])

  const update = (value, key) => {
    props.setPrice({ ...props.price, ...{ [key]: value } })
  }

  const updateMultiValue = values => {
    setValues(values)
    const value = values.reduce(
      (obj, price) => Object.assign(obj, { [price.name]: price.value }),
      {}
    )
    props.setPrice({ ...props.price, ...{ value: value } })
  }

  return (
    <div>
      <div className="field-container mt-3">
        <div className="field inputtext">
          <label className="label label-required">{t("Name")}</label>
          <div className="field-input">
            <InputText
              required
              value={props.price.name}
              onChange={e => update(e.value, "name")}
            />
          </div>
        </div>
      </div>

      <SchedulePriceFields
        values={values}
        setValues={setValues}
      />
    </div>
  )
}
