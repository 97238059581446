import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { site } from "/utils/store/site"
import { InnerTabsLayout, Loader } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import * as React from "react"
import { createContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Route, Routes, useParams } from "react-router-dom"
import SpaceConstraintDetailsRoutes from "../space-constraint-details/routes"
import { retrieve } from "./api"
import Create from "./components/Create"
import Delete from "./components/Delete"
import DeleteBulk from "./components/DeleteBulk"
import Detail from "./components/Detail"
import List from "./components/List"

const SpaceConstraintRouteContext = createContext(null)

const SpaceConstraintContent = () => {
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const auth = useAuth()
  const [spaceConstraint, setSpaceConstraint] = useState()
  const { spaceConstraintId } = useParams()
  const { t } = useTranslation(["operation"])
  const dispatch = useDispatch()

  const [tabs, setTabs] = useState()

  const retrieveDetail = async () => {
    await retrieve(siteStore.id, spaceConstraintId, auth.userData.access_token)
      .then(response => {
        setSpaceConstraint(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    if (siteStore.id && spaceConstraintId) {
      setTabs([
        {
          name: t("Base informations"),
          active:
            location.pathname.endsWith(`/space-constraints/${spaceConstraintId}/`) ||
            location.pathname.endsWith(`/space-constraints/${spaceConstraintId}/delete/`),
          url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/space-constraints/${spaceConstraintId}/`
        },
        {
          name: t("Details"),
          active: location.pathname.includes(`/space-constraints/${spaceConstraintId}/details`),
          url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/space-constraints/${spaceConstraintId}/details/`
        }
      ])

      retrieveDetail()
    }
  }, [spaceConstraintId, siteStore.id, organizationStore.id])

  return (
    <SpaceConstraintRouteContext.Provider value={{ spaceConstraint, retrieveDetail }}>
      {spaceConstraint ? (
        <InnerTabsLayout
          title={spaceConstraint.name}
          tabs={tabs}
          setTabs={setTabs}
        />
      ) : (
        <Loader />
      )}
    </SpaceConstraintRouteContext.Provider>
  )
}

export { SpaceConstraintRouteContext }
export default function SpaceConstraintRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="create"
        element={<Create />}
      />
      <Route
        path="multi-delete"
        element={<DeleteBulk />}
      />
      <Route
        path=":spaceConstraintId"
        element={<SpaceConstraintContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
        <Route
          path="details/*"
          element={<SpaceConstraintDetailsRoutes />}
        />
      </Route>
    </Routes>
  )
}
