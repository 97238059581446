import axios from "axios"

function header(token) {
  return {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

function headerFile(token) {
  return {
    headers: {
      "Content-Type": "multipart/form-data; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

export async function retrieveList(siteId, queryParams, token) {
  return await axios.get(
    `${window.BACKEND_URL}/optimization/sites/${siteId}/states/${queryParams}`,
    header(token)
  )
}

export async function retrieve(siteId, stateId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/optimization/sites/${siteId}/states/${stateId}/`,
    header(token)
  )
}

export async function add(siteId, data, token) {
  return await axios.post(
    `${window.BACKEND_URL}/optimization/sites/${siteId}/states/`,
    data,
    header(token)
  )
}

export async function update(siteId, stateId, data, token) {
  return await axios.put(
    `${window.BACKEND_URL}/optimization/sites/${siteId}/states/${stateId}/`,
    data,
    header(token)
  )
}

export async function remove(siteId, stateId, token) {
  return await axios.delete(
    `${window.BACKEND_URL}/optimization/sites/${siteId}/state/history/${stateId}`,
    header(token)
  )
}

export async function getGateways(siteId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/connectivity/sites/${siteId}/gateways/`,
    header(token)
  )
}

export async function getWritablePoints(siteId, gatewayId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/points/?write=True&has_automation_point=True&gateway=${gatewayId}`,
    header(token)
  )
}

export async function getHistory(siteId, historyId, stateType, token) {
  return await axios.get(
    `${window.BACKEND_URL}/optimization/sites/${siteId}/state/${stateType}/history/?history_id=${historyId}`,
    header(token)
  )
}

export async function exportStateValues(siteId, stateId, token) {
  return await axios({
    method: "get",
    url: `${window.BACKEND_URL}/optimization/sites/${siteId}/state-values/${stateId}/bulk/export/`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export async function importStateValues(siteId, stateId, file, token) {
  let formData = new FormData()
  formData.append("file", file)
  return await axios.post(
    `${window.BACKEND_URL}/optimization/sites/${siteId}/state-values/${stateId}/bulk/import/`,
    formData,
    headerFile(token),
    { signal: new AbortController() }
  )
}

export async function getStateArchive(siteId, stateId, token) {
  return await axios({
    method: "get",
    url: `${window.BACKEND_URL}/optimization/sites/${siteId}/state/${stateId}/download`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}
