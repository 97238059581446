{
  "Alarm (4)": "Alarm (4)",
  "Analogue (2)": "Analogue (2)",
  "Data type": "Data type",
  "Digital (1)": "Digital (1)",
  "Distributor": "Distributor",
  "Gateway": "Gateway",
  "Height": "Height",
  "Integer (3)": "Integer (3)",
  "No weather zone found": "No weather zone found.",
  "Sign out": "Sign out",
  "Surface area (m²)": "Surface area (m²)"
}
