import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "/utils/constants/api"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { BaseLayout, ListLayout } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { retrieveList, update } from "../api"
import { Dropdown } from "primereact/dropdown"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"
import { IconField } from "primereact/iconfield"
import { InputIcon } from "primereact/inputicon"

const countries = require("../data/country.json")

const ActionsBodyTemplate = props => {
  const [active, setActive] = useState(props.active)
  const auth = useAuth()
  const dispatch = useDispatch()
  const { t } = useTranslation(["configuration"])
  const [loading, setLoading] = useState(false)

  const activateWeatherZone = async value => {
    setLoading(true)
    await update(props.id, { active: value }, auth.userData.access_token)
      .then(() => {
        setActive(value)
        dispatch(
          addNotifications([
            successNotification(
              "Success",
              value
                ? t("The weather zone has been activated")
                : t("The weather zone has been desactivated")
            )
          ])
        )
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
        setActive(!value)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <InputSwitch
      disabled={loading}
      checked={active}
      onChange={e => activateWeatherZone(e.value)}
    />
  )
}

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const [weatherZones, setWeatherZones] = useState([])
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["configuration"])

  const [countryCode, setCountryCode] = useState("FR")
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset, setOffset] = useState(REQUEST_DEFAULT_OFFSET)
  const [count, setCount] = useState(0)
  const [sortOrder, setSortOrder] = useState(1)
  const [sortField, setSortField] = useState("city_name")

  const [search, setSearch] = useState("")

  const retrieve = async (
    queryParams = {
      limit,
      offset,
      ordering: sortField && `${sortOrder > 0 ? "" : "-"}${sortField}`
    }
  ) => {
    setLoading(true)
    await retrieveList(countryCode, auth.userData.access_token, queryParams)
      .then(({ data }) => {
        setCount(data.count)
        setWeatherZones(data.results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleSearch = e => {
    e.preventDefault()
    const queryParams = {
      limit,
      offset,
      search: search.split(" "),
      ordering: sortField && `${sortOrder > 0 ? "" : "-"}${sortField}`
    }
    retrieve(queryParams)
  }

  const onSort = sortEvent => {
    setSortOrder(sortEvent.sortOrder)
    setSortField(sortEvent.sortField)
  }

  useEffect(() => {
    offset === REQUEST_DEFAULT_OFFSET ? retrieve() : setOffset(REQUEST_DEFAULT_OFFSET)
  }, [countryCode, sortField, sortOrder])

  useEffect(() => {
    retrieve()
  }, [limit, offset])

  const columns = [
    { field: "owm_cityid", header: t("OWM City ID"), sortable: true },
    { field: "city_name", header: t("City"), sortable: true },
    { field: "timezone", header: t("Timezone") },
    { field: "country_code", header: t("Country code") }
  ]

  return (
    <BaseLayout title={t("Weather zones")}>
      <div className="flex gap-6">
        <Dropdown
          filter
          value={countryCode}
          optionLabel="name"
          optionValue="code"
          options={countries}
          onChange={e => setCountryCode(e.value)}
          placeholder="Select Country"
        />

        <form onSubmit={handleSearch}>
          <IconField iconPosition="left">
            <InputIcon
              onClick={handleSearch}
              className="fa-solid fa-search"
            >
              {" "}
            </InputIcon>
            <InputText
              placeholder={t("Search")}
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
          </IconField>
        </form>
      </div>
      <ListLayout
        columns={columns}
        value={weatherZones}
        loading={loading}
        limit={limit}
        offset={offset}
        setLimit={setLimit}
        setOffset={setOffset}
        count={count}
        actionsBodyTemplate={ActionsBodyTemplate}
        onSort={onSort}
        sortOrder={sortOrder}
        sortField={sortField}
      />
    </BaseLayout>
  )
}
