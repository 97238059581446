import { config } from "/utils/api"
import axios from "axios"

export const retrieveList = async (siteId, token, queryParams) => {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/circuit_breaker_relations/`,
    config({ token, queryParams })
  )
}

export const retrieveAllValues = async (siteId, token, queryParams) => {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/circuit_breaker_relations/allvalues`,
    config({ token, queryParams })
  )
}

export const add = async (siteId, data, token) => {
  return await axios.post(
    `${window.BACKEND_URL}/asset/sites/${siteId}/circuit_breaker_relations/`,
    data,
    config({ token })
  )
}

export const retrieve = async (siteId, circuitBreakerRelationId, token) => {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/circuit_breaker_relations/${circuitBreakerRelationId}/`,
    config({ token })
  )
}

export const update = async (siteId, circuitBreakerRelationId, data, token) => {
  return await axios.put(
    `${window.BACKEND_URL}/asset/sites/${siteId}/circuit_breaker_relations/${circuitBreakerRelationId}/`,
    data,
    config({ token })
  )
}

export const remove = async (siteId, circuitBreakerRelationId, token) => {
  return await axios.delete(
    `${window.BACKEND_URL}/asset/sites/${siteId}/circuit_breaker_relations/${circuitBreakerRelationId}/`,
    config({ token })
  )
}

export const retrieveEquipments = async (siteId, token, queryParams) => {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/equipments/`,
    config({ token, queryParams })
  )
}
