import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { DeleteConfirmation } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getCount, remove } from "../api"
import { PointRoutesContext } from "../routes"

export default function Delete() {
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["assets"])
  const { point } = useContext(PointRoutesContext)

  const [relatedObjects, setRelatedObjects] = useState(null)

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  const count = async () => {
    await getCount(point.id, auth.userData.access_token)
      .then(response => {
        setRelatedObjects(response.data)
      })
      .catch(error => {
        displayNotification(errorNotification("Error", error.message))
      })
  }

  const removeObject = async () => {
    await remove(siteStore.id, point.id, auth.userData.access_token)
      .then(() => {
        displayNotification([
          successNotification("Delete", t("The automation point has been removed."))
        ])
        navigate(
          `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/automation-points/`
        )
      })
      .catch(error => {
        displayNotification([errorNotification("Error", error.message)])
      })
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (siteStore.id && point) {
      count()
    }
  }, [siteStore.id, organizationStore.id, point])

  return (
    point && (
      <DeleteConfirmation
        objectType={t("Automation point")}
        objectNames={[point.label ?? point.name]}
        remove={removeObject}
        relatedObjects={relatedObjects}
        goBack={() => navigate(-1)}
      />
    )
  )
}
