import i18n from "i18next"

const menu = (menuName, organizationId = null, siteId = null) => {
  let items

  switch (menuName) {
    case "parameters":
      items = [
        {
          slug: "configuration.gateways",
          label: i18n.t("Gateways", { ns: "common" }),
          icon: "fa-solid fa-microchip",
          url: "/configuration/gateways/boxes/",
          disabled: false,
          visible: true
        },
        {
          slug: "configuration.parameters",
          label: i18n.t("Units & quantities", { ns: "common" }),
          icon: "fa-solid fa-sliders",
          url: "/configuration/units-and-quantities/",
          disabled: false,
          visible: true
        },
        {
          slug: "configuration.companies",
          label: i18n.t("Energy companies", { ns: "common" }),
          icon: "fa fa-bolt",
          namespace: "/configuration/energy-company/",
          url: "/configuration/energy-company/distributors/",
          disabled: false,
          visible: true
        },
        {
          slug: "configuration.edge_block_templates",
          label: i18n.t("Edge block templates", { ns: "common" }),
          icon: "fa-solid fa-code",
          url: "/configuration/edge-block-templates",
          disabled: false,
          visible: true
        },
        {
          slug: "configuration.weather_zone",
          label: i18n.t("Weather zone", { ns: "common" }),
          icon: "fa-solid fa-cloud-rain",
          url: "/configuration/weather-zones/",
          disabled: false,
          visible: true
        },
        {
          slug: "configuration.price_zone",
          label: i18n.t("Price zone", { ns: "common" }),
          icon: "fa-solid fa-landmark",
          url: "#",
          disabled: true,
          visible: true
        },
        {
          slug: "configuration.equipment_type",
          label: i18n.t("Equipment type", { ns: "common" }),
          icon: "fa-solid fa-plug",
          url: "/configuration/equipment-types/",
          disabled: false,
          visible: true
        }
      ]
      break

    case "organizations":
      items = [
        {
          slug: "sites",
          label: i18n.t("Sites", { ns: "common" }),
          icon: "fa-solid fa-city",
          url: `/organizations/${organizationId}/sites/`,
          disabled: false,
          visible: true
        }
      ]
      break

    case "site":
      items = [
        {
          slug: "assets",
          label: i18n.t("Assets", { ns: "common" }),
          icon: "fa-solid fa-network-wired",
          url: `/organizations/${organizationId}/sites/${siteId}/assets/`,
          namespace: `/organizations/${organizationId}/sites/${siteId}/assets/`,
          disabled: false,
          visible: true,
          items: [
            {
              label: i18n.t("Communication", { ns: "common" }),
              icon: "fa-solid fa-podcast",
              url: `/organizations/${organizationId}/sites/${siteId}/assets/communication/networks/`,
              namespace: `/organizations/${organizationId}/sites/${siteId}/assets/communication/`,
              disabled: false,
              visible: true
            },
            {
              label: i18n.t("Gateways", { ns: "common" }),
              icon: "fa-solid fa-tower-broadcast",
              url: `/organizations/${organizationId}/sites/${siteId}/assets/gateways/`,
              namespace: `/organizations/${organizationId}/sites/${siteId}/assets/gateways/`,
              disabled: false,
              visible: true
            },
            {
              label: i18n.t("Building", { ns: "common" }),
              icon: "fa-regular fa-building",
              url: `/organizations/${organizationId}/sites/${siteId}/assets/building/spaces/`,
              namespace: `/organizations/${organizationId}/sites/${siteId}/assets/building/`,
              disabled: false,
              visible: true
            },
            {
              label: i18n.t("Electrical", { ns: "common" }),
              icon: "fa-solid fa-plug",
              url: `/organizations/${organizationId}/sites/${siteId}/assets/electrical/circuit-breakers/`,
              namespace: `/organizations/${organizationId}/sites/${siteId}/assets/electrical/`,
              disabled: false,
              visible: true
            }
          ]
        },
        {
          slug: "energy",
          label: i18n.t("Energy", { ns: "common" }),
          icon: "fa fa-bolt",
          namespace: `/organizations/${organizationId}/sites/${siteId}/energy/`,
          url: `/organizations/${organizationId}/sites/${siteId}/energy/energy-delivery-points/`,
          disabled: false,
          visible: true
        },
        {
          slug: "connectivity",
          label: i18n.t("Connectivity", { ns: "common" }),
          icon: "fa-solid fa-wifi",
          url: `/organizations/${organizationId}/sites/${siteId}/connectivity/`,
          disabled: false,
          visible: false
        },
        {
          label: i18n.t("Operations", { ns: "common" }),
          icon: "fa-solid fa-shuffle",
          namespace: `/organizations/${organizationId}/sites/${siteId}/operations/`,
          url: `/organizations/${organizationId}/sites/${siteId}/operations/space-constraints`,
          disabled: false,
          visible: true
        },
        {
          slug: "optimization",
          label: i18n.t("Optimization", { ns: "common" }),
          icon: "fa-solid fa-gears",
          url: `/organizations/${organizationId}/sites/${siteId}/optimization/`,
          disabled: false,
          visible: true,
          items: [
            {
              label: i18n.t("Configuration", { ns: "common" }),
              icon: "fa-solid fa-gear",
              namespace: `/organizations/${organizationId}/sites/${siteId}/optimization/configuration/`,
              url: `/organizations/${organizationId}/sites/${siteId}/optimization/configuration/states/`,
              disabled: false,
              visible: true
            },
            {
              label: i18n.t("Flexibility", { ns: "common" }),
              icon: "fa-solid fa-shuffle",
              namespace: `/organizations/${organizationId}/sites/${siteId}/optimization/flexibility/`,
              url: `/organizations/${organizationId}/sites/${siteId}/optimization/flexibility/`,
              disabled: false,
              visible: false
            }
          ]
        },
        {
          slug: "events",
          label: i18n.t("Events", { ns: "common" }),
          icon: "fa-solid fa-bell",
          namespace: `/organizations/${organizationId}/sites/${siteId}/events/`,
          url: `/organizations/${organizationId}/sites/${siteId}/events/`,
          disabled: false,
          visible: true
        }
      ]
      break

    default:
      items = []
      break
  }

  return items
}

export default menu
