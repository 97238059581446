import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputText } from "primereact/inputtext"

export const Interface = (networks, nodeType, networkType, loadingNetworks) => {
  const bacnetField = {
    mstp_address: {
      label: i18n.t("MSTP address", { ns: "assets" }),
      field: InputText,
      dependsOn: "network",
      options: {
        required: true
      }
    }
  }

  const ipFields = {
    ip_address: {
      label: i18n.t("IP Address", { ns: "assets" }),
      field: InputText,
      helpText: i18n.t("CIDR format: {{format}}", { ns: "assets", format: "192.168.3.56" }),
      options: {
        required: true,
        pattern:
          "((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))"
      }
    },
    port: {
      label: i18n.t("Port", { ns: "assets" }),
      field: InputNumber,
      options: {
        required: true,
        showButtons: true
      }
    }
  }

  const networkField = {
    network: {
      label: i18n.t("Network", { ns: "assets" }),
      field: Dropdown,
      options: {
        options: networks,
        required: true,
        optionValue: "id",
        optionLabel: "context_name",
        loading: loadingNetworks,
        placeholder: loadingNetworks ? "Loading..." : "Select network"
      }
    }
  }

  if (nodeType === "bacnetnode" && networkType === "serialnetwork") {
    return {
      ...networkField,
      ...bacnetField
    }
  }
  if (networkType === "ipnetwork") {
    return {
      ...networkField,
      ...ipFields
    }
  }
  return networkField
}
