import axios from "axios"
import { config } from "/utils/api"

const BASE_URL = siteId =>
  `${window.BACKEND_URL}/operation/sites/${siteId}/unavailability_periods/jira/`

export async function retrieveList(siteId, token, queryParams) {
  return await axios.get(BASE_URL(siteId), config({ token, queryParams }))
}

export async function retrieve(siteId, unavailabilityPeriodId, token) {
  return await axios.get(`${BASE_URL(siteId)}${unavailabilityPeriodId}/`, config({ token }))
}

export async function add(siteId, data, token) {
  return await axios.post(BASE_URL(siteId), data, config({ token }))
}

export async function update(siteId, unavailabilityPeriodId, data, token) {
  return await axios.put(`${BASE_URL(siteId)}${unavailabilityPeriodId}/`, data, config({ token }))
}

export async function remove(siteId, unavailabilityPeriodId, token) {
  return await axios.delete(`${BASE_URL(siteId)}${unavailabilityPeriodId}/`, config({ token }))
}

export async function getUnavailabilityCategories(siteId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/operation/sites/${siteId}/unavailability_categories/jira/`,
    config({ token })
  )
}

export async function removeUnavailabilityPeriods(siteId, ids, token) {
  return await axios.post(
    `${window.BACKEND_URL}/operation/sites/${siteId}/unavailability_periods/bulk-delete/`,
    ids,
    config({ token })
  )
}

export async function getAllValues(siteId, token) {
  return await axios.get(`${BASE_URL(siteId)}allvalues`, config({ token }))
}
