import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const EdgeBlockTemplate = () => {
  return {
    name: {
      label: i18n.t("Name", { ns: "optimization" }),
      field: InputText,
      options: { required: true, maxLength: 255 }
    },
    priority: {
      label: i18n.t("Priority", { ns: "optimization" }),
      field: InputNumber,
      options: { min: 1, max: 9, required: true }
    },
    condition: {
      label: i18n.t("Condition", { ns: "optimization" }),
      field: InputTextarea,
      options: { required: true }
    },
    action: {
      label: i18n.t("Action", { ns: "optimization" }),
      field: InputTextarea,
      options: { required: true }
    },
    description: { label: i18n.t("Description", { ns: "optimization" }), field: InputTextarea },
    updated_by: {
      label: i18n.t("Updated by", { ns: "optimization" }),
      field: InputText,
      options: { required: true, disabled: true }
    }
  }
}
