import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "/utils/constants/api"
import { datetimeStr } from "/utils/datetime"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ListActions, ListLayout } from "@software-engineering/hivolution-frontend-utils"
import fileDownload from "js-file-download"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { exportNetworks, exportTemplate, getAllValues, importNetwork, retrieveList } from "../api"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const hiddenFileInput = useRef(null)
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingFilters, setLoadingFilters] = useState(true)
  const [filters, setFilters] = useState({})
  const [filterValues, setFilterValues] = useState(null)
  const [selected, setSelected] = useState([])
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset, setOffset] = useState(REQUEST_DEFAULT_OFFSET)
  const [count, setCount] = useState(0)
  const [processingImport, setProcessingImport] = useState(false)
  const [processingExport, setProcessingExport] = useState(false)
  const [processingTemplate, setProcessingTemplate] = useState(false)
  const [sortOrder, setSortOrder] = useState(null)
  const [sortField, setSortField] = useState(null)

  const retrieve = async (queryParams = { limit, offset }) => {
    setLoading(true)
    await retrieveList(siteStore.id, auth.userData.access_token, queryParams)
      .then(({ data }) => {
        setCount(data.count)
        data.results && setItems(data.results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Networks", error.message)]))
      })
      .finally(() => setLoading(false))
  }

  const getFilterValues = async () => {
    setLoadingFilters(true)
    await getAllValues(siteStore.id, auth.userData.access_token)
      .then(response => {
        setFilterValues(response.data)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        error.response && error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Networks", message)]))
      })
      .finally(() => setLoadingFilters(false))
  }

  const onSort = sortEvent => {
    setSortOrder(sortEvent.sortOrder)
    setSortField(sortEvent.sortField)
  }

  const columns = [
    { field: "type", header: t("Type"), sortable: true },
    { field: "ip_address_range", header: t("IP Range"), sortable: true },
    { field: "name", header: t("Zone name") },
    { field: "description", header: t("Description") }
  ]

  const columnsFilter = [
    { field: "type", header: t("Type") },
    { field: "space_name", header: t("Space") },
    { field: "is_bacnet", header: t("Bacnet") },
    { field: "ip_address_range", header: t("IP addres range") },
    { field: "bacnet_network_number", header: t("Bacnet number") }
  ]

  const getDetailUrl = networkId =>
    `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/networks/${networkId}/`

  const getDeleteUrl = networkId =>
    `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/networks/${networkId}/delete`

  const onRowClick = networkId => {
    navigate(getDetailUrl(networkId))
  }

  const getExport = async function () {
    setProcessingExport(true)
    await exportNetworks(siteStore.id, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `networks_${datetimeStr()}.csv`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Networks", error.message)]))
      })
      .finally(() => {
        setProcessingExport(false)
      })
  }

  const getTemplate = async function () {
    setProcessingTemplate(true)
    await exportTemplate(0, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `template_networks_${datetimeStr()}.csv`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Networks", error.message)]))
      })
      .finally(() => {
        setProcessingTemplate(false)
      })
  }

  const postImport = async function (target) {
    const file = target.files[0]

    if (file) {
      setProcessingImport(true)
      await importNetwork(siteStore.id, file, auth.userData.access_token)
        .then(async response => {
          dispatch(addNotifications([successNotification("Networks", response.data)]))
          setLoading(true)
          await retrieve()
          setLoading(false)
        })
        .catch(error => {
          if (
            error.response &&
            error.response.headers &&
            error.response.headers["content-type"] === "text/csv"
          ) {
            fileDownload(error.response.data, `ERROR_networks_${datetimeStr()}.csv`)
            dispatch(
              addNotifications([
                errorNotification(
                  "Networks",
                  t(
                    "An error occured during the import, see more details in the error report file."
                  )
                )
              ])
            )
          } else {
            dispatch(addNotifications([errorNotification("Networks", error.message)]))
          }
        })
        .finally(() => {
          setProcessingImport(false)
          target.value = null
        })
    }
  }

  const menuEntries = [
    {
      label: t("Import"),
      icon: processingImport ? "fa-solid fa-spinner fa-spin" : "fa-solid fa-file-import",
      disabled: processingImport,
      command: () => hiddenFileInput && hiddenFileInput.current.click()
    },
    {
      label: t("Export"),
      icon: processingExport ? "fa-solid fa-spinner fa-spin" : "fa-solid fa-file-export",
      disabled: processingExport,
      command: getExport
    },
    {
      label: t("Template"),
      icon: processingTemplate ? "fa-solid fa-spinner fa-spin" : "fa-solid fa-file",
      disabled: processingTemplate,
      command: getTemplate
    }
  ]

  const menuFilters = { columnsFilter, filters, setFilters, filterValues }

  useEffect(() => {
    if (siteStore.id && organizationStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
      getFilterValues()
    }
  }, [siteStore.id, organizationStore.id])

  const queryParams = () => {
    return {
      ...filters,
      limit,
      offset,
      ordering: sortField && `${sortOrder > 0 ? "" : "-"}${sortField}`
    }
  }

  useEffect(() => {
    siteStore.id && retrieve(queryParams())
  }, [limit, offset])

  useEffect(() => {
    siteStore.id && (offset ? setOffset(REQUEST_DEFAULT_OFFSET) : retrieve(queryParams()))
  }, [filters, sortField, sortOrder])

  return (
    <>
      <ListActions
        entries={menuEntries}
        filterBackend={true}
        filters={menuFilters}
        loading={loadingFilters}
      >
        <input
          className="hidden"
          ref={hiddenFileInput}
          type="file"
          onChange={e => postImport(e.target)}
        />
        <Link
          to={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/networks/create/`}
        >
          <Button
            rounded
            severity="info"
            icon="fa-regular fa-plus"
            label={t("Create")}
          />
        </Link>
      </ListActions>

      <ListLayout
        columns={columns}
        value={items}
        objectName={t("Networks")}
        loading={loading}
        filters={filters}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        bulkDeleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/networks/multi-delete/?${selected.map(item => `id=${item.id}`).join("&")}`}
        onRowClick={onRowClick}
        handleSelection={setSelected}
        offset={offset}
        limit={limit}
        setOffset={setOffset}
        setLimit={setLimit}
        count={count}
        onSort={onSort}
        sortOrder={sortOrder}
        sortField={sortField}
      />
    </>
  )
}
