import WeatherZoneRoutes from "./weather-zone/routes"
import EdgeBlockTemplateRoutes from "/apps/configuration/edge-block-template/routes"
import EnergyCompanyRoutes from "/apps/configuration/energy-company/routes"
import EquipmentTypeRoutes from "/apps/configuration/equipment-type/routes"
import GatewayRoutes from "/apps/configuration/gateway/routes"
import ParameterRoutes from "/apps/configuration/parameter/routes"
import { updateSideBar } from "/utils/store/sidebar"
import * as React from "react"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Route, Routes } from "react-router-dom"

export default function ConfigurationRoutes() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "parameters", siteId: null }))
  }, [])

  return (
    <Routes>
      <Route
        path="gateways/*"
        element={<GatewayRoutes />}
      />
      <Route
        path="units-and-quantities/*"
        element={<ParameterRoutes />}
      />
      <Route
        path="energy-company/*"
        element={<EnergyCompanyRoutes />}
      />
      <Route
        path="edge-block-templates/*"
        element={<EdgeBlockTemplateRoutes />}
      />
      <Route
        path="equipment-types/*"
        element={<EquipmentTypeRoutes />}
      />
      <Route
        path="weather-zones/*"
        element={<WeatherZoneRoutes />}
      />
    </Routes>
  )
}
