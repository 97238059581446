import axios from "axios"
import { config } from "/utils/api"

export async function retrieveList(token, queryParams) {
  return await axios.get(
    `${window.BACKEND_URL}/optimization/template_edge_blocks/`,
    config({ token, queryParams })
  )
}

export async function retrieve(id, token) {
  return await axios.get(
    `${window.BACKEND_URL}/optimization/template_edge_blocks/${id}`,
    config({ token })
  )
}

export async function add(data, token) {
  return await axios.post(
    `${window.BACKEND_URL}/optimization/template_edge_blocks/`,
    data,
    config({ token })
  )
}

export async function update(id, data, token) {
  return await axios.put(
    `${window.BACKEND_URL}/optimization/template_edge_blocks/${id}/`,
    data,
    config({ token })
  )
}

export async function remove(id, token) {
  return await axios.delete(
    `${window.BACKEND_URL}/optimization/template_edge_blocks/${id}`,
    config({ token })
  )
}

export async function getAllValues(token) {
  return await axios.get(
    `${window.BACKEND_URL}/optimization/template_edge_blocks/allvalues`,
    config({ token })
  )
}
