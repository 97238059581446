import { updateSideBar } from "/utils/store/sidebar"
import { Cards } from "@software-engineering/hivolution-frontend-utils"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Route, Routes, useParams } from "react-router-dom"
import ConfigurationRoutes from "./configuration/routes"
import FlexibilityRoutes from "./flex/routes"
import { organization } from "/utils/store/organization"

function OptimizationHome() {
  const organizationStore = useSelector(organization)
  const { siteId } = useParams()
  const { t } = useTranslation(["assets"])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "site", siteId: siteId }))
  }, [])

  return (
    <Cards
      items={[
        {
          icon: "fa-solid fa-gear",
          url: `/organizations/${organizationStore.id}/sites/${siteId}/optimization/configuration/points/`,
          title: t("Configuration")
        },
        {
          icon: "fa-solid fa-random",
          url: `/organizations/${organizationStore.id}/sites/${siteId}/optimization/flexibility/`,
          title: t("Flexibility")
        }
      ]}
    />
  )
}

export default function OptimizationRoutes() {
  return (
    <Routes>
      <Route
        index
        path="/"
        element={<OptimizationHome />}
      />
      <Route
        path="/configuration/*"
        element={<ConfigurationRoutes />}
      />
      <Route
        path="/flexibility/*"
        element={<FlexibilityRoutes />}
      />
    </Routes>
  )
}
