import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ListLayout, Loader } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { create, retrieveList, update } from "../api"
import BillResume from "./BillResume"
import { Button } from "primereact/button"
import BillForm from "./BillForm"

const EMPTY_BILL = {
  start_validity: "",
  end_validity: "",
  currency: "",
  prices: [{ name: "", price_type: "FIX", value: null }]
}

const MAPPING_CURRENCY = {
  FR: "EUR",
  SG: "SGD",
  MY: "MYR",
  TH: "THB"
}

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["energy"])
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [currentBill, setCurrentBill] = useState(null)
  const [showForm, setShowForm] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [data, setData] = useState({
    start_validity: "",
    end_validity: "",
    prices: []
  })

  const submit = async e => {
    e.preventDefault()
    const dataToSend = {
      ...data,
      currency: MAPPING_CURRENCY[siteStore.country],
      start_validity: `${data.start_validity}+02`,
      end_validity: `${data.end_validity}+02`
    }
    setLoading(true)
    console.log(dataToSend)
    console.log(isUpdate)
    if (items.length === 0) {
      await create(siteStore.id, dataToSend, auth.userData.access_token)
        .then(() => {
          dispatch(addNotifications(successNotification("Bill", t("Bill has been created"))))
          cancelForm()
          retrieve()
        })
        .catch(error => {
          setLoading(false)
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })
    } else {
      const billsList = items.reverse()

      if (isUpdate) {
        if (billsList.length > 1) {
          if (dataToSend.start_validity < billsList[billsList.length - 2].end_validity) {
            dispatch(
              addNotifications(
                errorNotification(
                  "Bill",
                  t("Bill's start validity should be later than the previous billing date")
                )
              )
            )
            setLoading(false)
            return
          }
        }
        billsList[billsList.length - 1] = dataToSend
      } else {
        if (dataToSend.start_validity < billsList[billsList.length - 1].end_validity) {
          dispatch(
            addNotifications(
              errorNotification(
                "Bill",
                t("Bill's start validity should be later than the previous billing date")
              )
            )
          )
          setLoading(false)
          return
        }
        billsList.push(dataToSend)
      }
      await update(siteStore.id, { bills: billsList }, auth.userData.access_token)
        .then(() => {
          dispatch(
            addNotifications(
              successNotification(
                "Bill",
                isUpdate ? t("Bill has been updated") : t("Bill has been created")
              )
            )
          )
          cancelForm()
          retrieve()
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
          setLoading(false)
        })
    }
  }

  const retrieve = async () => {
    setLoading(true)
    await retrieveList(siteStore.id, auth.userData.access_token)
      .then(({ data }) => {
        data.bills &&
          setItems(
            data.bills.reverse().map((bill, index) => {
              return {
                ...bill,
                id: index
              }
            })
          )
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setLoading(false))
  }

  const columns = [
    { field: "start_validity", header: t("Start validity"), sortable: true },
    { field: "end_validity", header: t("End validity"), sortable: true }
  ]

  const onRowClick = objectId => {
    setCurrentBill(items.find(item => item.id === objectId))
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
    siteStore.id && retrieve()
  }, [siteStore.id])

  useEffect(() => {
    if (items.length > 0) {
      setCurrentBill(items[0])
    }
  }, [items])

  const updateForm = () => {
    setShowForm(true)
    setIsUpdate(true)
    setData({
      ...items[0],
      start_validity: items[0].start_validity.split("+")[0],
      end_validity: items[0].end_validity.split("+")[0]
    })
  }

  const createForm = () => {
    setShowForm(true)
    setData({
      ...EMPTY_BILL,
      start_validity: items[0].end_validity.split("+")[0]
    })
  }

  const cancelForm = () => {
    setShowForm(false)
    setIsUpdate(false)
    setData({
      ...EMPTY_BILL
    })
  }

  console.log(data)

  return !loading ? (
    <section className="list-section">
      {!showForm && (
        <div className="bill-action-buttons">
          {!currentBill && (
            <p className="text-center">
              {t(
                "There is no bill configuration on this site, click on the button below to create one."
              )}
            </p>
          )}
          <div className="ml-auto">
            {items.length > 0 && (
              <Button
                rounded
                onClick={() => updateForm()}
              >
                {t("Update last bill")}
              </Button>
            )}
            <Button
              rounded
              className="ml-4"
              onClick={() => createForm()}
            >
              {t("Add bill")}
            </Button>
          </div>
        </div>
      )}

      {showForm && (
        <BillForm
          data={data}
          setData={setData}
          submit={submit}
          cancelForm={cancelForm}
        />
      )}

      {currentBill && !showForm && <BillResume bill={currentBill} />}
      <ListLayout
        columns={columns}
        value={items}
        loading={loading}
        onRowClick={onRowClick}
      />
    </section>
  ) : (
    <Loader />
  )
}
