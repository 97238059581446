import { GatewayRoutesContext } from "/apps/connectivity/gateway/routes"
import { updateInterface } from "/apps/connectivity/network-interface/api"
import {
  NetworkInterface,
  NetworkInterfaceOverview
} from "/apps/connectivity/network-interface/models"
import { NetworkInterfaceRoutesContext } from "/apps/connectivity/network-interface/routes"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const auth = useAuth()
  const dispatch = useDispatch()
  const { networkInterface, retrieveDetail } = useContext(NetworkInterfaceRoutesContext)
  const { gateway } = useContext(GatewayRoutesContext)
  const { t } = useTranslation(["assets"])
  const [data, setData] = useState(null)
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [processing, setProcessing] = useState(false)

  const updateObject = async e => {
    e.preventDefault()
    setProcessing(true)
    await updateInterface(siteStore.id, networkInterface.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(
          addNotifications([
            successNotification("Network interface", t("The network interface has been updated."))
          ])
        )
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        if (error.response.data) {
        }
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        error.response && error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Network interface", message)]))
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    networkInterface &&
      gateway &&
      gateway.id &&
      siteStore.id &&
      organizationStore.id &&
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id, organizationStore.id, gateway, networkInterface])

  useEffect(() => {
    networkInterface && networkInterface.id && setData(networkInterface)
  }, [networkInterface])

  return (
    networkInterface && (
      <>
        <div className="base-content-title">
          <h2>{networkInterface.context_name}</h2>
        </div>
        <OverView
          object={networkInterface}
          model={NetworkInterface()}
          overviewModel={NetworkInterfaceOverview()}
          updateObject={updateObject}
          data={data}
          setData={setData}
          errors={errors}
          edit={edit}
          setEdit={setEdit}
          processing={processing}
          deleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/network-interfaces/${networkInterface.id}/delete/`}
        />
      </>
    )
  )
}
