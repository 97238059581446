import {
  exportCircuitBreakers,
  exportCircuitBreakersRelations,
  getAllValues,
  importCircuitBreakers,
  importCircuitBreakersRelations,
  retrieveList
} from "/apps/assets/electrical/circuit-breaker/api"
import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "/utils/constants/api"
import { datetimeStr } from "/utils/datetime"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site.js"
import { ListActions, ListLayout } from "@software-engineering/hivolution-frontend-utils"
import fileDownload from "js-file-download"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [items, setItems] = useState([])
  const [filters, setFilters] = useState({})
  const [filterValues, setFilterValues] = useState(null)
  const [loadingFilters, setLoadingFilters] = useState(false)
  const [count, setCount] = useState(0)
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset, setOffset] = useState(REQUEST_DEFAULT_OFFSET)
  const [loading, setLoading] = useState(true)
  const hiddenFileInput = useRef(null)
  const hiddenFileInputRelations = useRef(null)
  const [sortOrder, setSortOrder] = useState(null)
  const [sortField, setSortField] = useState(null)
  const [processingImport, setProcessingImport] = useState(false)
  const [processingExport, setProcessingExport] = useState(false)
  const [processingTemplate, setProcessingTemplate] = useState(false)

  const columns = [
    { field: "name", header: t("Name"), sortable: true },
    { field: "cabinet", header: t("Cabinet"), sortable: true },
    { field: "nominal_intensity", header: t("Nominal intensity"), sortable: true },
    { field: "equipments_powered_count", header: t("Equipments powered"), sortable: true }
  ]

  const columnsFilter = [
    { field: "name", header: t("Name") },
    { field: "cabinet", header: t("Cabinet") },
    { field: "nominal_intensity", header: t("Nominal intensity") },
    { field: "parent_circuit_breaker_name", header: t("Parent circuit breaker") },
    { field: "equipments_powered_count", header: t("Nominal Equipments powered") },
    { field: "is_measured_by", header: t("Measured by") },
    { field: "space_name", header: t("Space") }
  ]

  const getDetailUrl = objectId =>
    `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/electrical/circuit-breakers/${objectId}/`

  const getDeleteUrl = objectId =>
    `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/electrical/circuit-breakers/${objectId}/delete/`

  const onRowClick = objectId => navigate(getDetailUrl(objectId))

  const getExport = async () => {
    setProcessingExport(true)
    await exportCircuitBreakers(siteStore.id, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `circuit_breaker_${datetimeStr()}.csv`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => {
        setProcessingExport(false)
      })
  }

  const getExportRelations = async () => {
    setProcessingExport(true)
    await exportCircuitBreakersRelations(siteStore.id, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `circuit_breaker_relations_${datetimeStr()}.csv`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => {
        setProcessingExport(false)
      })
  }

  const getTemplate = async () => {
    setProcessingTemplate(true)
    await exportCircuitBreakers(0, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `template_circuit_breaker_${datetimeStr()}.csv`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => {
        setProcessingTemplate(false)
      })
  }

  const postImport = async ({ target }) => {
    const file = target.files[0]
    if (file) {
      setProcessingImport(true)
      await importCircuitBreakers(siteStore.id, file, auth.userData.access_token)
        .then(response => {
          dispatch(addNotifications([successNotification("Circuit breakers", response.data)]))
          retrieve()
        })
        .catch(error => {
          if (
            error.response &&
            error.response.headers &&
            error.response.headers["content-type"] === "text/csv"
          ) {
            fileDownload(error.response.data, `ERROR_circuit_breakers_${datetimeStr()}.csv`)
            dispatch(
              addNotifications([
                errorNotification(
                  "Error import",
                  t(
                    "An error occured during the import, see more details in the error report file."
                  )
                )
              ])
            )
          } else {
            dispatch(addNotifications([errorNotification("Error import", error.message)]))
          }
        })
        .finally(() => {
          setProcessingImport(false)
          target.value = null
        })
    }
  }

  const postImportRelations = async ({ target }) => {
    const file = target.files[0]
    if (file) {
      setProcessingImport(true)
      await importCircuitBreakersRelations(siteStore.id, file, auth.userData.access_token)
        .then(async response => {
          dispatch(
            addNotifications([successNotification("Circuit breaker relations", response.data)])
          )
          setLoading(true)
          await retrieve()
          setLoading(false)
        })
        .catch(error => {
          if (
            error.response &&
            error.response.headers &&
            error.response.headers["content-type"] === "text/csv"
          ) {
            fileDownload(error.response.data, `ERROR_circuit_breakers_${datetimeStr()}.csv`)
            dispatch(
              addNotifications([
                errorNotification(
                  "Error import",
                  t(
                    "An error occured during the import, see more details in the error report file."
                  )
                )
              ])
            )
          } else {
            dispatch(addNotifications([errorNotification("Error import", error.message)]))
          }
        })
        .finally(() => {
          setProcessingImport(false)
          target.value = null
        })
    }
  }

  const retrieve = async (queryParams = { limit, offset }) => {
    setLoading(true)
    await retrieveList(siteStore.id, auth.userData.access_token, queryParams)
      .then(({ data }) => {
        if (data) {
          setCount(data.count)
          setItems(data.results)
        }
      })
      .catch(error => {
        console.log(error)
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getFilterValues = async () => {
    setLoadingFilters(true)
    await getAllValues(siteStore.id, auth.userData.access_token)
      .then(response => {
        setFilterValues(response.data)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification("Circuit breakers", message)]))
      })
      .finally(() => setLoadingFilters(false))
  }

  const menuEntries = [
    {
      label: t("Import"),
      icon: processingImport ? "fa-solid fa-spinner fa-spin" : "fa-solid fa-file-import",
      disabled: processingImport,
      items: [
        {
          label: t("Circuit breakers"),
          command: () => hiddenFileInput && hiddenFileInput.current.click()
        },
        {
          label: t("Circuit breakers relations"),
          command: () => hiddenFileInputRelations && hiddenFileInputRelations.current.click()
        }
      ]
    },
    {
      label: t("Export"),
      icon: processingExport ? "fa-solid fa-spinner fa-spin" : "fa-solid fa-file-export",
      disabled: processingExport,
      items: [
        { label: t("Circuit breakers"), command: getExport },
        { label: t("Circuit breakers relations"), command: getExportRelations }
      ]
    },
    {
      label: t("Template"),
      icon: processingTemplate ? "fa-solid fa-spinner fa-spin" : "fa-solid fa-file",
      command: getTemplate
    }
  ]

  const menuFilters = { columnsFilter, filters, setFilters, filterValues }

  const onSort = sortEvent => {
    setSortOrder(sortEvent.sortOrder)
    setSortField(sortEvent.sortField)
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  const queryParams = () => {
    return {
      ...filters,
      limit,
      offset,
      ordering: sortField && `${sortOrder > 0 ? "" : "-"}${sortField}`
    }
  }

  useEffect(() => {
    if (siteStore.id) {
      getFilterValues()
    }
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id && retrieve(queryParams())
  }, [limit, offset])

  useEffect(() => {
    siteStore.id && (offset ? setOffset(REQUEST_DEFAULT_OFFSET) : retrieve(queryParams()))
  }, [filters, sortField, sortOrder])

  return (
    <>
      <ListActions
        entries={menuEntries}
        filterBackend={true}
        filters={menuFilters}
        loading={loadingFilters}
      >
        <input
          className="hidden"
          ref={hiddenFileInput}
          type="file"
          onChange={postImport}
        />

        <input
          className="hidden"
          ref={hiddenFileInputRelations}
          type="file"
          onChange={postImportRelations}
        />

        <Link
          to={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/electrical/circuit-breakers/create/`}
        >
          <Button
            rounded
            severity="info"
            icon="fa-regular fa-plus"
            label={t("Create")}
          />
        </Link>
      </ListActions>
      <ListLayout
        columns={columns}
        value={items}
        objectName={t("Circuit breakers")}
        loading={loading}
        filters={filters}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
        limit={limit}
        offset={offset}
        setOffset={setOffset}
        setLimit={setLimit}
        count={count}
        onSort={onSort}
        sortOrder={sortOrder}
        sortField={sortField}
      />
    </>
  )
}
