import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "/utils/constants/api"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ListActions, ListLayout } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { getAllValues, retrieveList } from "../api"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["event"])
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})
  const [filterValues, setFilterValues] = useState(null)
  const [loadingFilters, setLoadingFilters] = useState(false)
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset, setOffset] = useState(REQUEST_DEFAULT_OFFSET)
  const [count, setCount] = useState(0)
  const [sortOrder, setSortOrder] = useState(null)
  const [sortField, setSortField] = useState(null)

  const typeLabels = {
    BULK_IMPORT_NETWORK: t("Bulk import network"),
    BULK_IMPORT_BACNET_NODE: t("Bulk import Bacnet node"),
    BULK_IMPORT_MODBUS_NODE: t("Bulk import Modbus node"),
    BULK_IMPORT_SM_NODE: t("Bulk import Sm node"),
    BULK_IMPORT_CAREL_NODE: t("Bulk import Carel node"),
    BULK_IMPORT_EQUIPMENT: t("Bulk import equipment"),
    BULK_IMPORT_BACNET_POINT: t("Bulk import Bacnet point"),
    BULK_IMPORT_MODBUS_POINT: t("Bulk import Modbus point"),
    BULK_IMPORT_SM_POINT: t("Bulk import SM point"),
    BULK_IMPORT_OPCUA_POINT: t("Bulk import OPCUA point"),
    BULK_IMPORT_CAREL_POINT: t("Bulk import Carel point"),
    BULK_IMPORT_STATE_VALUE: t("Bulk import state values"),
    BULK_IMPORT_SPACE: t("Bulk import space"),
    BULK_IMPORT_POINT: t("Bulk import point"),
    BULK_IMPORT_CIRCUIT_BREAKER: t("Bulk import circuit breaker"),
    BULK_IMPORT_CIRCUIT_BREAKER_RELATIONS: t("Bulk import circuit breaker relations")
  }

  const stateLabels = {
    IN_PROGRESS: t("In progress"),
    DONE: t("Done"),
    FAILED: t("Failed")
  }

  const columns = [
    {
      field: "request_type",
      header: t("Request type"),
      sortable: true,
      processValue: value => typeLabels[value]
    },
    {
      field: "state",
      header: t("State"),
      sortable: true,
      processValue: value => stateLabels[value]
    },
    { field: "user", header: t("User"), sortable: true },
    {
      field: "start_time",
      header: t("Start date"),
      sortable: true,
      processValue: value => {
        return new Date(value).toLocaleString()
      }
    },
    {
      field: "end_time",
      header: t("End date"),
      sortable: true,
      processValue: value => {
        return new Date(value).toLocaleString()
      }
    },
    { field: "info", header: t("Infos") }
  ]

  const columnsFilter = [
    { field: "request_type", header: t("Request type") },
    { field: "state", header: t("State") },
    { field: "user", header: t("User") },
    { field: "start_time", header: t("Start date") },
    { field: "end_time", header: t("End date") }
  ]

  const retrieve = async (queryParams = { limit, offset }) => {
    setLoading(true)

    await retrieveList(siteStore.id, auth.userData.access_token, queryParams)
      .then(({ data }) => {
        data.results && setItems(data.results)
        data.count && setCount(data.count)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setLoading(false))
  }

  const getFilterValues = async () => {
    setLoadingFilters(true)
    await getAllValues(siteStore.id, auth.userData.access_token)
      .then(response => {
        setFilterValues({
          ...response.data,
          start_time: Array.from(new Set(response.data.start_time.map(time => time.split(" ")[0]))),
          end_time: Array.from(new Set(response.data.end_time.map(time => time.split(" ")[0])))
        })
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setLoadingFilters(false))
  }

  const onSort = sortEvent => {
    setSortOrder(sortEvent.sortOrder)
    setSortField(sortEvent.sortField)
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
    siteStore.id && getFilterValues()
  }, [siteStore.id])

  const queryParams = () => {
    return {
      ...filters,
      limit,
      offset,
      ordering: sortField && `${sortOrder > 0 ? "" : "-"}${sortField}`
    }
  }

  useEffect(() => {
    siteStore.id && (offset ? setOffset(REQUEST_DEFAULT_OFFSET) : retrieve(queryParams()))
  }, [filters, sortField, sortOrder])

  useEffect(() => {
    siteStore.id && retrieve(queryParams())
  }, [limit, offset])

  const menuFilters = { columnsFilter, filters, setFilters, filterValues }

  return (
    <section className="list-section">
      <ListActions
        filterBackend={true}
        filters={menuFilters}
        loading={loadingFilters}
      ></ListActions>

      <ListLayout
        columns={columns}
        value={items}
        objectName={t("Spaces")}
        loading={loading}
        filters={filters}
        offset={offset}
        limit={limit}
        setOffset={setOffset}
        setLimit={setLimit}
        count={count}
        onSort={onSort}
        sortOrder={sortOrder}
        sortField={sortField}
      />
    </section>
  )
}
