import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputText } from "primereact/inputtext"

import { commonsFields, commonsOverview } from "./commons"

export const ExternalAPI = (nodes, loadingNodes) => {
  return {
    ...commonsFields(nodes, loadingNodes),
    ...{
      external_data_type: {
        label: i18n.t("External point type", { ns: "assets" }),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "sensinov", label: i18n.t("Sensinov", { ns: "assets" }) },
            { value: "mysirius", label: i18n.t("MySirius", { ns: "assets" }) },
            { value: "sigfox", label: i18n.t("Sigfox", { ns: "assets" }) },
            { value: "grdf", label: i18n.t("GRDF", { ns: "assets" }) },
            { value: "koovea", label: i18n.t("Koovea", { ns: "assets" }) },
            { value: "mobilealerts", label: i18n.t("MobileAlerts", { ns: "assets" }) }
          ]
        }
      },
      external_id: {
        label: i18n.t("External ID", { ns: "assets" }),
        field: InputText,
        options: {}
      }
    }
  }
}

export const ExternalAPIOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      external_data_type: { label: i18n.t("External point type", { ns: "assets" }) },
      external_id: { label: i18n.t("External ID", { ns: "assets" }) }
    }
  }
}
