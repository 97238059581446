import { getGateways } from "/apps/energy/contract/api"
import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import {
  BaseLayout,
  ButtonWithLoader,
  FormContent
} from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"

import { Dropdown } from "primereact/dropdown"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { add, getSpaces } from "../api"
import { Gateway } from "../models/GatewayBox"
import { Grdf } from "../models/Grdf"
import { Koovea } from "../models/Koovea"
import { MobileAlerts } from "../models/MobileAlerts"
import { MySirius } from "../models/MySiruis"
import { Sensinov } from "../models/Sensinov"
import { Sigfox } from "../models/Sigfox"

const SelectType = ({ data, setData }) => {
  const { t } = useTranslation(["assets"])

  const [types, setTypes] = useState([
    { value: "box", label: "BebryteBox" },
    { value: "sensinov", label: "Sensinov" },
    { value: "mysirius", label: "MySirius" },
    { value: "sigfox", label: "Sigfox" },
    { value: "grdf", label: "GRDF" },
    { value: "koovea", label: "Koovea" },
    { value: "mobilealerts", label: "Mobile Alerts" }
  ])
  const [type, setType] = useState(null)
  const siteStore = useSelector(site)
  const auth = useAuth()

  useEffect(() => {
    const getGW = async () => {
      await getGateways(siteStore.id, auth.userData.access_token).then(({ data }) => {
        const found = data.results.find(gateway => gateway.virtual_machine_type === "sensinov")
        if (found) {
          setTypes([
            { value: "box", label: "BebryteBox" },
            { value: "mysirius", label: "MySirius" },
            { value: "sigfox", label: "Sigfox" },
            { value: "grdf", label: "GRDF" },
            { value: "koovea", label: "Koovea" },
            { value: "mobilealerts", label: "Mobile Alerts" }
          ])
        }
      })
    }
    getGW()
  }, [siteStore.id])
  const updateType = value => {
    setType(value)
    if (value === "box") {
      setData({
        ...data,
        type: "box"
      })
    } else {
      setData({
        ...data,
        type: "virtualmachine",
        virtual_machine_type: value
      })
    }
  }

  return (
    <div className="field-container">
      <div className="field dropdown2">
        <label className="label label-required">{t("Type")}</label>
        <div className="field-input">
          <Dropdown
            options={types}
            value={type}
            onChange={e => updateType(e.value)}
            placeholder={t("Choose a type")}
          />
        </div>
      </div>
    </div>
  )
}

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [hardwares, setHardwares] = useState([])
  const [spaces, setSpaces] = useState([])
  const [processing, setProcessing] = useState(false)
  const [loadingHardwares, setLoadingHardwares] = useState(false)
  const [loadingSpaces, setLoadingSpaces] = useState(false)

  const goBack = e => {
    e && e.preventDefault()
    navigate(-1)
  }

  const getHW = async () => {
    setLoadingHardwares(true)
    // TODO: remove when the api will no longer paginate
    await paginatedRequest("connectivity/hardwares/", auth.userData.access_token)()
      .then(results => {
        setHardwares(results)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setLoadingHardwares(false))

    // TODO: uncomment when the API will no longer paginate
    /* await getHardwares(auth.userData.access_token).then(response => {
      setHardwares(response.data.results)
    }).catch(error => {
      dispatch(addNotifications([errorNotification("Error", error.message)]))
    }) */
  }

  const getSP = async () => {
    setLoadingSpaces(true)
    await getSpaces(siteStore.id, auth.userData.access_token)
      .then(({ data }) => {
        setSpaces(data.results)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setLoadingSpaces(false))
  }

  const create = async function (e) {
    e.preventDefault()
    setProcessing(true)

    await add(siteStore.id, data, auth.userData.access_token)
      .then(response => {
        dispatch(
          addNotifications([successNotification("Create", t("The gateway has been created."))])
        )
        navigate(
          `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${response.data.id}`
        )
      })
      .catch(error => {
        if (error.response.data) {
          setErrors(error.response.data)
        }
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setProcessing(false))
  }

  const getModel = (gatewayType, gatewaySubType) => {
    switch (gatewayType) {
      case "box":
        return Gateway(hardwares, spaces, loadingHardwares, loadingSpaces, gatewaySubType)
      case "virtualmachine":
        return getModelBySubType(gatewaySubType)
      default:
        return {}
    }
  }

  const getModelBySubType = gatewaySubType => {
    switch (gatewaySubType) {
      case "sensinov":
        return Sensinov(spaces, loadingSpaces)
      case "mysirius":
        return MySirius(spaces, loadingSpaces)
      case "sigfox":
        return Sigfox(spaces, loadingSpaces)
      case "grdf":
        return Grdf(spaces, loadingSpaces)
      case "koovea":
        return Koovea(spaces, loadingSpaces)
      case "mobilealerts":
        return MobileAlerts(spaces, loadingSpaces)
      default:
        return {}
    }
  }

  useEffect(() => {
    if (siteStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
    }
  }, [siteStore.id])

  useEffect(() => {
    if (data.type === "box") {
      getHW()
      getSP()
      setData({ ...data, name: `gatewayBox-${siteStore.name}` })
    } else if (data.type === "virtualmachine") {
      getSP()
      setData({ ...data, name: `gatewayVirtualMachine-${siteStore.name}` })
    }
  }, [data.type])

  return (
    data && (
      <BaseLayout title={t("Create gateway")}>
        <form
          className="form-view"
          onSubmit={create}
        >
          <SelectType
            data={data}
            setData={setData}
          />
          {data.type && (
            <FormContent
              model={getModel(data.type, data.virtual_machine_type)}
              data={data}
              setData={setData}
              errors={errors}
            />
          )}
          <div className="action-buttons-wrapper">
            <Button
              rounded
              outlined
              severity="secondary"
              label={t("Cancel")}
              disabled={processing}
              icon="fa-solid fa-xmark"
              onClick={goBack}
            />
            <ButtonWithLoader
              rounded
              severity="success"
              label={t("Save")}
              type="submit"
              icon="fa-solid fa-floppy-disk"
              disabled={processing}
            />
          </div>
        </form>
      </BaseLayout>
    )
  )
}
