import axios from "axios"
import { config } from "/utils/api"

const BASE_URL = (siteId, spaceConstraintId) =>
  `${window.BACKEND_URL}/operation/sites/${siteId}/constraints/${spaceConstraintId}/constraints_details/`

export async function retrieveList(siteId, spaceConstraintId, token) {
  return await axios.get(BASE_URL(siteId, spaceConstraintId), config({ token }))
}

export async function retrieve(siteId, spaceConstraintId, spaceConstraintDetailId, token) {
  return await axios.get(
    `${BASE_URL(siteId, spaceConstraintId)}${spaceConstraintDetailId}/`,
    config({ token })
  )
}

export async function add(siteId, spaceConstraintId, data, token) {
  return await axios.post(BASE_URL(siteId, spaceConstraintId), data, config({ token }))
}

export async function update(siteId, spaceConstraintId, spaceConstraintDetailId, data, token) {
  return await axios.put(
    `${BASE_URL(siteId, spaceConstraintId)}${spaceConstraintDetailId}/`,
    data,
    config({ token })
  )
}

export async function remove(siteId, spaceConstraintId, spaceConstraintDetailId, token) {
  return await axios.delete(
    `${BASE_URL(siteId, spaceConstraintId)}${spaceConstraintDetailId}/`,
    config({ token })
  )
}
