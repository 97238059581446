import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import {
  BaseLayout,
  ButtonWithLoader,
  FormContent
} from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  add,
  getCarelAllValues,
  getCarelNodes,
  getSMAllValues,
  getSMNodes,
  getSpaces
} from "../api"
import { BacnetNode } from "../models/bacnet"
import { CarelNode } from "../models/carel"
import { CarelNetworkNode } from "../models/carelnetwork"
import { ExternalAPINode } from "../models/externalapi"
import { ModbusNode } from "../models/modbus"
import { OPCUANode } from "../models/opcua"
import { SMNode } from "../models/sm"
import { SMControllerNode } from "../models/smcontroller"
import { SMIONode } from "../models/smio"
import { SMMeterNode } from "../models/smmeter"

const SelectType = ({ data, setData }) => {
  const { t } = useTranslation(["assets"])

  const [type, setType] = useState(null)
  const types = [
    { value: "modbusnode", label: "Modbus" },
    { value: "bacnetnode", label: "Bacnet" },
    { value: "smnetworknode", label: "AK-SM Network Node" },
    { value: "smnode", label: "AK-SM Node" },
    { value: "opcuanode", label: "OPCUA Node" },
    { value: "carelnode", label: "Carel Node" },
    { value: "carelnetworknode", label: "Carel Network Node" },
    { value: "externalapinode", label: "External API Node" }
  ]
  const [subTypeList, setSubTypeList] = useState([])
  const [subType, setSubType] = useState(null)
  const smnodeSubTypes = [
    { value: "smionode", label: "AK-SM IO Node" },
    { value: "smmeternode", label: "AK-SM Meter Node" },
    { value: "smcontrollernode", label: "AK-SM Controller Node" }
  ]

  const updateType = value => {
    setType(value)
    setSubType(null)

    if (value === "smnetworknode") {
      setSubTypeList(smnodeSubTypes)
    } else {
      setSubTypeList([])
    }
  }

  const updateSubType = value => {
    setSubType(value)
  }

  useEffect(() => {
    if (type === "smnetworknode" && subType) {
      setData({ ...data, ...{ type: subType } })
    } else {
      setData({ ...data, ...{ type: type } })
    }
  }, [type, subType])

  return (
    <>
      <div className="field-container">
        <div className="field dropdown2">
          <label className="label label-required">{t("Type")}</label>
          <div className="field-input">
            <Dropdown
              options={types}
              value={type}
              onChange={e => updateType(e.value)}
              placeholder={t("Choose a node type")}
            />
          </div>
        </div>
      </div>

      {subTypeList.length > 0 && (
        <div className="field-container">
          <div className="field dropdown2">
            <label className="label label-required">{t("Subtype")}</label>
            <div className="field-input">
              <Dropdown
                options={subTypeList}
                value={subType}
                onChange={e => updateSubType(e.value)}
                placeholder={t("Choose a sub type")}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [data, setData] = useState({ site: siteStore.id })
  const [errors, setErrors] = useState({})
  const [spaces, setSpaces] = useState([])
  const [processing, setProcessing] = useState(false)

  const goBack = e => {
    e && e.preventDefault()
    navigate(-1)
  }

  const retrieveSpaces = async () => {
    await getSpaces(siteStore.id, auth.userData.access_token)
      .then(({ data }) => {
        setSpaces(data.results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const retrieveCarelNodes = async queryParams => {
    return await getCarelNodes(siteStore.id, auth.userData.access_token, queryParams)
  }

  const getCarelValues = async () => {
    return await getCarelAllValues(siteStore.id, auth.userData.access_token)
  }

  const retrieveSMNodes = async queryParams => {
    return await getSMNodes(siteStore.id, auth.userData.access_token, queryParams)
  }

  const getSMValues = async () => {
    return await getSMAllValues(siteStore.id, auth.userData.access_token)
  }

  const create = async function (e) {
    e.preventDefault()

    setProcessing(true)

    if (data.type === "smnode" || data.type === "carelnode") {
      if (data.password !== data.confirm_password) {
        setErrors({
          password: t("Passwords must be the same"),
          confirm_password: t("Passwords must be the same")
        })
        dispatch(
          addNotifications([errorNotification("Automation node", t("Passwords must be the same"))])
        )
        return
      }
    }

    await add(siteStore.id, data, auth.userData.access_token)
      .then(response => {
        dispatch(
          addNotifications([
            successNotification("Automation node", t("The automation node has been created."))
          ])
        )
        navigate(
          `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/automation-nodes/${response.data.id}`
        )
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        error.response && error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Automation node", message)]))
      })
      .finally(() => setProcessing(false))
  }

  const getModel = nodeType => {
    switch (nodeType) {
      case "modbusnode":
        return ModbusNode(spaces)
      case "bacnetnode":
        return BacnetNode(spaces)
      case "smnode":
        return SMNode(spaces)
      case "smionode":
        return SMIONode(spaces, retrieveSMNodes, getSMValues)
      case "smcontrollernode":
        return SMControllerNode(spaces, retrieveSMNodes, getSMValues)
      case "smmeternode":
        return SMMeterNode(spaces, retrieveSMNodes, getSMValues)
      case "opcuanode":
        return OPCUANode(spaces)
      case "carelnode":
        return CarelNode(spaces)
      case "carelnetworknode":
        return CarelNetworkNode(spaces, retrieveCarelNodes, getCarelValues)
      case "externalapinode":
        return ExternalAPINode(spaces)
      default:
        return {}
    }
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (siteStore.id && organizationStore.id) {
      retrieveSpaces()
    }
  }, [siteStore.id, organizationStore.id])

  return (
    data && (
      <BaseLayout title={t("Create automation node")}>
        <form
          className="form-view"
          onSubmit={create}
        >
          <SelectType
            data={data}
            setData={setData}
          />
          {data.type && (
            <>
              <FormContent
                model={getModel(data.type)}
                data={data}
                setData={setData}
                errors={errors}
              />
              <div className="action-buttons-wrapper">
                <Button
                  rounded
                  outlined
                  severity="secondary"
                  label={t("Cancel")}
                  icon="fa-solid fa-xmark"
                  disabled={processing}
                  onClick={goBack}
                />
                <ButtonWithLoader
                  rounded
                  severity="success"
                  label={t("Save")}
                  type="submit"
                  icon="fa-solid fa-floppy-disk"
                  disabled={processing}
                />
              </div>
            </>
          )}
        </form>
      </BaseLayout>
    )
  )
}
