import axios from "axios"

function header(token) {
  return {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

export async function retrieveList(siteId, queryParams, token) {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/points/${queryParams}`,
    header(token)
  )
}

export async function getPointInfo(siteId, pointId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/optimization/sites/${siteId}/point/${pointId}/opti_summary/`,
    header(token)
  )
}
