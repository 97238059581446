import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { site } from "/utils/store/site"
import { Loader } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import * as React from "react"
import { createContext, useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, Route, Routes, useParams } from "react-router-dom"
import { SpaceConstraintRouteContext } from "../space-constraint/routes"
import { retrieve } from "./api"
import Create from "./components/Create"
import Delete from "./components/Delete"
import Detail from "./components/Detail"
import List from "./components/List"

const SpaceConstraintDetailRouteContext = createContext(null)

const SpaceConstraintDetailsContent = () => {
  const siteStore = useSelector(site)
  const auth = useAuth()
  const [spaceConstraintDetail, setSpaceConstraintDetail] = useState()
  const { spaceConstraintDetailId } = useParams()
  const dispatch = useDispatch()
  const { spaceConstraint } = useContext(SpaceConstraintRouteContext)

  const retrieveDetail = async () => {
    await retrieve(
      siteStore.id,
      spaceConstraint.id,
      spaceConstraintDetailId,
      auth.userData.access_token
    )
      .then(response => {
        setSpaceConstraintDetail(response.data)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
  }

  useEffect(() => {
    if (siteStore.id && spaceConstraint.id && spaceConstraintDetailId) {
      retrieveDetail()
    }
  }, [siteStore.id, spaceConstraint.id, spaceConstraintDetailId])
  return (
    <SpaceConstraintDetailRouteContext.Provider value={{ spaceConstraintDetail, retrieveDetail }}>
      {spaceConstraintDetail ? <Outlet /> : <Loader />}
    </SpaceConstraintDetailRouteContext.Provider>
  )
}

export { SpaceConstraintDetailRouteContext }
export default function SpaceConstraintDetailsRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="create"
        element={<Create />}
      />
      <Route
        path=":spaceConstraintDetailId"
        element={<SpaceConstraintDetailsContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
      </Route>
    </Routes>
  )
}
