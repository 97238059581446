import { CountryDropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputText } from "primereact/inputtext"

export const TSO = () => {
  return {
    name: {
      label: i18n.t("Name", { ns: "energy" }),
      field: InputText,
      options: { required: true, maxLength: 255 }
    },
    country: {
      label: i18n.t("Country", { ns: "energy" }),
      field: CountryDropdown,
      options: { required: true, filter: true }
    }
  }
}
