import { remove } from "../api"
import { CircuitBreakerRouteContext } from "/apps/assets/electrical/circuit-breaker/routes"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { DeleteConfirmation } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { updateSideBar } from "/utils/store/sidebar"
import { organization } from "/utils/store/organization"
import { site } from "/utils/store/site"
import { CircuitBreakerRelationRouteContext } from "../routes"

export default function Delete() {
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["assets"])
  const [processing, setProcessing] = useState(false)
  const { circuitBreaker } = useContext(CircuitBreakerRouteContext)
  const { circuitBreakerRelation } = useContext(CircuitBreakerRelationRouteContext)

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  const removeObject = async () => {
    setProcessing(true)
    await remove(siteStore.id, circuitBreakerRelation.id, auth.userData.access_token)
      .then(() => {
        displayNotification([
          successNotification(
            t("Circuit breaker relation"),
            t("The circuit breaker relation has been removed.")
          )
        ])
        navigate(
          `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/electrical/circuit-breakers/${circuitBreaker.id}/circuit-breaker-relations/`
        )
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        displayNotification([errorNotification("Error", message)])
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  return (
    <DeleteConfirmation
      objectType={t("Circuit breaker relation")}
      objectNames={[t("Relation between", { circuitBreakerRelation })]}
      remove={removeObject}
      goBack={() => navigate(-1)}
      processing={processing}
    />
  )
}
