import { retrieve } from "/apps/connectivity/store/api"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { site } from "/utils/store/site"
import { Loader } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import * as React from "react"
import { createContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, Route, Routes, useParams } from "react-router-dom"
import Create from "./components/Create"
import Delete from "./components/Delete"
import DeleteBulk from "./components/DeleteBulk"
import Detail from "./components/Detail"
import List from "./components/List"

const StoreRoutesContext = createContext(null)

const StoreContent = () => {
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const { storeId } = useParams()
  const auth = useAuth()
  const [store, setStore] = useState()

  const retrieveDetail = async () => {
    await retrieve(siteStore.id, storeId, auth.userData.access_token)
      .then(response => {
        setStore(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    siteStore.id && siteStore && retrieveDetail()
  }, [siteStore.id, storeId])

  return (
    <StoreRoutesContext.Provider value={{ store, retrieveDetail }}>
      {store ? <Outlet /> : <Loader />}
    </StoreRoutesContext.Provider>
  )
}

export { StoreRoutesContext }
export default function StoreRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="create"
        element={<Create />}
      />
      <Route
        path="multi-delete"
        element={<DeleteBulk />}
      />
      <Route
        path=":storeId"
        element={<StoreContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
      </Route>
    </Routes>
  )
}
