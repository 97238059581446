import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { DeleteConfirmation, Loader } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useLayoutEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { getPointsCount, removePoints } from "../api"

const DeleteBulk = () => {
  const auth = useAuth()
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const { t } = useTranslation(["assets"])
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [objectCount, setObjectCount] = useState()
  const [loadingRelatedObject, setLoadingRelatedObject] = useState(false)
  const [processing, setProcessing] = useState(false)
  const ids = searchParams.getAll("id")

  const count = async () => {
    setLoadingRelatedObject(true)
    await getPointsCount(ids, auth.userData.access_token)
      .then(({ data, status, statusText }) => {
        if (status === 200) {
          setObjectCount(data)
        } else {
          throw new Error(statusText)
        }
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setLoadingRelatedObject(false))
  }

  const bulkDelete = async () => {
    setProcessing(true)
    await removePoints(siteStore.id, ids, auth.userData.access_token)
      .then(({ status, statusText }) => {
        if (status === 200) {
          dispatch(addNotifications([successNotification("Delete", t("Points has been removed."))]))
          navigate(
            `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/points/`
          )
        } else {
          throw new Error(statusText)
        }
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setProcessing(false))
  }

  useLayoutEffect(() => {
    count()
  }, [])

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  return loadingRelatedObject ? (
    <Loader />
  ) : (
    objectCount && objectCount.label && !!objectCount.label.length && (
      <DeleteConfirmation
        objectType={t("Points")}
        objectNames={objectCount.label}
        relatedObjects={objectCount.count ? objectCount : null}
        loadingRelatedObject={loadingRelatedObject}
        remove={bulkDelete}
        processing={processing}
        goBack={() => navigate(-1)}
      />
    )
  )
}

export default DeleteBulk
