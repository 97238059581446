import { errorNotification, successNotification } from "/utils/notification"

import { addNotifications } from "/utils/store/notification"

import { BaseLayout, FormContent, Loader } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { useNavigate } from "react-router-dom"
import { add } from "../api"

import { EdgeBlockTemplate } from "../models"

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["configuration"])
  const [data, setData] = useState({ updated_by: auth.userData.profile.given_name })
  const [errors, setErrors] = useState({})

  const goBack = e => {
    e && e.preventDefault()
    navigate(-1)
  }

  const create = async function (e) {
    e.preventDefault()

    await add(data, auth.userData.access_token)
      .then(res => {
        dispatch(
          addNotifications([
            successNotification("Create", t("The edge block template has been created."))
          ])
        )
        navigate(`/configuration/edge-block-templates/${res.data.id}`)
      })
      .catch(error => {
        if (error.response.data) {
          setErrors(error.response.data)
        }
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  return data ? (
    <BaseLayout title={t("Create edge block template")}>
      <form
        className="form-view"
        onSubmit={create}
      >
        <FormContent
          model={EdgeBlockTemplate()}
          data={data}
          setData={setData}
          errors={errors}
        />
        <div className="action-buttons-wrapper">
          <Button
            rounded
            outlined
            severity="secondary"
            label={t("Cancel")}
            icon="fa-solid fa-xmark"
            onClick={goBack}
          />
          <Button
            rounded
            severity="success"
            label={t("Save")}
            type="submit"
            icon="fa-solid fa-floppy-disk"
          />
        </div>
      </form>
    </BaseLayout>
  ) : (
    <Loader />
  )
}
