import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const UnavailabilityCategory = (spaces, loadingSpaces) => ({
  /*
   * List of fields for the model, like this
   */
  name: {
    label: i18n.t("Name", { ns: "operation" }),
    field: InputText,
    options: { required: true }
  },
  scope: {
    label: i18n.t("Scope", { ns: "operation" }),
    field: Dropdown,
    options: {
      options: [
        { value: "Flexibility", label: i18n.t("Flexibility", { ns: "operation" }) },
        { value: "Optimization", label: i18n.t("Optimization", { ns: "operation" }) }
      ],
      required: true
    }
  },
  description: {
    label: i18n.t("Description", { ns: "operation" }),
    field: InputTextarea,
    options: {}
  },
  space: {
    label: i18n.t("Space", { ns: "operation" }),
    field: Dropdown,
    options: {
      options: spaces,
      optionLabel: "name",
      optionValue: "id",
      loading: loadingSpaces,
      placeholder: loadingSpaces ? "Loading..." : "Select space"
    }
  }
})
