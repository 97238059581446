import axios from "axios"
import { config } from "/utils/api"

const BASE_URL = siteId =>
  `${window.BACKEND_URL}/operation/sites/${siteId}/unavailability_categories/`

export async function retrieveList(siteId, token, queryParams) {
  return await axios.get(`${BASE_URL(siteId)}jira/`, config({ token, queryParams }))
}

export async function retrieve(siteId, unavailabilityCategoryId, token) {
  return await axios.get(`${BASE_URL(siteId)}jira/${unavailabilityCategoryId}/`, config({ token }))
}

export async function add(siteId, data, token) {
  return await axios.post(`${BASE_URL(siteId)}jira/`, data, config({ token }))
}

export async function update(siteId, unavailabilityCategoryId, data, token) {
  return await axios.put(
    `${BASE_URL(siteId)}jira/${unavailabilityCategoryId}/`,
    data,
    config({ token })
  )
}

export async function remove(siteId, unavailabilityCategoryId, token) {
  return await axios.delete(
    `${BASE_URL(siteId)}jira/${unavailabilityCategoryId}/`,
    config({ token })
  )
}

export async function getCount(siteId, unavailabilityCategoryId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/operation/sites/${siteId}/unavailabilities/${unavailabilityCategoryId}/count/`,
    config({ token })
  )
}

export async function retrieveSpaces(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/spaces/`, config({ token }))
}

export async function removeCategories(siteId, ids, token) {
  return await axios.post(`${BASE_URL(siteId)}bulk-delete/`, ids, config({ token }))
}

export async function getCategoriesCount(siteId, ids, token) {
  return await axios.post(`${BASE_URL(siteId)}multiple-count/`, ids, config({ token }))
}

export async function getAllValues(siteId, token) {
  return await axios.get(`${BASE_URL(siteId)}jira/allvalues`, config({ token }))
}
