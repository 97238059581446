import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const MySirius = (spaces, loadingSpaces) => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: {
      label: i18n.t("Name", { ns: "connectivity" }),
      field: InputText,
      options: {
        required: true
      }
    },
    space: {
      label: i18n.t("Space", { ns: "connectivity" }),
      field: Dropdown,
      options: {
        options: spaces,
        optionLabel: "name",
        optionValue: "id",
        filter: true,
        loading: loadingSpaces,
        placeholder: loadingSpaces ? "Loading..." : "Select space"
      }
    },
    description: {
      label: i18n.t("Description", { ns: "connectivity" }),
      field: InputTextarea,
      options: {}
    }
  }
}

export const MySiriusOverview = () => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: { label: i18n.t("Name", { ns: "connectivity" }) },
    external_id: { label: i18n.t("External ID", { ns: "connectivity" }) },
    space: { label: i18n.t("Space", { ns: "connectivity" }) },
    description: { label: i18n.t("Description", { ns: "connectivity" }) }
  }
}
