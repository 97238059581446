import { retrieve } from "/apps/configuration/energy-company/retailer/api"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { Loader } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import * as React from "react"
import { createContext, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Outlet, Route, Routes, useParams } from "react-router-dom"
import Create from "./components/Create"
import Delete from "./components/Delete"
import Detail from "./components/Detail"
import Table from "./components/List"

const RetailerRoutesContext = createContext(null)

const RetailerContent = () => {
  const dispatch = useDispatch()
  const auth = useAuth()
  const { id } = useParams()
  const [retailer, setRetailer] = useState(null)

  const retrieveDetail = async () => {
    await retrieve(id, auth.userData.access_token)
      .then(response => {
        setRetailer(response.data)
      })
      .catch(error => {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
  }

  useEffect(() => {
    id && retrieveDetail()
  }, [id])

  return (
    <RetailerRoutesContext.Provider value={{ retailer, retrieveDetail }}>
      {retailer ? <Outlet /> : <Loader />}
    </RetailerRoutesContext.Provider>
  )
}

export { RetailerRoutesContext }
export default function RetailerRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<Table />}
      />
      <Route
        path="create"
        element={<Create />}
      />
      <Route
        path=":id"
        element={<RetailerContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
      </Route>
    </Routes>
  )
}
