import React from "react"
import { useTranslation } from "react-i18next"

export default function ConfigErrors({ configErrors }) {
  const { t } = useTranslation(["optimization"])

  const groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
      ;(rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, {})
  }

  return (
    <div className="config-errors">
      <h4>{t("There is errors in configuration")}</h4>
      {Object.entries(groupBy(configErrors, "section")).map(([key, value], index) => {
        return (
          <div key={index}>
            <div className="section-error">
              {t("Section")} [{key}]
            </div>
            {value.map((error, indexError) => {
              return (
                <div key={indexError}>
                  {error.value} : {error.error_msg}
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}
