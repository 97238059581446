import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const Space = (spaces, loadingSpaces) => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: {
      label: i18n.t("Name", { ns: "assets" }),
      field: InputText,
      options: {
        required: true,
        maxLength: 255
      }
    },

    parent_space_id: {
      label: i18n.t("Parent space", { ns: "assets" }),
      field: Dropdown,
      options: {
        options: spaces,
        optionLabel: "name",
        optionValue: "id",
        loading: loadingSpaces,
        placeholder: loadingSpaces ? "Loading..." : "Select parent space"
      }
    },
    description: {
      label: i18n.t("Description", { ns: "assets" }),
      field: InputTextarea,
      options: {}
    },
    surface_area: {
      label: i18n.t("Surface area (m²)", { ns: "assets" }),
      field: InputNumber,
      options: { showButtons: true }
    },
    height: {
      label: i18n.t("Height (m)", { ns: "assets" }),
      field: InputNumber,
      options: { showButtons: true }
    },
    is_optimized: {
      label: i18n.t("Optimized", { ns: "assets" }),
      field: InputSwitch,
      options: {}
    },
    is_permeable: {
      label: i18n.t("Permeable", { ns: "assets" }),
      field: InputSwitch,
      options: {}
    },
    is_heated: {
      label: i18n.t("Heated", { ns: "assets" }),
      field: InputSwitch,
      options: {}
    },
    is_cooled: {
      label: i18n.t("Cooled", { ns: "assets" }),
      field: InputSwitch,
      options: {}
    }
  }
}

export const SpaceOverview = () => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: { label: i18n.t("Name", { ns: "assets" }) },
    description: { label: i18n.t("Description", { ns: "assets" }) },
    parent_space_name: { label: i18n.t("Parent space", { ns: "assets" }) },
    surface_area: { label: i18n.t("Surface area (m²)") },
    height: { label: i18n.t("Height") },
    is_optimized: { label: i18n.t("Optimized", { ns: "assets" }) },
    is_permeable: { label: i18n.t("Permeable", { ns: "assets" }) },
    is_heated: { label: i18n.t("Heated", { ns: "assets" }) },
    is_cooled: { label: i18n.t("Cooled", { ns: "assets" }) }
  }
}
