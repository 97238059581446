import axios from "axios"
import { config } from "/utils/api"

export async function retrieveList(siteId, token, queryParams) {
  return await axios.get(
    `${window.BACKEND_URL}/optimization/sites/${siteId}/edge_controllers/`,
    config({ token, queryParams })
  )
}

export async function retrieve(siteId, id, token) {
  return await axios.get(
    `${window.BACKEND_URL}/optimization/sites/${siteId}/edge_controllers/${id}/`,
    config({ token })
  )
}

export async function add(siteId, data, token) {
  return await axios.post(
    `${window.BACKEND_URL}/optimization/sites/${siteId}/edge_controllers/`,
    data,
    config({ token })
  )
}

export async function update(siteId, edgeControllerId, data, token) {
  return await axios.put(
    `${window.BACKEND_URL}/optimization/sites/${siteId}/edge_controllers/${edgeControllerId}/`,
    data,
    config({ token })
  )
}

export async function remove(siteId, id, token) {
  return await axios.delete(
    `${window.BACKEND_URL}/optimization/sites/${siteId}/edge_controllers/${id}/`,
    config({ token })
  )
}

export async function getGateways(siteId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/connectivity/sites/${siteId}/gateways/`,
    config({ token })
  )
}

export async function getPoints(siteId, gatewayId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/${siteId}/points/?gateway=${gatewayId}`,
    config({ token })
  )
}

export async function getEdgeBlockTemplates(token) {
  return await axios.get(
    `${window.BACKEND_URL}/optimization/template_edge_blocks/`,
    config({ token })
  )
}

export async function getHistory(siteId, historyId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/optimization/sites/${siteId}/edge-controllers/history/${historyId}/`,
    config({ token })
  )
}

export async function exportEdgeController(siteId, edgeControllerId, token) {
  return await axios({
    method: "get",
    url: `${window.BACKEND_URL}/optimization/sites/${siteId}/edge_controllers/${edgeControllerId}/export`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export async function importConfigurationEdgeController(file, token) {
  let formData = new FormData()
  formData.append("file", file)
  return await axios.post(
    `${window.BACKEND_URL}/optimization/edge_controllers/import/`,
    formData,
    config({ token, file: true }),
    { signal: new AbortController() }
  )
}

export async function getEdgeControllerTypes(token) {
  return await axios.get(
    `${window.BACKEND_URL}/optimization/edge_controller_types/`,
    config({ token })
  )
}
