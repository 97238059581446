import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import CodeMirror from "./components/CodeMirrorInput"
import { InputText } from "primereact/inputtext"

export const EdgeController = (gateways, edgeControllerTypes) => {
  return {
    /*
     * List of fields for the model, like this
     */

    gateway_id: {
      label: i18n.t("Gateway", { ns: "optimization" }),
      field: Dropdown,
      options: {
        required: true,
        options: gateways,
        optionLabel: "name",
        optionValue: "id",
        filter: true,
        placeholder: i18n.t("Select a gateway", { ns: "optimization" })
      }
    },
    type: {
      label: i18n.t("Type", { ns: "optimization" }),
      field: Dropdown,
      options: {
        required: true,
        options: edgeControllerTypes,
        placeholder: i18n.t("Select a type", { ns: "optimization" })
      }
    },
    comment: {
      label: i18n.t("Comment", { ns: "optimization" }),
      field: InputText,
      options: { required: true }
    },
    config: {
      label: i18n.t("Configuration", { ns: "optimization" }),
      field: CodeMirror,
      options: {
        height: "400px",
        readOnly: false
      }
    }
  }
}

export const EdgeControllerOverview = () => {
  return {
    /*
     * List of fields for the model, like this
     */

    gateway_name: { label: i18n.t("Gateway", { ns: "optimization" }) },
    type: { label: i18n.t("Type", { ns: "optimization" }) },
    comment: { label: i18n.t("Comment", { ns: "optimization" }) },
    config: { label: i18n.t("Config", { ns: "optimization" }) }
  }
}
