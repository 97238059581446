import { paginatedRequestFlex } from "/utils/api"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { ListActions, ListLayout } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useState } from "react"
import { Calendar } from "react-calendar"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const { siteId, flexId } = useParams()

  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})
  const [currentDate, setCurrentDate] = useState(new Date())

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "site", siteId: siteId }))
  }, [])

  useEffect(() => {
    const getSchedules = async strDate => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequestFlex(
        `flex/${flexId}/load_curtail_schedule_fetch_in_a_day/?execution_desired=true${strDate ? `&current_date=${strDate}` : ""}`,
        auth.userData.access_token
      )()
        .then(results => {
          setItems(results)
          setLoading(false)
        })
        .catch(error => {
          setLoading(false)
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* await retrieveList(flexId, strDate, auth.userData.access_token).then(response => {
        if (response.data) {
          setItems(response.data.results)
        }
        setLoading(false)
      }).catch(error => {
        setLoading(false)
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      }) */
    }

    if (currentDate) {
      const strDate = new Date(
        Date.UTC(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          currentDate.getHours(),
          currentDate.getMinutes()
        )
      )
        .toISOString()
        .slice(0, 16)
      getSchedules(strDate)
    }
  }, [currentDate])

  const columns = [
    {
      field: "curtail_start",
      header: t("Curtail start"),
      processValue: value => {
        return new Date(value).toLocaleString()
      }
    },
    {
      field: "curtail_end",
      header: t("Curtail end"),
      processValue: value => {
        return new Date(value).toLocaleString()
      }
    },
    { field: "curtail_capacity", header: t("Curtail capacity") },
    {
      field: "create_time",
      header: t("Proposed time"),
      processValue: value => {
        return new Date(value).toLocaleString()
      }
    },
    { field: "created_by", header: t("Proposed by"), sortable: true },
    { field: "execution_desired_text", header: t("Requested?") },
    {
      field: "request_time",
      header: t("Request time"),
      processValue: value => {
        return value ? new Date(value).toLocaleString() : ""
      }
    },
    { field: "requested_by", header: t("Requested by"), sortable: true }
  ]

  const getDetailUrl = scheduleId => {
    return `/sites/${siteId}/optimization/flexibility/${flexId}/confirmed-curtail-schedules/${scheduleId}/`
  }

  const getDeleteUrl = scheduleId => {
    return `/sites/${siteId}/optimization/flexibility/${flexId}/confirmed-curtail-schedules/${scheduleId}/delete/`
  }

  const onRowClick = scheduleId => {
    navigate(getDetailUrl(scheduleId))
  }

  const menuFilters = { items, columns, filters, setFilters }

  return (
    <>
      <Calendar
        className="calendar-box"
        onChange={setCurrentDate}
        value={currentDate}
        tileClassName={({ date, view }) => {
          if (view === "month") {
            let search = items.find(item => {
              const strDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`
              return item.curtail_start_date === strDate
            })
            if (search) {
              return "border-round border-1 border-solid border-primary"
            }
          }
          return "border-round"
        }}
      />

      <section className="list-section">
        <div className="list-section-head">
          <h3>
            {t("Upcoming load curtailment for")} {currentDate.toLocaleString().split(" ")[0]}
          </h3>
        </div>

        <ListActions filters={menuFilters} />

        <ListLayout
          columns={columns}
          value={items}
          loading={loading}
          filters={filters}
          getDetailUrl={getDetailUrl}
          getDeleteUrl={getDeleteUrl}
          onRowClick={onRowClick}
        />
      </section>
    </>
  )
}
