import { paginatedRequest, config } from "/utils/api"
import axios from "axios"

export async function retrieveList(token, queryParams) {
  return await axios.get(
    `${window.BACKEND_URL}/asset/organizations/`,
    config({ token, queryParams })
  )
}

export async function add(data, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/organizations/`, data, config({ token }))
}

export async function retrieve(id, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/organizations/${id}/`, config({ token }))
}

export async function update(id, data, token) {
  return await axios.put(
    `${window.BACKEND_URL}/asset/organizations/${id}/`,
    data,
    config({ token })
  )
}

export async function remove(id, token) {
  return await axios.delete(`${window.BACKEND_URL}/asset/organizations/${id}/`, config({ token }))
}

export async function listSites(organizationId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/asset/sites/`,
    config({ token, queryParams: { organization_id: organizationId } })
  )
}

export async function retrieveAll(token) {
  return await paginatedRequest("asset/organizations/", token)()
}

export async function getAllValues(token) {
  return await axios.get(`${window.BACKEND_URL}/asset/organizations/allvalues`, config({ token }))
}
