import ConfigurationRoutes from "/apps/configuration/routes"
import TabList from "/apps/organization/components/List"
import OrganizationRoutes from "/apps/organization/routes"
import Private from "/utils/auth/components/Private"
import Header from "/utils/components/Header"
import Notifications from "/utils/components/Notifications"
import SideBarManager from "/utils/components/SideBarManager"
import store from "/utils/store/main"
import "/static/sass/app.sass"
import "/utils/i18n"
import { AuthProvider } from "oidc-react"
import { PrimeReactProvider } from "primereact/api"
import React, { useEffect } from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { BrowserRouter, Outlet, Route, Routes, useLocation } from "react-router-dom"

const container = document.getElementById("app")
const root = createRoot(container)
const primeReactConfig = {
  ripple: true
}

const oidcConfig = {
  authority: window.OIDC_ENDPOINT,
  clientId: window.OIDC_CLIENT_ID,
  redirectUri: window.REDIRECT_URI
}

const MainContent = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  document.addEventListener(
    "invalid",
    (() => {
      return e => {
        e.preventDefault()
        document.querySelector("input:invalid,textarea:invalid,select:invalid").focus()
      }
    })(),
    true
  )

  return (
    <PrimeReactProvider params={primeReactConfig}>
      <Notifications />
      <Header />
      <SideBarManager />

      <main className="main-content">
        <Outlet />
      </main>
    </PrimeReactProvider>
  )
}

root.render(
  <AuthProvider {...oidcConfig}>
    <Private>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={<MainContent />}
            >
              <Route
                index
                element={<TabList />}
              />
              <Route
                path="organizations/*"
                element={<OrganizationRoutes />}
              />
              <Route
                path="configuration/*"
                element={<ConfigurationRoutes />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </Provider>
    </Private>
  </AuthProvider>
)
